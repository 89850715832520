import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import _ from 'lodash';

import { apiCall } from '../../action/RouterActions';
import ModalUploadFile from '../../component/common/modals/ModalUploadFile';

import '../../component/dump/menuComponents/menuComponents.sass'
import { checkEmail } from '../../component/helpers/tablesFuncHelpers';
import Toggle from 'react-toggle';
import CustomButton from '../../component/CustomButton';

const AddDriver = (props) => {

	const { groups, company, drivers, initialData, setPropStates, toggleLoader } = props;

	const [data, setData] = useState({
		first_name: '',
		last_name: '',
		group_id: '',
		card_id: '',
		email: '',
		send_qr_code: false
	});
	const [errMessage, setErrMessage] = useState({
		errfirst_name: '',
		errlast_name: '',
		errcard_id: '',
		erremail: ''
	});
	const [open, setOpen] = useState(false);
	const [showUploadDrivers, setShowUploadDrivers] = useState(false);
	const [fileUploadDrivers, setFileUploadDrivers] = useState(null);
	const [upd, setUpd] = useState(false);

	const handleInputChange = (event) => {
		const { target } = event;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		setData(prevState => ({ ...prevState, [name]: value }));
	};

	const checkForm = (data, e) => {
		const regExp = /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/;

		let error = false;

		Object.keys(data).forEach((key) => {
			if (data[key] === '') {
				error = true;
				let field = key.replaceAll('_', ' ');
				errMessage[`err${key}`] = `Enter your ${field}`;
			} else if ((key === "first_name" || key === "last_name") && data[key].toString().trim() === '' || (key !== "email" && !/^[a-zA-Z0-9 ]+$/.test(data[key].toString()))) {
				error = true;
				errMessage[`err${key}`] = `Please enter a valid ${key}`;
			} else if (key === 'card_id' && data.card_id && (data.card_id.length !== 8 || !regExp.test(data.card_id))) {
				error = true;
				errMessage.errcard_id = 'The Card ID must be either blank or exactly 8 hexadecimal digits';
			} else if (key === 'email' && data.email && !checkEmail(data.email)) {
				error = true;
				errMessage.erremail = 'Please enter a valid email address';
			} else if (key === 'send_qr_code' && !data.email) {
				error = true;
				errMessage.erremail = 'Please enter an email to send the QR code to.';
			} else if (errMessage[`err${key}`]) {
				errMessage[`err${key}`] = null;
			}
		})

		setErrMessage(errMessage);
		setUpd(!upd);
		return error;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { first_name, last_name, group_id, card_id, email, send_qr_code } = data;
		const params = {
			first_name,
			last_name,
			company_id: company.company_id,
			card_id,
			email
		}
		if (group_id) {
			params.group_id = group_id
		}
		if (send_qr_code) {
			params.send_qr_code = send_qr_code
		}

		const error = checkForm(params, e);
		if (!error) {
			toggleLoader(true);

			apiCall('PUT', '/driver', params)
				.then((res, err) => {
					console.log('handleSubmit res: ', res, err)

					const obj = res.data.response
					setPropStates({
						initialData: [...initialData, obj],
						drivers: [...drivers, obj],
					})
					clearFields();

					toggleLoader(false);
					toastr.success('', 'Driver Successfully Added')
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!onSave error: ', error.response, error)
					if (error.response && error.response.status === 404) {
						toastr.error(error.response.data.response.error);
					} else {
						toastr.error('Unable to add driver. Please try again later');
					}
				})
		}
	};

	const displayUploadDrivers = () => {
		setShowUploadDrivers(true);
	};

	const closeUploadDrivers = () => {
		setShowUploadDrivers(false);
	};

	const handleFileChange = (e) => {
		const { value } = e.target;
		const file = e.target.files[0];
		setFileUploadDrivers(file);

		let i;
		if (value.lastIndexOf('\\')) {
			i = value.lastIndexOf('\\') + 1;
		} else {
			i = value.lastIndexOf('/') + 1;
		}
		const filename = value.slice(i);
		const uploaded = document.getElementById('upload-form-label');
		uploaded.innerHTML = filename;
	};

	const handleUploadDrivers = (e) => {
		e.preventDefault()

		if (!fileUploadDrivers) {
			toastr.warning('You should select CSV/Excel file')
			return
		}

		const params = {
			file: fileUploadDrivers,
			company_id: company.company_id,
		}
		toggleLoader(true);
		apiCall('PUT', '/driver/upload?bell_notifs=true', params)
			.then((res, err) => {
				console.log('handleUploadDrivers res: ', res, err)
				toggleLoader(false);
				toastr.success('', 'File Successfully Uploaded')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('handleUploadDrivers error: ', error.response, error)

				toastr.error("Unable to upload drivers. Please try again later.");
			})
	};

	const clearFields = () => {
		const data = {
			first_name: '',
			last_name: '',
			group_id: '',
			card_id: '',
			email: '',
			send_qr_code: false,
		};

		setData(data);

		setErrMessage({
			errfirst_name: '',
			errlast_name: '',
			errcard_id: '',
			erremail: ''
		});
	};

	const toggleAddBlock = (e) => {
		e.preventDefault();
		setOpen(!open);
		clearFields();
	};

	const { first_name, last_name, group_id, card_id, email, send_qr_code } = data;
	const { errfirst_name, errlast_name, errcard_id, erremail } = errMessage;

	return (
		<div className="add-asset-block">
			<CustomButton variant="secondary-outline" className='rosco-secondary-inverted' onClick={toggleAddBlock}>{open ? 'Cancel' : 'Add a New Driver'}</CustomButton>
			{open
				&& (
					<div className='three-column-grid'>
						<div className={`${errfirst_name && 'has-error'}`}>
							<label className="control-label">
								First Name
							</label>
							<input
								type="text"
								name="first_name"
								className="vehicle-search"
								value={first_name}
								onChange={handleInputChange}
							/>
							{errfirst_name && <span className="help-block">{errfirst_name}</span>}
						</div>

						<div className={`${errlast_name && 'has-error'}`}>
							<label className="control-label">
								Last Name
							</label>
							<input
								type="text"
								name="last_name"
								className="vehicle-search"
								value={last_name}
								onChange={handleInputChange}
							/>
							{errlast_name && <span className="help-block">{errlast_name}</span>}
						</div>

						<div>
							<label htmlFor="user-role" className="control-label">
								Assign Group
							</label>
							<select
								placeholder="Select Group"
								className="rosco-select block"
								name="group_id"
								value={group_id}
								onChange={handleInputChange}
							>
								<option value="">Select Group...</option>
								{groups.map(({ id, group_name }) => <option key={id} value={id}>{group_name}</option>)}
							</select>
						</div>

						<div className={`${erremail && 'has-error'}`}>
							<label className="control-label">
								Email
							</label>
							<input
								type="text"
								name="email"
								className="vehicle-search"
								value={email}
								onChange={handleInputChange}
							/>
							{erremail && <span className="help-block">{erremail}</span>}
						</div>

						<div className={`${errcard_id && 'has-error'}`}>
							<label className="control-label">
								Card ID
							</label>
							<input
								type="text"
								name="card_id"
								className="vehicle-search"
								value={card_id}
								onChange={handleInputChange}
							/>
							{errcard_id && <span className="help-block">{errcard_id}</span>}
						</div>
						<div className='toggles-container'>
							<label className='toggle-wrapper'>
								<Toggle
									name="send_qr_code"
									checked={send_qr_code}
									onChange={handleInputChange}
								/>
								<span className="control-label">
									Send QR Code
								</span>
							</label>
						</div>
						<div className="first-two-columns">
							<label className="control-label"> </label>
							<div>
								<CustomButton
									className="rosco-primary two-buttons"
									onClick={handleSubmit}
									variant='primary'
								>
									Add New Driver
								</CustomButton>

								<CustomButton
									className="rosco-primary-inverted"
									onClick={displayUploadDrivers}
									title="Upload a Excel/CSV file with driver names and card IDs"
									variant='primary-outline'
								>
									Upload Drivers
								</CustomButton>
							</div>
						</div>
					</div>
				)}

			{showUploadDrivers
				&& (
					<ModalUploadFile
						onFileChange={handleFileChange}
						onUploadFile={handleUploadDrivers}
						onHide={closeUploadDrivers}
					/>
				)}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(AddDriver);
