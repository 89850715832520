import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { constants } from '../helpers/constants';
import { browserHistory } from 'react-router'
import { Button } from 'react-bootstrap'
import _ from 'lodash'

import AutocompleteComponent from '../common/AutocompleteComponent';
import ModalManageJobKey from '../smart/modals/ModalManageJobKey';
import AnnounceKit from 'announcekit-react'
import { apiCallGet } from '../../action/RouterActions';
import moment from 'moment';
import { viewedLastReleaseDescription } from '../../action/ClientActions';
import CustomButton from '../CustomButton';

const UserDropdown = (props) => {
	const { user, updateUser } = props;
	const userRole = user.roles[0];
	const roleName = constants.roleNames[user.roles[0]];
	// FWSD-6888
	// const announceLink = document.location.hostname.includes('staging') ? 'https://updates.roscolive.com/widgets/v2/2Z9pGE' : 'https://updates.roscolive.com/widgets/v2/2Z9pGE'
	const announceLink = 'https://updates.roscolive.com/widgets/v2/2Z9pGE'

	// let widgetRef = useRef(null);

	const [company_name, setCompany_name] = useState('');
	const [partner_company_name, setPartner_company_name] = useState('');
	const [showManageJobKey, setShowManageJobKey] = useState(false);

	// useEffect(() => {
	// 	apiCallGet('/releases/last')
	// 		.then(res => {
	// 			const release = res.data.response

	// 			if (_.isEmpty(user)) {
	// 				return
	// 			}

	// 			if (release && moment.utc().isAfter(moment.utc(release.timestamp)) &&
	// 				(!user.release_viewed || moment.utc(user.release_viewed).isBefore(moment.utc(release.timestamp)))
	// 			) {
	// 				widgetRef.open()
	// 			}
	// 		})
	// 		.catch(err => {
	// 			console.log(err)
	// 		})
	// }, []);

	// const onReleaseViewed = () => {
	// 	const timeReviewed = moment.utc();
	// 	viewedLastReleaseDescription(timeReviewed.format('YYYYMMDDHHmm00'))
	// 		.then(res => {
	// 			updateUser({ ...user, release_viewed: timeReviewed.format('YYYY-MM-DD HH:mm:ss') })
	// 		})
	// 		.catch(err => {
	// 			console.log(err)
	// 		})
	// }

	const handleClick = (url) => {
		props.closeDropdown();
		browserHistory.push({ pathname: url })
	}

	let partnerLocal = localStorage.getItem("partner_company_name");
	let companyLocal = localStorage.getItem("company_name");
	if (!!partner_company_name === false && !!company_name === false && !!companyLocal === true) setCompany_name(companyLocal);
	if (!!partner_company_name === false && !!partnerLocal === true) setPartner_company_name(partnerLocal);
	localStorage.setItem("partner_company_name", partner_company_name);
	localStorage.setItem("company_name", company_name);

	return (
		<div>
			<div className="user-menu-item user-menu-title">
				{`${user.first_name} ${user.last_name}`}
				<div className="user-initials">
					<span>{user.first_name[0] + user.last_name[0]}</span>
				</div>
				<div className="user-menu-subtitle">
					<div>{roleName}</div>
					<strong style={{fontWeight: '700'}}>{user.email}</strong>
				</div>
			</div>

			{['system_admin', 'customer_service', 'partner', 'installer', 'partner_view', 'sales_demo', 'custom_partner'].includes(userRole) && (
				<div className="company-select-menu">
					<span className="select-company-container">
						{['system_admin', 'customer_service', 'sales_demo'].indexOf(userRole) > -1 && (
							<div className="company-select-wrapper">
								<div className="company-select-label">
									Partner:
								</div>
								<AutocompleteComponent
									value={partner_company_name}
									items={_.get(props, 'partnerList.partner_list')}
									placeholder={_.get(props, 'company.partner')}
									onChange={(e, value) => {
										setPartner_company_name(value);
										if (!value) localStorage.removeItem("partner_company_name")
									}}
									onSelect={(value) => {
										setPartner_company_name(value);
										setCompany_name('');
										props.getPartnersCompanies(value)
									}}
									clear={() => {
										localStorage.removeItem("partner_company_name")
										localStorage.removeItem("company_name")
										setPartner_company_name('');
									}}
									partner={true}
								/>
							</div>
						)}
						<div className="company-select-wrapper">
							<div className="company-select-label">
								Company:
							</div>
							<AutocompleteComponent
								value={company_name}
								items={_.get(props, 'partnerList.company_list')}
								placeholder={_.get(props, 'company.name')}
								onChange={(e, value) => {
									setCompany_name(value)
									if (!value) localStorage.removeItem("company_name")
								}}
								onSelect={(value) => {
									setCompany_name(value)
									props.onSelectCompany(value)
								}}
								clear={() => {
									localStorage.removeItem("company_name")
									setCompany_name('');
								}}
								company={true}
							/>
						</div>
					</span>
				</div>
			)}
			
			<nav>
				<div className="user-menu-item">
					<CustomButton variant='link' onClick={() => handleClick('/account/settings')}>Settings</CustomButton>
				</div>
				<div className="user-menu-item">
					<AnnounceKit
						widget={announceLink}
						onWidgetOpen={props.closeDropdown}
						// onWidgetClose={onReleaseViewed}
						// onWidgetUnread={() => alert('onWidgetUnread1')}
						// ref={elem => (widgetRef = elem)}
						user={{
							id: user.id,
							email: user.email
						}}
						data={{
							role: user.roles[0]
						}}
						widgetStyle={{
							height: '0.75em',
							top: '13px',
							position: 'absolute',
							left: '48px',
						}}
					>
						<CustomButton variant="link">What's New?</CustomButton>
					</AnnounceKit>
				</div>
				<div className='user-menu-item'>
					<CustomButton variant='link' onClick={() => handleClick('/support')}>Support</CustomButton>
				</div>
				{['system_admin', 'customer_service', 'partner', 'fleet_manager'].includes(userRole) && (
					<div className="user-menu-item">
						<CustomButton variant='link' onClick={() => handleClick('/account/fleet-settings')}>Fleet Settings</CustomButton>
					</div>
				)}
				{['system_admin', 'customer_service', 'partner', 'fleet_manager', 'group_manager'].includes(userRole) &&
					<div className='user-menu-item'>
						<CustomButton
							variant='link'
							onClick={() => {
								setShowManageJobKey(true);
								props.closeDropdown()
							}}
						>
							Company Key
						</CustomButton>
					</div>
				}
				<div className='user-menu-item logout'>
					<CustomButton variant='link' onClick={props.logOut}>Log Out</CustomButton>
				</div>
			</nav>

			{showManageJobKey
				&& (
					<ModalManageJobKey
						onHide={() => setShowManageJobKey(false)}
					// setPropStates={setPropStates}
					/>
				)}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
	}),
	dispatch => ({
		updateUser: (user) => {
			dispatch({ type: 'GET_USER_SUCCESS', payload: user });
		},
	}),
)(UserDropdown);
