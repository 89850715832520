import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import classnames from 'classnames';

import { restorePassword } from '../../../../action/AccountActions';
import CustomButton from '../../../CustomButton';
import { toastr } from 'react-redux-toastr';

const RestorePasswordForm = (props) => {
    const { toggleLoader } = props

    const [data, setData] = useState({
        email: ''
    })
    const [errMessage, setErrMessage] = useState({
        email: ''
    })
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false,
    })

    const closeModal = () => {
        if (!modal.error) {
            browserHistory.push('/login');
        }
        else {
            let modaClose = modal;
            modaClose['error'] = false;
            modaClose['show'] = false;
            setModal(modaClose);
        }
    }

    const openModal = () => {
        setModal((prevState) => ({ ...prevState, show: true }))
    }

    const fillForm = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const checkForm = (data) => {
        let error = false;
        const updatedErrMessage = { ...errMessage };

        for (let i in data) {
            if (data[i] == '') {
                error = true;
                updatedErrMessage[i] = 'Enter your ' + i + '!';
            }
            else if (updatedErrMessage[i]) {
                updatedErrMessage[i] = null;
            }
        }

        if (!updatedErrMessage['email']) {
            var re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(data['email'])) {
                error = true;
                updatedErrMessage['email'] = 'Email is not valid.';
            }
        }

        setErrMessage(updatedErrMessage);
        return error;
    }

    const sendForm = (e) => {
        e.preventDefault();
        
        const error = checkForm(data);
        if (!error) {
            toggleLoader(true);
            restorePassword(data)
                .then((res, err) => {
                    toggleLoader(false)
                    setModal((prevState) => ({ ...prevState, content: 'Check your email to reset password.' }) )
                    openModal()
                    // toastr.info('Check your email to reset password.')
                })
                .catch((error, p) => {
                    toggleLoader(false);

                    if (error.response.data.response.error) {
                        let modalUpdate = modal;
                        modalUpdate['error'] = true;
                        modalUpdate['content'] = error.response.data.response.error;
                        setModal(modalUpdate);

                        openModal()
                        toastr.error(error.response.data.response.error);
                    }
                });
        }
    }

    const cancel = () => {
        browserHistory.push('/');
    }

    const { email } = errMessage;

    return (
        <div className="panel-body">
            <h3 className='login-header'>Password Recovery:</h3>
            <div className="form-wrapper">

                <form onSubmit={sendForm}>
                    <div className={classnames('login-form form-group', { 'has-error': email })}>
                        <label className={`login-label ${(data.email.length) ? 'active' : ''}`}>E-mail</label>
                        <input type="text" name="email" className="vehicle-search" onChange={fillForm} placeholder='E-mail' />
                        {email && <span className="error-label">{email}</span>}
                    </div>

                    <div className="change-password-submit form-group submit-btn">
                        <CustomButton type="submit" variant="primary" size="lg" id="restore-button">Submit</CustomButton>
                        <CustomButton variant="primary-outline" size="lg" onClick={cancel}>Back</CustomButton>
                    </div>
                </form>

            </div>
            <Modal size="small" show={modal.show} onHide={closeModal} dialogClassName={classnames('modal-custom', { 'has-error': modal.error })}>
                <Modal.Header>
                    <Modal.Title>
                        <div className={classnames({ 'has-error': modal.error })}>
                            {modal.error ? 'Alarm!' : 'Success'}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modal.content}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='rosco-primary' onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(RestorePasswordForm)
