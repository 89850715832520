import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import GreyHeader from '../../component/GreyHeader'
import DriversPage from './DriversPage'
import DriversScoring from './DriversScoring'
import DriversCoaching from './DriversCoaching'
import CustomButton from '../../component/CustomButton';

const DriversContainer = (props) => {

	const { location } = props;

	const [tabKey, setTabKey] = useState(1);

	return (
		<>
			<GreyHeader
				title='Drivers'
				renderSubtitle={
					<nav className='page-subtitle'>
						<Button variant='subtitle' className={classNames('subtitle-nav', { 'active': tabKey === 1 })} onClick={() => setTabKey(1)}>
							At a Glance
						</Button>
						<Button variant='subtitle' className={classNames('subtitle-nav', { 'active': tabKey === 2 })} onClick={() => setTabKey(2)}>
							Scoring
						</Button>
						<Button variant='subtitle' className={classNames('subtitle-nav', { 'active': tabKey === 3 })} onClick={() => setTabKey(3)}>
							Coaching
						</Button>
					</nav>
				}
			/>

			{tabKey === 1 &&
				<DriversPage
					location={location}
				/>
			}
			{tabKey === 2 &&
				<DriversScoring />
			}
			{tabKey === 3 &&
				<DriversCoaching />
			}
		</>
	);
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
	}),
)(DriversContainer);
