import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap';
import { getDevicesList } from '../../../action/DeviceActions';
import CategoryItem from '../../dump/menuComponents/CategoryItem';
import CustomButton from '../../CustomButton';

const DeleteProfileModal = (props) => {

    const { company, profiles, profile, onHide, deleteProfile, loaderModal, toggleLoaderModal } = props;

    const [currentVehicles, setCurrentVehicles] = useState([]);
    const [newProfileId, setNewProfileId] = useState(-1);
    const [step, setStep] = useState();
    const vehicle_ids = [];

	useEffect(() => {
		toggleLoaderModal(true);
		getDevicesList(company.company_id)
			.then(res => {
				let totalVehicles = res.data.response.devices
				let currentVehicles = []
                totalVehicles.forEach(vehicle => {
                    if (vehicle.uploaded_company_profile === profile.name || (vehicle.config_checksum === 1 && vehicle.pending_company_profile === profile.name)) {
                        currentVehicles.push(vehicle)
                        vehicle_ids.push(vehicle.device_id)
                    }
                })
				setCurrentVehicles(currentVehicles)
                currentVehicles.length === 0 && setStep(2)
				toggleLoaderModal(false);
			})
			.catch(err => {
				toggleLoaderModal(false);
			})
	}, [])

    const selectNewProfile = (e) => {
        setNewProfileId(e.target.value)
    }

    const vehiclesTerm = currentVehicles.length > 1 ? 'vehicles' : 'vehicle';

    return (
        <Modal
            show
            onHide={onHide}
            className='config-modal modal-lg-size'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">Delete Fleet Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='config-modal-body'>
                    {currentVehicles.length > 0 ?
                        <>
                            <label>The profile <strong>{profile.name}</strong> is applied to the following {vehiclesTerm}:</label>
                            <div className='profile-vehicle-list'>
                                {currentVehicles.map((vehicle) => {
                                    return (
                                        <CategoryItem
                                            key={vehicle.device_id}
                                            text={vehicle.vehicle_name || vehicle.device_id}
                                            small
                                        />
                                    )
                                })}
                            </div>
                            <label>
                                In order to delete this profile, you must select a new profile to apply to the above {vehiclesTerm}. 
                            </label>
                            <div className="text-center">
                                <select name="profile-select" id="profile-select" className="rosco-select" value={newProfileId} onChange={selectNewProfile}>
                                    <option hidden value={-1}>Select a new Profile...</option>
                                    {profiles.map((item, index) =>
                                        <option key={index} value={item.id} className="menu-item">{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </> :
                        <label>
                            Are you sure you wish to to delete the profile <strong>{profile.name}</strong>? This cannot be undone.
                        </label>
                    } 
                </div>
            </Modal.Body>
            <Modal.Footer>
                {currentVehicles.length ?
                    <>
                        <CustomButton variant="primary-outline" onClick={onHide}>
                            Cancel
                        </CustomButton>
                        <CustomButton type="button" variant="delete" onClick={() => deleteProfile(vehicle_ids, newProfileId)} disabled={newProfileId === -1}>
                            Delete Profile and Update Vehicles
                        </CustomButton>
                    </> :
                    <CustomButton variant="delete" onClick={deleteProfile}>
                        Delete Profile
                    </CustomButton>
                }
            </Modal.Footer>
            {loaderModal}
        </Modal>
    );
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
        loaderModal: state.loader.loaderModal,
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(DeleteProfileModal);
