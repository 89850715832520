import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import AccountUserContainer from './AccountUserContainer';
import AccountChangePasswordContainer from './AccountChangePasswordContainer';


import '../../dump/menuComponents/menuComponents.sass'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const AccountSettings = (props) => {

	const { user, location } = props;

	const [tabKey, setTabKey] = useState(1);
	const [userStatus, setUserStatus] = useState(null);

	useEffect(() => {
		const userRole = user.roles[0];

		if (location && location.state) {
			if (location.state.status === 'firstly' && ['provisioning', 'installer', 'partner_view', 'sales_demo', 'storage_user', 'custom_user'].indexOf(userRole) === -1) {
				//* The user first login
				setTabKey(2);
			} else {
				setTabKey(1)
			}
			setUserStatus('firstly');

			browserHistory.replace({ pathname: '/account/settings', state: {} });
		}
	}, [])

	const handleSelect = (key) => {
		setTabKey(key)
	}

	return (
		<div className="account-settings">
			<GreyHeader
				title='Account'
				renderSubtitle={
					<nav className="page-subtitle">
						<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => handleSelect(1)}>
							Settings
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 2} onClick={() => handleSelect(2)}>
							Password
						</CustomButton>
					</nav>
				}
			/>

			{tabKey === 1 && <AccountUserContainer />}
			{tabKey === 2 && <AccountChangePasswordContainer user_status={userStatus} />}
		</div>
	);
}

export default connect(
	state => ({
		user: state.user.user
	})
)(AccountSettings);
