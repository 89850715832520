import React from 'react'
import classNames from 'classnames';
import './CustomButton.sass'

const CustomButton = (props) => {
    const { variant, children, onClick, disabled, title, active, type, name, value, prefix, id, style, autoFocus } = props;
    const size = props.size || (['secondary', 'secondary-outline', 'delete'].includes(variant) ? 'sm' : '')

    const classArr = [];

    switch (variant) {
        case 'primary':
            classArr.push('rosco-primary');
            break;
        case 'secondary':
            classArr.push('rosco-secondary');
            break;
        case 'tertiary':
            classArr.push('rosco-tertiary');
            break;
        case 'primary-outline':
            classArr.push('rosco-primary', 'inverted');
            break;
        case 'secondary-outline':
            classArr.push('rosco-secondary', 'inverted');
            break;
        case 'tertiary-outline':
            classArr.push('rosco-tertiary', 'inverted');
            break;
        case 'delete':
            classArr.push('rosco-delete');
            break;
        case 'delete-outline':
            classArr.push('rosco-delete', 'inverted');
            break;
        case 'link':
            classArr.push('button-link');
            size === 'lg' && classArr.push('large')
            break;
        case 'subtitle':
            classArr.push('subtitle-nav');
            break;
        case 'toggle':
            classArr.push('toggle-button', 'rosco-primary');
            break;
        case 'toggle-group':
            classArr.push('toggle-button-group', 'rosco-primary');
            break;
        case 'empty':
            classArr.push('transparent');
            break;
        case 'close':
            classArr.push('close');
            break;
        default:
            break;
    }

    switch (size) {
        case 'sm':
            classArr.push('small');
            break;
        case 'xs':
            classArr.push('btn-xs')
            break;
        case 'hs':
            classArr.push('health-status')
            break;
        case 'icon':
            classArr.push('icon');
        default:
            break;
    }

    if (active) {
        classArr.push('active')
    }

    const completeClassName = classArr.join(' ')

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames('custom-button', { [completeClassName]: classArr.length, [prefix]: prefix })}
            title={title}
            type={type}
            name={name}
            value={value}
            id={id}
            style={style}
            autoFocus={autoFocus}
        >
            {children}
        </button>
    )
}

export default CustomButton