import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import Auth from '../../Auth'
import GreyHeader from '../../dump/menuComponents/GreyHeader'
import ModalDelete from '../modals/ModalDelete'

import { apiCallGet } from '../../../action/RouterActions'
import { addTutorialVideo, deleteTutorialVideo } from '../../../action/AccountActions'
import { defineEnvironmentDokuWiki } from '../../helpers/labels'
import { isInternalUser } from '../../helpers/constants'
import { generalIcons } from '../../helpers/iconHelpers'
import CustomButton from '../../CustomButton';
import roscoLogo from '../../../../../assets/roscovision-icon.png';

const SupportContainer = (props) => {

	const { user, toggleLoader } = props;
	const userRole = user.roles[0];

	const [videos, setVideos] = useState([])
	const [showAddTutorial, setShowAddTutorial] = useState(false)
	const [name, setName] = useState('')
	const [url, setUrl] = useState('')
	const [selectedTutorial, setSelectedTutorial] = useState(null)
	const [changed_users, setChanged_users] = useState(false)
	const [showDeleteModalState, setShowDeleteModalState] = useState(false)

	useEffect(() => {
		getTutorials();
	}, []);

	const getTutorials = () => {
		toggleLoader(true);
		apiCallGet('/tutorials')
			.then(res => {
				toggleLoader(false);
				setVideos(res.data.response.tutorials)
				console.log(res)
			})
			.catch(err => {
				console.log(err)
				toggleLoader(false);
				toastr.error('Unable to load tutorial videos. Please try again later')
			})
	}

	const addVideo = () => {
		let data = {
			name: name,
			url: url
		}
		toggleLoader(true);
		addTutorialVideo(data)
			.then(res => {
				getTutorials();
				toggleLoader(false);
				setName('');
				setUrl('');
				setShowAddTutorial(false);
			})
			.catch(err => {
				console.log(err)
				toggleLoader(false);
				toastr.error('Unable to add tutorial. Please try again later.')
			})
	}

	const showDeleteModal = (tutorial) => {
		setSelectedTutorial(tutorial)
		setShowDeleteModalState(true)
	}

	const deleteTutorial = (e) => {
		e.stopPropagation()
		toggleLoader(true);
		deleteTutorialVideo(selectedTutorial.id)
			.then(res => {
				getTutorials();
				setSelectedTutorial(null);
				setShowDeleteModalState(false);
				toggleLoader(false);
				toastr.success('Successfully deleted tutorial.')
			})
			.catch(err => {
				console.log(err)
				toggleLoader(false);
				toastr.error('Unable to add tutorial. Please try again later.')
			})
	}

	return (
		<div>
			<GreyHeader title='RoscoLive Support' hideSubtitle />
			<div className='empty-div' />

			<main className="settings-page support-page main-content-block">
				<h3 className='category-title'>RoscoLive Wiki</h3>
				<ul>
					<li>
						<a target="_blank" rel="noopener noreferrer" href={`https://${defineEnvironmentDokuWiki()}/?s_t=${new Auth().getSessionToken()}&env=${location.hostname}`}>
							<i className="fa fa-book" />
							Knowledge Base
						</a>
					</li>
				</ul>
				<h3 className='category-title'>Tutorials</h3>
				{isInternalUser(userRole) &&
					<div className='add-tutorial add-asset-block'>
						<CustomButton variant='secondary-outline' onClick={() => { setShowAddTutorial(!showAddTutorial) }}>{showAddTutorial ? 'Cancel' : 'Add New Tutorial'}</CustomButton>
						{showAddTutorial &&
							<div className='add-video-block'>
								<input className='vehicle-search' placeholder='Name' value={name} onChange={(e) => { setName(e.target.value) }} />
								<input className='vehicle-search' placeholder='Video Url:' value={url} onChange={(e) => { setUrl(e.target.value) }} />
								<CustomButton variant='secondary-outline' onClick={addVideo}>Add New Tutorial</CustomButton>
							</div>
						}
					</div>
				}
				<div className='tutorial-video-grid'>
					{videos.map(tutorial =>
						<div className='tutorial-video-card' key={tutorial.id}>
							<a href={tutorial.url} target="_blank">
								<img className='tutorial-thumbnail' src={roscoLogo} name={tutorial.url} />
							</a>
							<div className='tutorial-info'>
								<a href={tutorial.url} target="_blank" className='tutorial-name'>{tutorial.name}</a>
								{isInternalUser(userRole) &&
									<div className="imageButton deleteButton" title="Delete Tutorial" onClick={() => showDeleteModal(tutorial)}>
										{generalIcons.crossDelete}
									</div>
								}
							</div>
						</div>
					)}
				</div>
			</main>

			{showDeleteModalState &&
				<ModalDelete
					title='Delete Tutorial'
					content={`Are you sure you want to delete the tutorial "${selectedTutorial.name}"?`}
					closeModal={() => {
						setShowDeleteModalState(false);
						setSelectedTutorial(null)
					}}
					deleteModal={deleteTutorial}
				/>
			}
		</div>
	)
}

SupportContainer.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(
	({ user }) => ({
		user: user.user
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(SupportContainer);
