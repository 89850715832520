import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import classnames from 'classnames';
import { Link, browserHistory } from 'react-router';
import { checkEmail } from '../../../helpers/tablesFuncHelpers'
import { toastr } from 'react-redux-toastr';
import { loginUser, logoutUser } from '../../../../action/AccountActions';
import Auth from '../../../Auth';

import '../../../dump/menuComponents/menuComponents.sass'
import CustomButton from '../../../CustomButton';

const LoginForm = (props) => {

	const [data, setData] = useState({
		email: '',
		password: '',
	})
	const [errMessage, setErrMessage] = useState({
		email: '',
		password: '',
	})
	const [modal, setModal] = useState({
		show: false,
		title: '',
		content: '',
		error: false,
	})
	const [toggle_password, setToggle_password] = useState(true)
	const [rememberMe, setRememberMe] = useState(1)

	useEffect(() => {
		setRememberMe(localStorage.getItem('r_u') === null ? 1 : localStorage.getItem('r_u') * 1);
	}, []);

	const togglePassword = () => {
		setToggle_password(!toggle_password);
	}

	const closeModal = () => {
		const modalClose = modal;
		modalClose.error = false;
		modalClose.show = false;
		setModal(modalClose);
	}

	const openModal = () => {
		const modalOpen = modal;
		modalOpen.show = true;
		setModal(modalOpen);
	}

	const fillForm = ({ target }) => {
		const value = target.type === 'checkbox' ? target.checked : target.value;

		if (target.name === 'rememberMe') {
			setRememberMe(value * 1);
		} else {
			let dataUpdate = data;
			dataUpdate[target.name] = value;
			setData({ ...dataUpdate, [target.name]: value });
			setErrMessage({ ...errMessage, email: '', password: '' });
		}
	}

	const checkForm = (data) => {
		let error = false;
		const updatedErrMessage = { ...errMessage };

		for (const i in data) {
			if (data[i] == '') {
				error = true;
				updatedErrMessage[i] = 'Please enter your ' + i;
			}
			else if (errMessage[i]) {
				updatedErrMessage[i] = null;
			}
		}

		if (!updatedErrMessage.email) {
			var re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(data.email)) {
				error = true;
				updatedErrMessage.email = 'Please enter a valid email.';
			}
		}

		setErrMessage(updatedErrMessage);
		return error;
	}

	const sendForm = (e) => {
		e.preventDefault()

		const { toggleLoader } = props
		const error = checkForm(data)

		if (!error) {
			toggleLoader(true);

			loginUser(data)
				.then((res, err) => {
					if (res.data.response.error && res.data.response.error === 'you have to logout first') {
						logoutUser()
							.then((res, err) => {
								new Auth().deauthenticateUser();
								sendForm(e);
							})
							.catch((error) => {
								console.log('!error');
								console.log(error);
							});
					}
					else if (res.data.response.error && ['Invalid password', 'Specified user does not exist'].includes(res.data.response.error)) {
						toggleLoader(false)
						setErrMessage({ ...errMessage, password: 'Email/Password is incorrect. Please try again.' })
					}
					else if (res.data.response.error) {
						toggleLoader(false)
						toastr.error('Unable to login user. Please try again later');
					}
					else if (res.data.response.roles && res.data.response.roles[0] === 'notification_recipient') {
						toggleLoader(false)
						browserHistory.push({ pathname: '/confirmation' })
					}
					else {
						toggleLoader(false)
						const { session_token } = res.data.response;
						new Auth().authenticateUser(true, session_token, rememberMe);
						const { last_login } = res.data.response;
						if (!last_login) {
							browserHistory.push({ pathname: '/account/settings', state: { status: 'firstly' } })
						}
						else {
							location.href = '/';
						}
					}
				})
				.catch((error) => {
					console.log(error.response);

					toggleLoader(false)

					if (error.response && error.response.data.response.error) {
						let error_content = error.response.data.response.error;
						let error_title = '';
						if (error_content.toLowerCase() === 'invalid password') {
							error_title = 'Please re-enter your password';
							error_content = 'The password you\'ve entered is incorrect, please try again. <br\><b>(Note: Password is case sensitive)</b><br\>Forgotten your password? <a href="/restore-password">Request a new one</a>';
						}
						else if (error_content.toLowerCase() === 'specified user does not exist') {
							error_title = 'Incorrect Email';
							error_content = 'The email you entered does not belong to any account. <br\> <a href="/login">Please try again</a>';
						}

						const modalUpdate = modal;
						modalUpdate.error = true;
						modalUpdate.title = error_title;
						modalUpdate.content = error_content;
						setModal(modalUpdate);

						openModal();
						toastr.error(error_content);
					}
					else {
						toastr.error('Unable to login user. Please try again later');
					}
				});
		}
	}

	const { email, password } = errMessage;

	return (
		<div className="panel-body">
			<h3 className="login-header">Login:</h3>
			<div className="form-wrapper">

				<form onSubmit={sendForm} autoComplete={rememberMe ? 'auto' : 'off'} id="login-form">
					{!rememberMe && <input autoComplete="false" name="hidden" type="text" className="hidden" hidden="none" />}
					<div className='login-form'>
						<label className={`login-label ${(data.email.length) ? 'active' : ''}`}>E-mail</label>
						<input
							type="text"
							name="email"
							className={`vehicle-search ${(email || password) ? 'error' : ''}`}
							onChange={fillForm}
							placeholder="E-mail"
							autoComplete={rememberMe ? 'auto' : 'new-password'}
						/>
						{email && <span className="help-block error-label">{email}</span>}
					</div>
					<div className="login-form has-feedback">
						<label className={`login-label ${(data.password.length) ? 'active' : ''}`}>Password</label>
						<input
							type={toggle_password ? 'password' : 'text'}
							id="password"
							name="password"
							className={`vehicle-search ${password ? 'error' : ''}`}
							data-toggle="password"
							onChange={fillForm}
							placeholder="Password"
							autoComplete={rememberMe ? 'auto' : 'new-password'}
						/>
						<span className="fa fa-eye form-control-feedback" onClick={togglePassword} />
						{password && <span className="help-block error-label">{password}</span>}
					</div>
					<div className="login-form">
						<div className="checkbox">
							<label>
								<input
									type="checkbox"
									name="rememberMe"
									checked={rememberMe}
									onChange={fillForm}
								/>
								Remember Me
							</label>
						</div>
					</div>
					<div className="login-form submit-btn">
						<CustomButton variant="primary" type="submit" value="Login" id="login-button" style={{ padding: '8px 0px' }}>Login</CustomButton>
					</div>

					<div className="forgot-password">
						<Link to="/restore-password">Forgot password</Link>
					</div>
				</form>

			</div>
			<Modal show={modal.show} onHide={closeModal} dialogClassName={classnames('modal-custom', { 'has-error': modal.error })}>
				<Modal.Header>
					<Modal.Title>
						<div className={classnames({ 'has-error': modal.error })}>
							{modal.error ? modal.title : 'Success'}
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: modal.content }} />
				</Modal.Body>
				<Modal.Footer>
					<CustomButton variant="primary" onClick={closeModal}>Close</CustomButton>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(LoginForm)