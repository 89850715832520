import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const InfoPop = ({ title, body, children, placement = 'right' }) => {
	const popover = (
		<Popover id="popover-trigger-hover-focus">
			<Popover.Header as="h3">{title}</Popover.Header>
			<Popover.Body>
				{body}
			</Popover.Body>
		</Popover>
	)

	return (
		<OverlayTrigger
			placement={placement}
			overlay={popover}
			// trigger="click"
		>
			{children}
		</OverlayTrigger>
	)
}

export default InfoPop
