import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import './dump/forms/Form.sass'
import roscoLogo from '../../../assets/rosco-logo-horizontal.png'

const EmptyLayout = (props) => {

	const { loader, children } = props;

	useEffect(() => {
		document.body.classList.add('body-bg');

		if (moment().hour() > 21 || moment().hour() < 6) {
			document.body.classList.add('bg-7')
		}
		else {
			let day = moment().day();
			day = day === 7 ? 6 : day
			day && document.body.classList.add('bg-' + day)
		}

		if (isMobileOnly) {
			document.body.classList.add('mobile');
		}

		return () => {
			document.body.classList.remove('body-bg');
		}
	}, []);

	const panelAccess = !['/confirmation', '/reset-password'].includes(location.pathname);

	return (
		<div className="container">
			<div className="panel panel-default account-form">
				<div className="panel-heading ui-font-bold">
					<img src={roscoLogo} className="login-logo" />
				</div>
				{children}
				{panelAccess && (
					<div className="panel-body contact-section">
						<h3 className="login-header">
							Need access to RoscoLive?
						</h3>
						<div className="login-subheader">
							We'll be happy to guide you on how to gain access to the RoscoLive Fleet Management Dashboard.
						</div>
						<a href="mailto:sales@roscovision.com" className="sales-link">
							Contact Rosco
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
								<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
							</svg>
						</a>
					</div>
				)}
			</div>
			{loader}
		</div>
	);
}

export default connect(
	state => ({
		loader: state.loader.loader
	})
)(EmptyLayout);
