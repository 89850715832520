import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { toastr } from 'react-redux-toastr';
import DualListBox from 'react-dual-listbox';
import { isMobileOnly } from 'react-device-detect';
import _ from 'lodash';

import '../../dump/menuComponents/menuComponents.sass'

import { getDevicesList } from '../../../action/DeviceActions';
import { getUsers } from '../../../action/ClientActions';
import { getDevicesOfGroup, getUsersOfGroup, addDeviceToGroup, removeDeviceFromGroup, addUserToGroup, removeUserFromGroup, editGroup, getGroupData, deleteGroup } from '../../../action/GroupsActions';
import { apiCallGet, apiCall } from '../../../action/RouterActions'
import { errorDetection } from '../../helpers/helperErrors'

import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton'

const EditGroupContainer = (props) => {

	const { user, location, toggleLoader } = props;
	const userRole = user.roles[0];
	const company = location.state.company;

	const [group, setGroup] = useState({})
	const [initialGroup, setInitialGroup] = useState(location.state.group)

	const [all_devices, setAll_devices] = useState([])
	const [group_devices, setGroup_devices] = useState([])
	const [changed_devices, setChanged_devices] = useState([])

	const [all_users, setAll_users] = useState([])
	const [group_users, setGroup_users] = useState([])
	const [changed_users, setChanged_users] = useState([])

	const [all_drivers, setAll_drivers] = useState([])
	const [group_drivers, setGroup_drivers] = useState([])
	const [changed_drivers, setChanged_drivers] = useState([])

	const [errMessage, setErrMessage] = useState({
		err_group_name: '',
	})
	const [modal, setModal] = useState({
		show: false,
		error: false
	})
	const [delete_group, setDelete_group] = useState({})
	const [groups, setGroups] = useState()
	const [upd, setUpd] = useState(false)

	useEffect(() => {
		getGroup();

		return () => {
			localStorage.removeItem("selectGroup");
		}
	}, []);

	const getGroup = () => {
		toggleLoader(true);
		const params = {
			id: location.state.group.id,
			partner_name: company.partner,
			company_name: company.name,
			user_role: userRole,
		}
		getGroupData(params)
			.then((res) => {
				// console.log('!getGroup res: ', res, err);
				const result = res.data.response.groups[0];
				const initGroup = {
					group_name: result.group_name,
					id: result.id,
				}
				setGroup(initGroup)
				// setInitialGroup(initGroup);
				getAllData(result.group_name)
			})
			.catch((error) => {
				console.log('!getGroupData error', error);
				toggleLoader(false);
			});
	}

	const getAllData = async (argGroupName) => {
		const groupName = argGroupName || group.group_name || initialGroup.group_name;
		const requestArray = [];

		if (groupName && groupName.length > 0) {
			toggleLoader(true);

			// gets the company devices list and devices assigned to group
			requestArray.push(getDevicesList(company.company_id));
			const params = {
				company_name: company.name,
				partner_name: company.partner,
				group_name: groupName,
				user_role: userRole,
			}
			requestArray.push(getDevicesOfGroup(params));

			// gets the company users and users assigned to group
			const paramsCompany = {
				name: company.name,
				partner: company.partner,
			};
			requestArray.push(getUsers(userRole, paramsCompany))
			requestArray.push(getUsersOfGroup(params))

			// gets the company drivers
			requestArray.push(apiCallGet('/company/{companyId}/driver', { companyId: company.company_id }))
			const group_name = localStorage.getItem("selectGroup") || initialGroup.group_name

			await Promise.all(requestArray)
				.then(
					(res) => {
						// console.log('getAllData PromiseAll: ', res);
						let all_devices, group_devices, all_users, group_users, all_drivers, group_drivers

						if (res[0].status === 200 || res[0].status === 201) {
							const { devices } = res[0].data.response
							const filteredDevices = devices.filter(item => !item.group_name || item.group_name === group_name)
							all_devices = filteredDevices.map((device) => {
								return { label: device.vehicle_name || device.driver_name || device.device_id, value: device.device_id.toString() }
							})
						}

						if (res[1].status === 200 || res[1].status === 201) {
							const { devices } = res[1].data.response
							group_devices = devices.map(({ device_id }) => device_id.toString())
						}

						if (res[2].status === 200 || res[2].status === 201) {
							const { users } = res[2].data.response
							const filteredUsers = users.filter((item) => { if (item.roles[0] === 'user') return item })
							all_users = filteredUsers.map(({ email }) => email)
						}

						if (res[3].status === 200 || res[3].status === 201) {
							group_users = res[3].data.response.users;
						}

						if (res[4].status === 200 || res[4].status === 201) {
							const { drivers } = res[4].data.response
							const filteredDrivers = drivers.filter(item => !item.group_name || item.group_name === group_name)
							all_drivers = filteredDrivers.map((driver) => {
								return { label: driver.name || driver.email, value: driver.id.toString() }
							})
							group_drivers = filteredDrivers.filter((item) => item.group_name === group_name).map(({ id }) => id.toString())
						}

						setAll_devices(all_devices)
						setGroup_devices(group_devices)
						setChanged_devices(group_devices)

						setAll_users(all_users)
						setGroup_users(group_users)
						setChanged_users(group_users)

						setAll_drivers(all_drivers)
						setGroup_drivers(group_drivers)
						setChanged_drivers(group_drivers)

						toggleLoader(false);

					}, (error) => {
						console.log('getAllData PromiseAll ERROR', error);
						toggleLoader(false);
						let errDescription = 'An unexpected error occurred. Please try again later'
						if (error.response.data.response.error) {
							errDescription = error.response.data.response.error
						}
						toastr.error(errDescription)
					});
		}
	}

	const checkForm = (data) => {
		let error = false;
		let errMessageUpdate = errMessage;

		// if (data.group_name.trim() === '' || !/^[a-zA-Z0-9 ]+$/.test(data.group_name.trim())) {
		// 	error = true;
		// 	toastr.error('Enter a valid group name.');
		// }
		if (data.group_name.includes('/')) {
			error = true;
			errMessageUpdate['err_group_name'] = 'Group name cannot contain "/"'
		} else {
			for (let i in data) {
				if (data[i] === '') {
					error = true;
					let message = i.replaceAll('_', ' ');
					errMessageUpdate['err_' + i] = 'Please enter a ' + message;
				}
				else if (errMessageUpdate['err_' + i]) {
					errMessageUpdate['err_' + i] = null;
				}
			}
		}
		if (!errMessageUpdate['err_group_name'] && !/^[a-zA-Z0-9 ]+$/.test(data.group_name.trim())) {
			error = true;
			toastr.error('Enter a valid group name.')
		}

		setErrMessage(errMessageUpdate);
		return error;
	}

	const onSaveClick = async () => {
		let requestArray = [];
		const groupData = {
			company_name: company.name,
			partner_name: company.partner,
			user_role: userRole,
			group_name: group.group_name.trim(),
			id: group.id
		}

		const error = checkForm(group);
		setUpd(!upd);

		if (!error) {
			toggleLoader(true);
			localStorage.setItem("selectGroup", group.group_name);

			if (group.group_name !== initialGroup.group_name) {
				requestArray.push(await editGroup(groupData))
			}

			if (!_.isEqual(_.sortBy(changed_devices), _.sortBy(group_devices))) {
				// adds devices to group
				_.difference(changed_devices, group_devices).forEach((deviceId) => {
					requestArray.push(addDeviceToGroup(groupData, deviceId))
				})
				// removes devices from group
				_.difference(group_devices, changed_devices).forEach((deviceId) => {
					requestArray.push(removeDeviceFromGroup(groupData, deviceId))
				})
			}

			if (!_.isEqual(_.sortBy(changed_users), _.sortBy(group_users))) {
				// adds users to group
				_.difference(changed_users, group_users).forEach((userEmail) => {
					requestArray.push(addUserToGroup(groupData, userEmail))
				})
				// removes users from group
				_.difference(group_users, changed_users).forEach((userEmail) => {
					requestArray.push(removeUserFromGroup(groupData, userEmail))
				})
			}

			if (!_.isEqual(_.sortBy(changed_drivers), _.sortBy(group_drivers))) {
				// adds drivers to group
				_.difference(changed_drivers, group_drivers).forEach((driverId) => {
					requestArray.push(apiCall('POST', '/driver/{driver_id}', { driver_id: driverId, group_id: groupData.id, group_name: groupData.group_name }))
				})
				// removes drivers from group
				_.difference(group_drivers, changed_drivers).forEach((driverId) => {
					requestArray.push(apiCall('POST', '/driver/{driver_id}', { driver_id: driverId, group_id: '', group_name: '' }))
				})
			}

			console.log('requestArray', requestArray);

			Promise.all(requestArray)
				.then(res => {
					if (res.length > 0) {
						if (res[0] && (res[0].status === 200 || res[0].status === 201)) {
							setInitialGroup(group);
							getGroup();
							toggleLoader(false);
							toastr.success('', 'Changes were successfully saved');
						}
					}
					else {
						toggleLoader(false);
						toastr.info('', 'There were no changes');
					}
				})
				.catch(err => {
					toggleLoader(false);
					console.log('Promise.all error: ', err.response);

					let errDescription = 'Unable to update group. Please try again later.'
					if (err.response.data.body) {
						errDescription = errorDetection(err.response.data.body) || err.response.data.body
					}
					else if (err.response.data.response.error) {
						errDescription = errorDetection(err.response.data.response.error) || err.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const onCancelClick = () => {
		browserHistory.push('/managegroups');
	}

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		let data = group;
		data[name] = value;

		setGroup(data);
		setErrMessage({ group_name: '' });
	}

	const deleteGroupConfirm = () => {
		setDelete_group(group);
		setModal({ ...modal, show: true });
	}

	const deleteGroupModal = () => {
		toggleLoader(true);
		const data = {
			group_name: delete_group.group_name,
			user_role: userRole,
			company_name: props.company.name,
			partner_name: props.company.partner
		}

		if (group.group_name) {
			deleteGroup(data)
				.then(res => {
					let modalUpdate = modal;
					modalUpdate['show'] = false;
					let getGroups = res.data.response.groups;
					setDelete_group({});
					setModal(modalUpdate);
					setGroups(getGroups);
					toastr.success('', 'Group successfully deleted');
					onCancelClick();
					toggleLoader(false);
				})
				.catch(error => {
					toggleLoader(false);
					console.log('!deleteGroupModal error: ', error.response, error)
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const closeModal = () => {
		setModal({ ...modal, show: false });
	}

	const openModal = () => {
		setModal({ ...modal, show: true });
	}

	const { err_group_name } = errMessage;
	const hasDeletePermission = !['custom_user', 'custom_partner'].includes(user.roles[0])
		|| _.find(user.permissions, { perm_category_id: 6, a_delete: true });
	const hasEditPermission = !['custom_user', 'custom_partner'].includes(user.roles[0])
		|| _.find(user.permissions, { perm_category_id: 6, a_edit: true });
	const editableName = !['MASTER GROUP', 'Installed Devices'].includes(location.state.group.group_name);

	// FWSD-3589, FWSD-4488
	// let optionsDisabled = false
	// if (location.state.group.group_name === 'Installed Devices') {
	// 	optionsDisabled = true
	// }

	const options_mui = []
	const selected_mui = []
	const options2_mui = []
	const options3_mui = []
	const selected3_mui = []

	const options = []
	all_devices.forEach((item) => {
		options.push({ value: item.value, label: item.label })

		if (changed_devices.indexOf(item.value) === -1) {
			options_mui.push(item.label)
		} else {
			selected_mui.push(item.label)
		}
	})

	const options2 = []
	all_users.forEach(item => {
		options2.push({ value: item, label: item });

		if (changed_users.indexOf(item) === -1) {
			options2_mui.push(item)
		}
	})

	const options3 = []
	all_drivers.forEach((item) => {
		options3.push({ value: item.value, label: item.label })

		if (changed_drivers.indexOf(item.value) === -1) {
			options3_mui.push(item.label)
		} else {
			selected3_mui.push(item.label)
		}
	})

	if (!changed_devices || !changed_users || !changed_drivers) {
		return (
			<GreyHeader title='Edit Group' hideSubtitle />
		)
	}

	return (
		<div className='edit-group'>
			<GreyHeader title="Edit Group" subtitle={localStorage.getItem("selectGroup") || initialGroup.group_name} />

			<div className={`page-subheader ${editableName ? '' : 'align-right'}`}>
				{editableName && (
					<div className={classnames('subheader-section search-flex', { 'has-error': err_group_name })}>
						<label className='search-label'>Name: </label>
						<input
							type="text"
							name="group_name"
							className="vehicle-search"
							onChange={handleInputChange}
							value={group.group_name || ''}
						/>
						{err_group_name && <span className="help-block">{err_group_name}</span>}
					</div>
				)}
				<div className='subheader-section'>
					<CustomButton variant="secondary" onClick={onSaveClick} disabled={!hasEditPermission}>SAVE</CustomButton>
					<CustomButton variant="secondary-outline" onClick={onCancelClick}>BACK</CustomButton>
					{hasDeletePermission &&
						<CustomButton variant="delete" onClick={deleteGroupConfirm}>DELETE</CustomButton>
					}
				</div>
			</div>

			<main className='main-content-block'>
				<section>
					<h3 className='category-title'>Vehicles</h3>
					<div className="dual-list-label">
						<label htmlFor="groups" className="control-label">
							Available Vehicles:
						</label>
						<label htmlFor="groups" className="control-label">
							{`Vehicles in ${group.group_name}:`}
						</label>
					</div>
					<div className={`dual-listbox-wrapper ${location.state.group.group_name === 'Installed Devices' && 'disable-assign'}`}>
						<DualListBox
							name="devices"
							canFilter
							// disabled={optionsDisabled}
							options={options}
							selected={changed_devices}
							onChange={(selected) => setChanged_devices(selected)}
						/>

						{isMobileOnly && (
							<div className='mobile-dual-list-wrapper'>
								<textarea value={options_mui.join('\r\n')} readOnly className="textarea-duallistbox" />
								<textarea value={selected_mui.join('\r\n')} readOnly className="textarea-duallistbox" />
							</div>
						)}
					</div>
				</section>

				<section>
					<h3 className='category-title'>Users</h3>
					<div className="dual-list-label">
						<label htmlFor="users" className="control-label">
							Available Users:
						</label>
						<label htmlFor="users" className="control-label">
							{`Users Assigned to ${group.group_name}:`}
						</label>
					</div>
					<DualListBox
						name="users"
						canFilter
						options={options2}
						selected={changed_users}
						onChange={(selected) => setChanged_users(selected)}
					/>

					{isMobileOnly && (
						<div className='mobile-dual-list-wrapper'>
							<textarea value={options2_mui.join('\r\n')} readOnly className="textarea-duallistbox" />
							<textarea value={changed_users.join('\r\n')} readOnly className="textarea-duallistbox" />
						</div>
					)}
				</section>

				<section>
					<h3 className="category-title">Drivers</h3>
					<div className="dual-list-label">
						<label htmlFor="drivers" className="control-label">
							Available Drivers:
						</label>
						<label htmlFor="drivers" className="control-label">
							{`Drivers Assigned to ${group.group_name}:`}
						</label>
					</div>
					<DualListBox
						name="drivers"
						canFilter
						options={options3}
						selected={changed_drivers}
						onChange={selected => setChanged_drivers(selected)}
						preserveSelectOrder
					/>

					{isMobileOnly && (
						<div className="mobile-dual-list-wrapper">
							<textarea value={options3_mui.join('\r\n')} readOnly className="textarea-duallistbox" />
							<textarea value={selected3_mui.join('\r\n')} readOnly className="textarea-duallistbox" />
						</div>
					)}
				</section>
			</main>

			<Modal
				show={modal.show}
				dialogClassName="has-error"
				onHide={closeModal}
				className="modal-lg-size"
			>
				<Modal.Header closeButton>
					<Modal.Title className="delete-title">Delete Group</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-text text-center">
						Are you sure you want to delete the group: <strong>{group.group_name}</strong>?
					</div>
				</Modal.Body>
				<Modal.Footer bsPrefix="default-modal-footer modal-footer">
					<CustomButton
						variant="delete"
						onClick={deleteGroupModal}
					>
						DELETE
					</CustomButton>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(EditGroupContainer);
