const defaultState = {
	notifications: [],
	selectedObj: {},
}

const notification = (state = defaultState, action) => {
	switch (action.type) {
	case 'SET_USER_NOTIFICATIONS': {
		return { ...state, ...{ notifications: action.payload } }
	}
	case 'SET_OBJECT_NOTIFICATIONS': {
		return { ...state, ...{ selectedObj: action.payload } }
	}
	default:
		return state
	}
}

export default notification
