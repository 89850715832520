import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

const API_URL = process.env.REACT_APP_API_URL

export function updateCompanyProfile(data) {
	let url
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/profiles`
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/profiles`
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/profiles`
			break
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/profiles`
			break
	}
	const form = new FormData()
	data.name ? form.append('name', data.name) : ''
	data.configuration ? form.append('configuration', data.configuration) : ''
	data.configurationdv6 ? form.append('configurationdv6', data.configurationdv6) : ''

	// FWSD-4876
	if (data.event_types) {
		_.forOwn(data.event_types, (value, key) => {
			form.append(key, value)
		})
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function deleteCompanyProfile (data) {
	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/profiles/${data.profile_id}`
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/profiles/${data.profile_id}`
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/profiles/${data.profile_id}`
			break
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/profiles/${data.profile_id}`
			break
	}

	var form = new FormData()
	if (data.preferred_profile_id) {
		// url += `?preferred_profile_id=${data.preferred_profile_id}`
		form.append('preferred_profile_id', data.preferred_profile_id)
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {},
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function getCompanyProfiles(data) {
	let url
	switch (data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/profiles`
		break
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/profiles`
		break
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${data.company_name}/profiles`
		break
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${data.partner_name}/companies/${data.company_name}/profiles`
		break
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'storage_manager':
	case 'custom_user':
	case 'group_manager':
	case 'user':
		url = `${API_URL}/profiles`
		break
	default:
		break
	}
	
	if (data.include_data) {
		url += '?include_data='+data.include_data
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function sendGeotabLink(data) {
	console.log('!sendGeotabLink action data', data)

	const url = `${API_URL}/company/geotab/email`

	var form = new FormData()
	form.append('email', data.geotab_email)
	form.append('company_name', data.company_name)
	form.append('partner_name', data.partner_name)
	form.append('provision_link', data.provision_link)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function deleteCompany (data) {
	console.log('!deleteCompany action data', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/groups/${group_name}`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
			url = `${API_URL}/companies/${data.company_name}`;
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {},
		withCredentials: true,
	}

	return axios(settings);
}

export function clearLastEvents(user_role, company_name=null, partner_name=null) {
	console.log('!clearLastEvents', company_name, partner_name);

	// const url = `${API_URL}/company/${company_name}/clearlastevents`;
	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/clearlastevents`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/clearlastevents`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}/clearlastevents`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${company_name}/clearlastevents`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/clearlastevents`;
			break;
	}


	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getCompaniesList(user_role, company_type, get_type) {
	 console.log('!getCompaniesList action data', user_role, company_type);

	let url;
	switch(user_role) {

		case 'system_admin':
			url = `${API_URL}/system_admin/companies/filter/list?company_type=${company_type}`; //get partners
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/companies/filter/list?company_type=${company_type}`; //get partners
			break;
		case 'partner':
		case 'partner_view':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/filter/list?company_type=${company_type}`;
			break;
		case 'installer':
			url = `${API_URL}/installer/companies/filter/list?company_type=${company_type}`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/companies/filter/list?company_type=${company_type}`;
			break;
	}

	if (get_type) {
		url +='&gettype=true'
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getPartnerCompaniesList(user_role, partner_company_name, get_type) {
	console.log('!getPartnerCompaniesList action data', user_role, partner_company_name);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_company_name}/companies/filter/list`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_company_name}/companies/filter/list`;
			break;
		case 'installer':
			url = `${API_URL}/installer/companies/filter/list`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${partner_company_name}/companies/filter/list`;
			break;
	}

	if (get_type) {
		url +='?gettype=true'
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: "no-cache"},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getAllCompanies(user_role, company_type) {
	 console.log('!getAllCompanies action data', user_role, company_type);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/companies?type=${company_type}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/companies?type=${company_type}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/companies?type=${company_type}`;
			break;
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
//        // "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getCompaniesCustomer() {
	console.log('!getCompaniesCustomer')

	const url = `${API_URL}/customer_service/companies`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function getCompanyByName(user_role, company_name=null, partner_name=null, dms_cutoff=null) {
	console.log('!getCompanyByName action data', user_role, company_name, partner_name);

	let url;
	switch(user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}`;
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${company_name}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/company`;
			break;
	}

	if (dms_cutoff) {
		url += '?include_data=dms_notifs_cutoff'
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getCompanyById(userRole, companyId, include_data) {
	let url;
	switch (userRole) {
		case 'system_admin':
			url = `${API_URL}/system_admin/companies/${companyId}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/companies/${companyId}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${companyId}`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/companies/${companyId}`;
			break;
		default:
			url = `${API_URL}/company/${companyId}`;
			break;
	}

	if (include_data) {
		url += '?include_data=' + include_data
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function updateCompany(state, user_role, callback) {
	console.log('!updateCompany action data: ', state, user_role);

	let url;
	switch (user_role) {
	case 'fleet_manager':
	case 'storage_manager':
		url = `${API_URL}/company`;
		break;
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${state.company.owner_company}/companies/${state.company.company_name}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${state.company.owner_company}/companies/${state.company.company_name}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${state.company.company_name}`;
		break;
	default:
		break;
	}

	const form = new FormData();
	state.data.company_city ? form.append('company_city', state.data.company_city) : '';
	state.data.company_state ? form.append('company_state', state.data.company_state) : '';
	state.data.company_zipcode ? form.append('company_zipcode', state.data.company_zipcode) : '';
	state.data.company_address ? form.append('company_address', state.data.company_address) : '';
	state.data.company_address2 ? form.append('company_address2', _.get(state, 'data.company_address2', '')) : ''
	state.data.company_country ? form.append('company_country', state.data.company_country) : '';
	(state.data.company_phone_number || !_.isEmpty(state.data.company_phone_number) ) ? form.append('company_phone_number', state.data.company_phone_number) : '';
	state.data.billing_date ? form.append('billing_date', state.data.billing_date) : '';
	state.data.company_name ? form.append('company_new_name', state.data.company_name) : '';
	state.data.company_type ? form.append('company_type', state.data.company_type) : '';
	state.data.partner_company ? form.append('partner_company', state.data.partner_company) : '';
	state.data.data_limit ? form.append('data_limit', state.data.data_limit) : '';

	_.has(state.data, 'storage_active') ? form.append('storage_active', state.data.storage_active) : '';
	_.has(state.data, 'trace_active') ? form.append('trace_active', state.data.trace_active) : '';
	_.has(state.data, 'live_active') ? form.append('live_active', state.data.live_active) : '';
	_.has(state.data, 'download_files') ? form.append('download_files', state.data.download_files) : '';
	_.has(state.data, 'file_retention_time') ? form.append('file_retention_time', state.data.file_retention_time) : '';
	_.has(state.data, 'driver_recognition') ? form.append('driver_recognition', state.data.driver_recognition) : ''
	_.has(state.data, 'timezone') ? form.append('timezone', state.data.timezone) : ''
	_.has(state.data, 'reformat_reminder') ? form.append('reformat_reminder', state.data.reformat_reminder) : ''
	_.has(state.data, 'dms_notifs_cutoff') ? form.append('dms_notifs_cutoff', state.data.dms_notifs_cutoff) : ''
	_.has(state.data, 'advanced_profile_settings') ? form.append('advanced_profile_settings', state.data.advanced_profile_settings) : ''
	_.has(state.data, 'asset_deletion_protection') ? form.append('asset_deletion_protection', state.data.asset_deletion_protection) : ''
	_.has(state.data, 'event_expiration') ? form.append('event_expiration', state.data.event_expiration) : ''
	_.has(state.data, 'custom_apn') ? form.append('custom_apn', state.data.custom_apn) : ''
	// _.has(state.data, 'data_threshold_pct') ? form.append('data_threshold_pct', state.data.data_threshold_pct) : ''
	// _.has(state.data, 'data_threshold_type') ? form.append('data_threshold_type', state.data.data_threshold_type) : ''
	_.has(state.data, 'industry_type') ? form.append('industry_type',state.data.industry_type) : ''
	_.has(state.data, 'using_rosco_sim') ? form.append('using_rosco_sim',state.data.using_rosco_sim) : ''
	_.has(state.data, 'override_overage_limits') ? form.append('override_overage_limits',state.data.override_overage_limits) : ''

	if (_.has(state.data, 'trial_status')) {
		form.append('trial_status', state.data.trial_status)
		state.data.trial_status ? form.append('trial_date', moment(state.data.trial_date).format('YYYYMMDD000000')) : form.append('trial_date', '')
		state.data.trial_status ? form.append('trial_subscribers', state.data.trial_subscribers) : form.append('trial_subscribers', '')
	}

	if (state.data.fleet_configuration) {
		let fleet_configuration = state.data.fleet_configuration;
		if (typeof fleet_configuration === 'object') {
			fleet_configuration = JSON.stringify(fleet_configuration);
		}
		form.append('fleet_configuration', fleet_configuration);
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
	// axios(settings).then((res, err)=>{
	//     console.log('!updateCompanyCustomer updated user res');
	//     console.log(res);
	//     if (typeof callback === 'function') {
	//         return callback(null, {'result':'ok'})
	//     }
	//
	// })
	//     .catch((error)=>{
	//         if (typeof callback === 'function') {
	//             return callback(error, null);
	//         }
	//     });
}

export function disableCompany(user_role, state, active) {
	// console.log('disableCompany state', state);
	// console.log('disableCompany active', active);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${state.company.owner_company}/companies/${state.company.company_name}/${active}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${state.company.owner_company}/companies/${state.company.company_name}/${active}`;
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		// "mimeType": "multipart/form-data",
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function createCompanyCustomer(state, user_role, partner_name) {
	console.log('!createCompanyCustomer action state', state.data);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${state.data.company_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${state.data.company_name}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${state.data.company_name}`;
			break;
	}

	var form = new FormData();
	form.append('company_city', state.data.company_city);
	form.append('company_state', state.data.company_state);
	form.append('company_zipcode', state.data.company_zipcode);
	form.append('company_address', state.data.company_address);
	// state.data.company_address2 ? form.append("company_address2", state.data.company_address2) : "";
	(state.data.company_address2 && !_.isEmpty(state.data.company_address2) ) ? form.append('company_address2', state.data.company_address2) : '';

	form.append('company_country', state.data.company_country);
	// form.append("company_phone_number", state.data.company_phone_number);

	// (state.data.company_phone_number || _.isEmpty(state.data.company_phone_number) ) ? form.append("company_phone_number", state.data.company_phone_number) : "";
	form.append('company_phone_number', _.get(state, 'data.company_phone_number', ''));

	form.append('live_active', state.data.live_active);
	form.append('billing_date', state.data.billing_date);
	form.append('cutoff_when_overage', state.data.cutoff_when_overage);
	form.append('storage_active', state.data.storage_active);
	form.append('trace_active', state.data.trace_active);
	form.append('industry_type', state.data.industry_type);
	state.data.assigned_fleet_token ? form.append('assigned_fleet_token', state.data.assigned_fleet_token) : '';
	state.data.company_type !=='' ? form.append('company_type', state.data.company_type) : '';
	state.data.partner_company ? form.append('partner_company', state.data.partner_company) : '';
	state.data.data_limit ? form.append('data_limit', state.data.data_limit) : '';

	state.data.download_files ? form.append('download_files', state.data.download_files) : '';
	state.data.file_retention_time ? form.append('file_retention_time', state.data.file_retention_time) : '';
	_.has(state.data, 'custom_apn') ? form.append('custom_apn', state.data.custom_apn) : ''

	if (_.has(state.data, 'trial_status')) {
		form.append('trial_status', state.data.trial_status)
		// state.data.trial_status ? form.append("trial_date", state.data.trial_date) : form.append("trial_date", '')
		// state.data.trial_status ? form.append("trial_date", moment(state.data.trial_date).utc().format('YYYYMMDDHHmmss')) : form.append("trial_date", '')
		state.data.trial_status ? form.append('trial_date', moment(state.data.trial_date).format('YYYYMMDD000000')) : form.append('trial_date', '')
		// state.data.trial_status ? form.append("trial_subscribers", state.data.trial_subscribers) : form.append("trial_subscribers", '')
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	};


	return axios(settings);
	// axios(settings).then((res, err)=>{
	//     console.log('!updateCompanyCustomer updated user res');
	//     console.log(res);
	//     if (typeof callback === 'function') {
	//         return callback(null, {'result':'ok'})
	//     }
	//
	// })
	//     .catch((error)=>{
	//         if (typeof callback === 'function') {
	//             return callback(error, null);
	//         }
	//     });
}


export function getPartnerByName(user_role, partner_name) {
	console.log('getPartnerByName partner_name', partner_name);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}`;
			break;
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function disablePartner(user_role, state, active) {
	// console.log('disablePartner partner_name state', state);
	// console.log('disablePartner partner_name active', active);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${state.company.company_name}/${active}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${state.company.company_name}/${active}`;
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		// "mimeType": "multipart/form-data",
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function createPartner(state, user_role) {
	console.log('!createPartner action state', state);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${state.data.company_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${state.data.company_name}`;
			break;
	}

	var form = new FormData();
	form.append('company_city', state.data.company_city);
	form.append('company_state', state.data.company_state);
	form.append('company_zipcode', state.data.company_zipcode);
	form.append('company_address', state.data.company_address);
	form.append('company_country', state.data.company_country);
	(state.data.company_address2 && !_.isEmpty(state.data.company_address2) ) ? form.append('company_address2', state.data.company_address2) : '';
	// (state.data.company_phone_number || _.isEmpty(state.data.company_phone_number) ) ? form.append("company_phone_number", state.data.company_phone_number) : "";
	form.append('company_phone_number', _.get(state, 'data.company_phone_number', '') ) ;

	// form.append("live_active", state.data.live_active);
	// form.append("billing_date", state.data.billing_date);
	// form.append("cutoff_when_overage", state.data.cutoff_when_overage);
	// form.append("storage_active", state.data.storage_active);
	// form.append("trace_active", state.data.trace_active);
	// state.data.assigned_fleet_token ? form.append("assigned_fleet_token", state.data.assigned_fleet_token) : '';

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function updatePartner(state, user_role, callback) {
	console.log('!updatePartner action state', state, user_role)
	let url
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${state.company.company_name}`
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${state.company.company_name}`
			break
	}

	const form = new FormData();
	state.data.company_city ? form.append('company_city', state.data.company_city) : ''
	state.data.company_state ? form.append('company_state', state.data.company_state) : ''
	state.data.company_zipcode ? form.append('company_zipcode', state.data.company_zipcode) : ''
	state.data.company_address ? form.append('company_address', state.data.company_address) : ''
	state.data.company_country ? form.append('company_country', state.data.company_country) : ''

	(state.data.company_address2 || _.isEmpty(state.data.company_address2) ) ? form.append('company_address2', state.data.company_address2) : ''
	(state.data.company_phone_number || _.isEmpty(state.data.company_phone_number) ) ? form.append('company_phone_number', state.data.company_phone_number) : ''

	state.data.billing_date ? form.append('billing_date', state.data.billing_date) : ''
	state.data.company_name ? form.append('company_new_name', state.data.company_name) : ''

	// state.data.active ? form.append("active", state.data.active) : '';

	// form.append("active", state.data.active);

	form.append('storage_active', state.data.storage_active)
	form.append('trace_active', state.data.trace_active)
	form.append('live_active', state.data.live_active)
	form.append('allow_create_company', state.data.allow_create_company)
	form.append('allow_edit_company', state.data.allow_edit_company)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function deletePartner (data) {
	console.log('!deletePartner data', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}`;
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {},
		withCredentials: true,
	}

	return axios(settings);
}
