import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import classNames from 'classnames'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'

import SearchBar from '../../component/dump/menuComponents/SearchBar'
import DateDropdown from '../../component/dump/menuComponents/DateDropdown'
import DriversScoreTable from './DriversScoreTable'
import ModalCoachEvent from './ModalCoachEvent'
import ModalEventWeights, { initEventWeightsArr } from './ModalEventWeights'

import { apiCallGet } from '../../action/RouterActions'
import { getDriverEventWeights, getDriverScoresByTimeframe } from '../../action/DriverActions'
import { paginationExportOptions, tableExportOptions } from '../../component/helpers/table'
import { formatName } from '../../component/helpers/tablesFuncHelpers'
import { getDateRangeFromTabKey } from '../../component/smart/reportsComponents/ReportsHelpers'

import CustomButton from '../../component/CustomButton';
import { arrowIcon, questionIcon } from '../../component/helpers/iconHelpers';

const request_types = {
    gsensor: 'fatalgsensor',
    max_speed: 'highspeed',
    adas_fcw: 'forwardcollisionwarning',
    adas_ldw: 'lanedeparture',
    adas_pcw: 'pedestrianwarning',
    drowsiness: 'Drowsiness',
    distraction: 'Distraction',
    phone_use: 'Phoneuse',
    yawning: 'Yawning',
    seatbelt: 'Seatbelt',
    smoking: 'Smoking',
    harshbraking: 'harshbraking',
    harshaccel: 'harshaccel',
    low_speeding: 'low_speeding',
    moderate_speeding: 'moderate_speeding',
    high_speeding: 'high_speeding',
    severe_speeding: 'severe_speeding',
};

const DriversScoring = (props) => {

    const { company, toggleLoader } = props;

    const [searchText, setSearchText] = useState('')
    const [drivers, setDrivers] = useState([])
    const [initialData, setInitialData] = useState([])
    const [tabKey, setTabKey] = useState(5)
    const [start_date, setStart_date] = useState(moment().startOf('month').format('YYYYMMDD'))
    const [end_date, setEnd_date] = useState(moment().format('YYYYMMDD'))

    const [showCoachEventModal, setShowCoachEventModal] = useState(false)
    const [showEventWeightsModal, setShowEventWeightsModal] = useState(false)
    const [coachingItem, setCoachingItem] = useState(null)
    const [eventWeightsArr, setEventWeightsArr] = useState(initEventWeightsArr)
    const [hideDescription, setHideDescription] = useState(JSON.parse(localStorage.getItem('hideDriverScoreDescription')) || false)

    useEffect(() => {
        getDriverScores();
        getEventWeights();
        setSearchText('')
    }, [company.company_id])

    useEffect(() => {
        getDriverScores();
    }, [tabKey, start_date, end_date])

    // const getAllDriversScores = async () => {
    //     const event_types = eventWeightsArr.reduce((typesArr, event) => {
    //         if (event.enabled && request_types[event.name]) {
    //             typesArr.push(request_types[event.name])
    //         }
    //         return typesArr;
    //     }, []);

    //     let data = [];
    //     drivers.map(item => {
    //         data.push({
    //             start_ts: moment.utc(moment((start_date)).startOf('day')).format('YYYYMMDDHHmmss'),
    //             end_ts: moment.utc(moment((end_date)).endOf('day')).format('YYYYMMDDHHmmss'),
    //             alert_types: '',
    //             event_types: event_types.join(','),
    //             driver_id: item.id
    //         });
    //     });

    //     let dataSend = [];
    //     data.map(item => {
    //         dataSend.push(
    //             apiCallGet(`/driver/${item.driver_id}/alerts`, item)
    //                 .then(res => {
    //                     if (!!res.data.response) {
    //                         let sum = 0;
    //                         res.data.response[0].events.map(event => {
    //                             eventWeightsArr.map(weight => { if (event.event_type.toLowerCase() === weight.name.toLowerCase()) sum += weight.weight })
    //                         });
    //                         drivers.map(driver => {
    //                             if (driver.id === item.driver_id) {
    //                                 driver.events = res.data.response[0].events;
    //                                 driver.driver_score = sum;
    //                             }
    //                         });
    //                         setDrivers(drivers);
    //                     }
    //                 })
    //                 .catch((error) => {
    //                 })
    //         );
    //     });
    //     await Promise.all(dataSend);
    // }

    const getDriverScores = async () => {
        const data = {
            start_ts: moment.utc(moment(start_date).startOf('day')).format('YYYYMMDDHHmmss'),
            end_ts: moment.utc(moment(end_date).endOf('day')).format('YYYYMMDDHHmmss'),
            company_id: company.company_id,
        }
        toggleLoader(true);
        await getDriverScoresByTimeframe(data)
            .then((res) => {
                const { drivers } = res.data.response
                drivers.forEach(driver => {
                    driver.events = []
                    driver.isExpanded = false
                    driver.eventsRequested = false // its marker that /alerts request was called already for this driver
                    // driver.driver_score = 0;
                });
                setDrivers(drivers);
                toggleLoader(false);
            })
            // .then(async () => {
            // 	await getAllDriversScores();
            // 	toggleLoader(false);
            // })
            .catch(err => {
                toggleLoader(false);
                toastr.error('There was an issue getting driver scores. Please try again later.')
                console.log(err);
            })
    }

    const getEventWeights = () => {
        getDriverEventWeights(company.company_id)
            .then(res => {
                const updatedWeights = _.unionBy(res.data.response.event_weights, initEventWeightsArr, 'name')
                setEventWeightsArr(updatedWeights)
            })
            .catch(err => {
            })
    }

    const getDriverEvents = (row) => {
        if (row.isExpanded) {
            toggleExpandRow(row, false)
        }
        // else if (!row.events.length) {
        else if (row.eventsRequested) {
            toggleExpandRow(row, true)
        }
        else {
            const event_types = eventWeightsArr.reduce((typesArr, event) => {
                if (event.enabled && request_types[event.name]) {
                    typesArr.push(request_types[event.name])
                }
                return typesArr
            }, [])
            const data = {
                start_ts: moment.utc(moment((start_date)).startOf('day')).format('YYYYMMDDHHmmss'),
                end_ts: moment.utc(moment((end_date)).endOf('day')).format('YYYYMMDDHHmmss'),
                alert_types: '',
                event_types: event_types.join(','),
                driver_id: row.id,
            };

            toggleLoader(true);
            // TODO: implementation of the no_alarms attribute once the FWSD-5381 done
            apiCallGet('/driver/{driver_id}/alerts', data)
                .then(res => {
                    const { response } = res.data

                    const data = [];
                    if (row.events_score) {
                        data.push({
                            type: "Total Events Score",
                            id: 0,
                            event_id: row.events_score,
                            timestamp: start_date,
                        })
                    }
                    if (row.speeding_score) {
                        data.push({
                            type: "Total Speeding Score",
                            id: -1,
                            event_id: row.speeding_score,
                            timestamp: start_date,
                        })
                    }
                    if (row.idling_score) {
                        data.push({
                            type: "Idling",
                            id: -2,
                            event_id: row.idling_score,
                            timestamp: start_date,
                        })
                    }
                    const driversWithEvents = [...drivers];
                    if (response.length) {
                        response.forEach(eventsGroup => {
                            const { driver_id, driver_name, device_id, vehicle_name, events } = eventsGroup;

                            if (events && events.length) {
                                events.forEach((driverEvent) => {
                                    data.push({
                                        id: driverEvent.id,
                                        device_id,
                                        vehicle_name,
                                        driver_id,
                                        driver_name,
                                        timestamp: driverEvent.timestamp,
                                        type: driverEvent.event_type,
                                        event_id: driverEvent.id,
                                        event_type: driverEvent.event_type,
                                    })
                                })
                            }
                        })

                    }
                    const expandableDriver = driversWithEvents[drivers.findIndex(driver => driver.id === row.id)];
                    expandableDriver.events = data;
                    expandableDriver.eventsRequested = true;
                    setDrivers(driversWithEvents);
                    toggleExpandRow(row, true);
                    toggleLoader(false);
                })
                .catch((error) => {
                    toggleExpandRow(row, true)
                    console.log(error.response)
                    toggleLoader(false);
                    let errDescription = 'An unexpected error occurred. Please try again later'
                    if (error.response.data.response.error) {
                        errDescription = error.response.data.response.error
                    }
                    toastr.error(errDescription);
                })
        }
    }

    const updateScores = () => {
        setShowEventWeightsModal(false)
        getDriverScores()
        getEventWeights()
    }

    const selectDate = (tabKey, startDate, endDate) => {
        let new_start_date, new_end_date;
        if (tabKey) {
            const dateRange = getDateRangeFromTabKey(tabKey)
            new_start_date = dateRange[0];
            new_end_date = dateRange[1];
        }
        else {
            //manually selected date
            new_start_date = startDate ? moment(startDate).format('YYYYMMDD') : start_date;
            new_end_date = endDate ? moment(endDate).format('YYYYMMDD') : end_date;
        }
        setStart_date(new_start_date)
        setEnd_date(new_end_date)
        setTabKey(tabKey)
        // getDriverScores(new_start_date, new_end_date);
    }

    const formatExpandColumn = (cell, row) => (
        <button className={classNames('expand-icon', { rotate: cell })} onClick={() => getDriverEvents(row)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right item-arrow" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
            </svg>
        </button>
    )

    const toggleExpandRow = (row, expand) => {
        const driversWithEvents = JSON.parse(JSON.stringify(drivers))
        driversWithEvents[drivers.findIndex(driver => driver.id === row.id)].isExpanded = expand;
        setDrivers(driversWithEvents);
    }

    const formatScore = (cell, row) => {
        return (
            <CustomButton variant="link" onClick={(e) => {
                e.stopPropagation();
                openCreateCoachEventModal(row)
            }}>
                {Math.round(cell)}
            </CustomButton>
        )
    }

    const formatCSVFileName = () => {
        const timeframe = moment(start_date).format('M-D-YY') + (start_date === end_date ? '' : '--' + moment(end_date).format('M-D-YY'))
        return `driver_scoring_report_${timeframe}.csv`
    }

    const openCreateCoachEventModal = (row) => {
        setShowCoachEventModal(true)
        setCoachingItem(row)
    }

    const toggleShowDescription = () => {
        localStorage.setItem('hideDriverScoreDescription', JSON.stringify(!hideDescription))
        setHideDescription(!hideDescription)
    }

    const filteredDrivers = drivers.filter(driver => {
        let name = driver.first_name + ' ' + driver.last_name;
        if (!searchText || name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
        return false;
    })

    const event_types = eventWeightsArr.reduce((typesArr, event) => {
        if (event.enabled && request_types[event.name]) {
            typesArr.push(request_types[event.name])
        }
        return typesArr;
    }, [])
    const paramsRequestEvents = {
        start_ts: moment.utc(moment((start_date)).startOf('day')).format('YYYYMMDDHHmmss'),
        end_ts: moment.utc(moment((end_date)).endOf('day')).format('YYYYMMDDHHmmss'),
        alert_types: '',
        event_types: event_types.join(','),
        // driver_id: item.id
    }

    return (
        <div className="driver-scoring-container">
            <div className='page-subheader'>
                <div className='subheader-section search-flex'>
                    <SearchBar
                        term={searchText}
                        placeholder="Search Driver..."
                        onSearch={(e) => setSearchText(e.target.value)}
                    />
                    <DateDropdown
                        start_date={start_date}
                        end_date={end_date}
                        tabKey={tabKey}
                        onSelectDate={selectDate}
                        limitDates={['This Week', 'This Month', 'Last Month']}
                    />
                </div>
                <div className='subheader-section'>
                    {hideDescription &&
                        <CustomButton variant='secondary' size='icon' title='More...' onClick={toggleShowDescription} style={{ border: "none" }}>
                            {questionIcon}
                        </CustomButton>
                    }
                    <CustomButton variant="primary-outline" prefix="header-button" onClick={() => setShowEventWeightsModal(true)}>
                        Adjust Event Weights
                    </CustomButton>
                </div>
            </div>

            <main className="main-content-block driver-page-table">

                {!hideDescription &&
                    <section className='asset-description'>
                        <h3 className='category-title text-center'>Driver Scoring</h3>
                        <p className='asset-paragraph'>
                            Drivers are rated on a score from <strong>0</strong> (best)
                            to <strong>200</strong> (worst). The scores are based on the amount of
                            events per mile driven each driver registers over a given timeframe, as well as
                            the weight assigned to each event type. You can adjust which event types
                            factor into the score, as well as the weight they are given by
                            clicking <strong>Adjust Event Weights</strong> at the top of the screen.
                        </p>
                        <div className='text-center'>
                            <CustomButton variant='link' onClick={toggleShowDescription}>Hide</CustomButton>
                        </div>
                    </section>
                }

                <BootstrapTable
                    data={filteredDrivers}
                    {...tableExportOptions}
                    options={{
                        ...paginationExportOptions,
                        expandBy: 'column'
                    }}
                    expandableRow={(row) => row.isExpanded}
                    expandComponent={(row) => <DriversScoreTable data={row.events} weights={eventWeightsArr} />}
                    // expandComponent={(row) => <DriversScoreTable driver={row} data={row.events} weights={eventWeightsArr} paramsRequestEvents={paramsRequestEvents} />}
                    csvFileName={formatCSVFileName()}
                >
                    <TableHeaderColumn dataField='id' isKey hidden />
                    <TableHeaderColumn
                        dataField='isExpanded'
                        width='25'
                        dataFormat={formatExpandColumn}
                        columnClassName={'no-padding-column'}
                        export={false}
                    />
                    <TableHeaderColumn
                        width='25%'
                        dataField='first_name'
                        dataFormat={formatName}
                        dataSort
                        csvFormat={formatName}
                        csvHeader={'Driver Name'}
                        className='no-border'
                        expandable={false}
                    >
                        Driver Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='25%'
                        dataField='driver_score'
                        dataFormat={formatScore}
                        dataSort
                        csvFormat={cell => Math.round(cell)}
                        csvHeader={'Score'}
                        expandable={false}
                    >
                        Score
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='25%'
                        dataField='group_name'
                        dataFormat={cell => cell}
                        dataSort
                        csvFormat={cell => cell}
                        csvHeader={'Group Name'}
                        expandable={false}
                    >
                        Group Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='25%'
                        dataField='card_id'
                        dataFormat={cell => cell}
                        dataSort
                        csvFormat={cell => cell}
                        csvHeader={'Card ID'}
                        expandable={false}
                    >
                        Card ID
                    </TableHeaderColumn>
                </BootstrapTable>
            </main>

            {showCoachEventModal &&
                <ModalCoachEvent
                    onHide={() => setShowCoachEventModal(false)}
                    coachingItem={coachingItem}
                    type={'score'}
                />
            }

            {showEventWeightsModal &&
                <ModalEventWeights
                    onHide={() => setShowEventWeightsModal(false)}
                    eventWeightsArr={eventWeightsArr}
                    updateScores={updateScores}
                />
            }
        </div>
    );
}

DriversScoring.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    company: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(DriversScoring);
