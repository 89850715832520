import React, { useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import classnames from 'classnames';

import { changePassword } from '../../../../action/AccountActions';
import { passwordValidation } from '../../../helpers/helperAccount';
import CustomButton from '../../../CustomButton';
import { toastr } from 'react-redux-toastr';

const ResetPasswordForm = (props) => {
    const { toggleLoader } = props;

    const [data, setData] = useState({
        password: '',
        password_confirm: '',
    })
    const [errMessage, setErrMessage] = useState({
        password: '',
        password_confirm: '',
    })
    const [toggle_password, setToggle_password] = useState(true)
    const [toggle_password_confirm, setToggle_password_confirm] = useState(true)
    const [disabledSave, setDisabledSave] = useState(true)

    const togglePassword = () => {
        setToggle_password(!toggle_password);
    }

    const togglePassword2 = () => {
        setToggle_password_confirm(!toggle_password_confirm);
    }

    const handleInputChange = (e) => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);

        (!data.password && !data.password_confirm) ? setDisabledSave(true) : setDisabledSave(checkForm(newData));
    }

    const checkForm = (data) => {
        let error = false;
        const updatedErrMessage = { ...errMessage };

        for (let i in data) {
            if (data[i] == '') {
                error = true;
                updatedErrMessage[i] = 'Enter your password!';
            }
            else if (updatedErrMessage[i]) {
                updatedErrMessage[i] = null;
            }
        }

        if (!updatedErrMessage.password) {
            const validation = passwordValidation(data.password)
            if (!validation.result) {
                error = true;
                updatedErrMessage.password = validation.error;
            }
            else if (data.password !== data.password_confirm) {
                error = true;
                updatedErrMessage.password = 'Passwords do not match';
            }
        }

        if (!updatedErrMessage.password_confirm) {
            if (data.password !== data.password_confirm) {
                error = true;
                updatedErrMessage.password_confirm = 'Passwords do not match';
            }
        }

        setErrMessage(updatedErrMessage);
        return error;
    }

    const sendForm = (e) => {
        e.preventDefault()

        const params = {
            token: props.data.location.query.token,
            password: data.password,
            password_confirm: data.password_confirm
        };
        const error = checkForm(params);
        if (!error) {
            toggleLoader(true);

            changePassword(params)
                .then((res, err) => {
                    toggleLoader(false);
                    toastr.success('Password change successful');
                    browserHistory.push('/login');
                })
                .catch((error, p) => {
                    toggleLoader(false);
                    if (error.response.data.response.error) toastr.error(error.response.data.response.error);
                });
        }
    }

    const { password, password_confirm } = errMessage;

    return (
        <>
            <div className="panel-body">
                <h3 className='login-header'>Reset Password</h3>
                <div className="form-wrapper">
                    <form onSubmit={sendForm}>
                        <div className={classnames('login-form form-group has-feedback', { 'error': password })} style={{ marginTop: '30px', maxHeight: '54px' }}>
                            <input type={toggle_password ? "password" : "text"} name="password" className={`vehicle-search ${password ? 'error' : ''}`} placeholder='New Password' onChange={handleInputChange} />
                            <span className="fa fa-eye form-control-feedback" onClick={togglePassword}></span>
                            {password && <span className="help-block error-label">{password}</span>}
                        </div>
                        <div className={classnames('login-form form-group has-feedback', { 'error': password_confirm })} style={{ marginTop: '30px', maxHeight: '54px' }}>
                            <input type={toggle_password_confirm ? "password" : "text"} name="password_confirm" className={`vehicle-search ${password_confirm ? 'error' : ''}`} placeholder='Re-enter new password' onChange={handleInputChange} />
                            <span className="fa fa-eye form-control-feedback" onClick={togglePassword2}></span>
                            {password_confirm && <span className="help-block error-label">{password_confirm}</span>}
                        </div>
                        <div className="change-password-submit form-group submit-btn" style={{ marginTop: '80px' }}>
                            <CustomButton variant="primary" size="lg" value="Submit" type="submit" id="restore-button" disabled={disabledSave}>Save Password</CustomButton>
                        </div>
                    </form>
                </div >
            </div >
            <div className="panel-body contact-section">
                <h5>Password must have:</h5>
                <div className="login-subheader" style={{ marginTop: 0 }}>
                    <ul>
                        <li>Atleast 8 characters long</li>
                        <li>Must contain atleast I uppercase character</li>
                        <li>Must contain atleast Ilower case character</li>
                        <li>Must contain a number</li>
                    </ul>
                </div>
            </div >
        </>
    )
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(ResetPasswordForm);
