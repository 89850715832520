import _ from 'lodash'
// import API from 'mg-api-node'
import GeotabApi from 'mg-api-js'
import { promisify } from 'util'
import { toastr } from 'react-redux-toastr'

import { insertGeotabCompany } from '../../action/GeotabActions';

export function getConvertedURL(url) {
	const env = location.hostname
	let domain = ''

	switch (env) {
		case 'dev.roscolive.com':
			domain = 'geotabdev'
			break
		// case 'localhost':
		case 'test.roscolive.com':
			domain = 'geotabtest'
			break
		case 'localhost':
		case 'staging.roscolive.com':
			domain = 'geotabstaging'
			break
		// case 'localhost':
		case 'roscocloud.com':
		case 'www.roscocloud.com':
		case 'fleet.roscolive.com':
			return url
		default:
			break
	}

	return url.replace(/geotab/, domain)
}

export function getAddinConfig() {
	const env = location.hostname
	let ac = _.cloneDeep(constants.ADDIN_CONFIG);
	let domain = ''

	switch (env) {
		case 'dev.roscolive.com':
			domain = 'geotabdev'
			break
		// case 'localhost':
		case 'test.roscolive.com':
			domain = 'geotabtest'
			break
		case 'localhost':
		case 'staging.roscolive.com':
			domain = 'geotabstaging'
			break
		// case 'localhost':
		case 'roscocloud.com':
		case 'www.roscocloud.com':
		case 'fleet.roscolive.com':
			return ac
		default:
			break
	}

	ac.items = ac.items.map(item => {
		if (item.url) {
			item.url = item.url.replace(/geotab/, domain)
		} else if (item.mapScript && item.mapScript.src) {
			item.mapScript.src = item.mapScript.src.replace(/geotab/, domain)
		}

		return item
	})

	return ac
}

export const constants = {
	webfleet_apikey: '8fdb255c-4246-4b34-b891-0b4c087bd27e',
	webfleet_plugin_card_apikey: '40d126f5-ef7a-4ba7-bafb-0c267e2cf78d',
	WEBFLEET_CARD_CONFIG: {
		version: '1.0.0',
		defaultLocale: 'en-US',
		title: { 'en-US': 'Rosco Live' },
		externalLinks: {
			name: { 'en-US': 'External links' },
			value: [
				{
					href: { 'en-US': 'https://fleet.roscolive.com' },
					title: { 'en-US': 'Go to Rosco Live' },
					description: { 'en-US': 'By clicking you will be redirected to the Rosco Live App.' }
				}
			]
		},
		data: [
			[
				{
					name: { 'en-US': 'Camera Name' },
					value: { 'en-US': 'Truck 004' }
				}
			],
			[
				{
					name: { 'en-US': 'Network Status' },
					value: { 'en-US': 'Off' }
				}
			]
		]
	},
	ADDIN_CONFIG: {
		name: 'Dual-Vision Video Camera',
		supportEmail: 'techsupport@roscovision.com',
		version: '2.31.0',
		items: [
			{
				menuId: 'RoscoLive',
				svgIcon: 'https://geotabstaging.roscolive.com/images/rosco_logo.svg',
				// icon: 'https://geotab.roscocloud.com/images/icon.png',
				menuName: {
					en: 'RoscoLive',
				},
			},
			{
				url: 'https://geotab.roscolive.com/events.html',
				svgIcon: 'https://geotabstaging.roscolive.com/images/rosco_events.svg',
				path: 'RoscoLive/',
				menuName: {
					en: 'Camera Events',
				},
			},
			{
				url: 'https://geotab.roscolive.com/vehicles.html',
				svgIcon: 'https://geotabstaging.roscolive.com/images/rosco_vehicles.svg',
				path: 'RoscoLive/',
				menuName: {
					en: 'Camera Admin',
				},
			},
			{
				url: 'https://geotab.roscolive.com/video.html',
				svgIcon: 'https://geotabstaging.roscolive.com/images/rosco_live_video.svg',
				path: 'RoscoLive/',
				menuName: {
					en: 'Live Video',
				},
			},
			{
				url: 'https://geotab.roscolive.com/driver_scoring.html',
				svgIcon: 'https://geotabstaging.roscolive.com/images/rosco_notifications.svg',
				path: 'RoscoLive/',
				menuName: {
					en: 'Driver Scoring',
				},
			},
			{
				url: 'https://geotab.roscolive.com/notifications.html',
				svgIcon: 'https://geotabstaging.roscolive.com/images/rosco_notifications.svg',
				path: 'RoscoLive/',
				menuName: {
					en: 'Notification Portal',
				},
			},
			{
				page: 'map',
				title: 'ROSCOlive',
				mapScript: {
					src: 'https://geotab.roscolive.com/maptools.js?SameSite=None',
				},
			},
			{
				page: 'tripsHistory',
				title: 'ROSCOlive',
				noView: true,
				mapScript: {
					src: 'https://geotab.roscolive.com/triptools.js?SameSite=None',
				},
			},
			{
				url: 'https://geotab.roscolive.com/live.html'
			},
			{
				url: 'https://geotab.roscolive.com/player.html'
			}
		],
		solutionId: 'roscoDV6',
		isSigned: false,
		enableViewSecurityId: true,
		securityIDs: [
			{
				name: 'CameraEventsPageAccess',
				en: 'RoscoLive - Camera Events Page'
			},
			{
				name: 'AllowViewEvent',
				en: 'RoscoLive - View Camera Events'
			},
			{
				name: 'AllowDownloadEvent',
				en: 'RoscoLive - Download Camera Events'
			},
			{
				name: 'AllowCustomVideo',
				en: 'RoscoLive - Request Custom Video'
			},
			{
				name: 'CameraAdminPageAccess',
				en: 'RoscoLive - Camera Admin Page'
			},
			{
				name: 'AllowCameraPairing',
				en: 'RoscoLive - Allow Camera Pairing to GO Device'
			},
			{
				name: 'AllowFormat',
				en: 'RoscoLive - Allow Format SD Card'
			},
			{
				name: 'AllowSaveFleetProfile',
				en: 'RoscoLive - Allow Save Fleet Profiles'
			},
			{
				name: 'NotificationPortalPageAccess',
				en: 'RoscoLive - Notification Portal Page'
			},
			{
				name: 'AllowAddNotificationUser',
				en: 'RoscoLive - Allow Add Notification User'
			},
			{
				name: 'AllowReportDownload',
				en: 'RoscoLive - Allow Report Download'
			},
			{
				name: 'MapAddInAccess',
				en: 'RoscoLive - Map Add-in'
			},
			{
				name: 'AllowLiveStream',
				en: 'RoscoLive - View Camera Live Stream'
			},
			{
				name: 'CameraHealthPageAccess',
				en: 'RoscoLive - Camera Health Page'
			},
			{
				name: "DriverScoringPageAccess",
				en: "RoscoLive - View Driver Score Page"
			},
			{
				name: "AllowEditDriverScoreSettings",
				en: "RoscoLive - Edit Driver Score Settings"
			}
		]
	},
}

// FWSD-4475
export async function geotabAuthentication(initData, refreshInitData) {
	// console.log('geotabAuthentication initData company_id: ', initData.company_id, initData)
	const data = { ...initData }

	let geotabApi
	let resAuth

	try {
		// With Password:
		// If you are connecting with the wrapper for the first time, you must pass a username/password combo of a user on the database you are trying to connect to.
		// With SessionId:
		// If you already have a session, you can pass in the SessionId, and the wrapper will attempt to authenticate using this first.
		geotabApi = new GeotabApi({
			credentials: {
				database: data.database,
				userName: data.email,
				password: data.password,
				sessionId: data.sessionId
			},
			path: data.path
		})

		// const authenticate = promisify(geotabApi.authenticate); // forces return Promise from api.authenticate
		// call = promisify(geotabApi.call);
		// resAuth = await authenticate();

		await geotabApi.authenticate()
				.then( response => {
					resAuth = response
					//console.log('I have authenticated response: ', response);
				})

		// resAuth = await authenticate(
		// 	res => {
		// 		// console.log('!Geotab Authenticate res: ', res)
		// 		const { credentials } = res
		// 		data.email = credentials.userName;
		// 		data.database = credentials.database;
		// 		data.sessionId = credentials.sessionId;

		// 		return { geotabApi, geotabData: data }
		// 	},
		// 	error => {
		// 		console.log('!Geotab Authenticate error: ', error)

		// 		if (error.message) {
		// 			toastr.error(error.message)
		// 		}
		// 		else {
		// 			// toastr.error('An unexpected error occurred. Please try again later')
		// 			toastr.error(error)
		// 		}

		// 		return { error: true }
		// 	});

		data.email = resAuth.credentials.userName;
		data.database = resAuth.credentials.database;
		data.sessionId = resAuth.credentials.sessionId;

		// stores to the geotab_company table new generated session_id
		// insertGeotabCompany(data)
		// 	.then(() => {
		// 		if (typeof refreshInitData === 'function') {
		// 			return refreshInitData()
		// 		}
		// 		// getCompanyGeotabData()
		// 	})
		// 	.catch((error) => {
		// 		console.log('!insertGeotabCompany error: ', error.response, error);
		// 	});
	}
	catch (errAuth) {
		console.log('!Error Geotab authentication errAuth2: ', errAuth);
		return { error: true }
	}

	// console.log('GeitabAPI: ', geotabApi);
	return { geotabApi, geotabData: data }
}
