import React, { useState, useEffect } from 'react'
// import { browserHistory } from 'react-router'
// import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'
import GeotabApi from 'mg-api-js';
// import API from 'mg-api-node'
// import GeotabApi from 'mg-api-js';
// import { loginUser, logoutUser } from '../../../../action/AccountActions';
// import Auth from '../../../../component/Auth';

import ModalDelete from '../modals/ModalDelete'

import { getAddinConfig, getConvertedURL } from '../../helpers/helperGeotab'
// import { passwordValidation } from '../../helpers/helperAccount';
import { companyHash, companyCheckFakeEmails, insertGeotabCompany, getGeotabCompanyByCompanyId } from '../../../action/GeotabActions'

import '../../dump/menuComponents/menuComponents.sass'
import CustomButton from '../../CustomButton'

const addinConfig = getAddinConfig()

const GeotabCredentialsContainer = (props) => {
	const { toggleLoader } = props;

	const [company, setCompany] = useState('')
	const [systemSettings, setSystemSettings] = useState('')
	const [data, setData] = useState({
		email: '',
		password: '',
		database: ''
	})
	const [errMessage, setErrMessage] = useState({
		email: '', // romabogdan@gmail.com
		password: '', // roman2012
		database: '',
	})
	const [modal, setModal] = useState({
		show: false,
		title: '',
		content: '',
		error: false,
	})
	const [toggle_password, setToggle_password] = useState(true)
	const [geotabCompany, setGeotabCompany] = useState(null)
	const [showConfirmUpdate, setShowConfirmUpdate] = useState(false)

	useEffect(() => {
		toggleLoader(true);

		const hash = props.params.companyHash
		companyHash(hash)
			.then(res => {
				toggleLoader(false)
				console.log('!companyHash res', res)

				setCompany(res.data.response)
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!gcompanyHash error: ', error)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}, []);

	const togglePassword = () => {
		console.log('state change', toggle_password);
		setToggle_password(!toggle_password);
	}

	const fillForm = (e) => {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	const getGeotabProvider = (e, t, y) => {
		const { provider } = props.params
		console.log('getGeotabProvider', provider, e, t, y, props)
		switch (provider) {
			case '1':
				return 'my.geotab.com'
				break
			case '2':
				return 'mysyncupfleet.com'
				break
			case '3':
				return 'mypreview.geotab.com'
				break
			case '4':
				return 'gov.geotab.com'
				break
			default:
				return 'undefined'
				break
		}
	}

	const checkForm = (data) => {
		let error = false;
		const updatedErrMessage = { ...errMessage };

		for (let i in data) {
			if (data[i] == '') {
				error = true;
				updatedErrMessage[i] = 'Enter your ' + i + '!';
			}
			else if (updatedErrMessage[i]) {
				updatedErrMessage[i] = null;
			}
		}

		// if (!errMessage.password) {
		// 	const validation = passwordValidation(data.password)
		// 	if (!validation.result) {
		// 		error = true;
		// 		errMessage.password = validation.error;
		// 	}
		// }

		//        if (!errMessage['email']) {
		//            var re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		//            if (!re.test(data['email'])){
		//                error = true;
		//                errMessage['email'] = 'Email is not valid.';
		//            }
		//        }

		setErrMessage(updatedErrMessage);
		return error;
	}

	const updateGeotabPairing = () => {
		let dataUpdate = data;
		toggleLoader(true);

		// api.forget();
		const provider = getGeotabProvider();

		const authentication = {
			credentials: {
				database: dataUpdate.database,
				userName: dataUpdate.email,
				password: dataUpdate.password
			},
			path: provider
		}
		
		// const api = new API(dataUpdate.email, dataUpdate.password, dataUpdate.database, provider)
		const api = new GeotabApi(authentication);
		// api.authenticate((err, res) => {
		api.authenticate(
			(res) => {
				console.log('!Geotab Authenticate res: ', res,)

				// checks if exists pairing with Rosco company_id already
				// and if so then requires confirmation to update pairing credentials

				// getGeotabCompanyByCompanyId({ company_id: dataUpdate.company_id  })
				// 	.then((res) => {
				// 		console.log('getGeotabCompanyByCompanyId res: ', res)
				// 		getByCompanyId
				// 	})
				// 	.catch((err) => {
				// 		console.log('getGeotabCompanyByCompanyId error: ', err)
				// 		toastr.error('An unexpected error occurred. Please try again later');
				// 	})

				//  else if (res.data.result && res.data.result.path && res.data.result.credentials && res.data.result.credentials.database) {
				if (res.path && res.credentials && res.credentials.database) {
					const newData = data;
					newData.path = (res.path === 'ThisServer') ? provider : res.path
					newData.database = res.credentials.database
					newData.sessionId = res.credentials.sessionId
					// setData(newData)
					newData.company_id = company.id

					api.multiCall([
						['Get', {
							typeName: 'SystemSettings',
							// credentials: {
							// 	database: newData.database,
							// 	userName: newData.email,
							// 	sessionId: newData.sessionId
							// 	// password: newData.password
							// }
						}],
						['Get', {
							typeName: 'AddIn',
						}],
						['GetNotificationWebRequestTemplates', {
							credentials: {
								database: newData.database,
								userName: newData.email,
								sessionId: newData.sessionId
								// password: newData.password
							}
						}],
					],
					(res) => {
						console.log('!GET Geotab Settings res: ', res);

						//* Checks existing of the fake emails: geotab_<partner>_<companyname>_fleet and geotab_<partner>_<companyname>_user
						const hash = props.params.companyHash;
						companyCheckFakeEmails(hash)
							.then((resFakeEmails, errFakeEmails) => {
								console.log('!companyCheckFakeEmails resFakeEmails, errFakeEmails: ', resFakeEmails, errFakeEmails);
							})
							.catch((errorFakeEmails) => {
								console.log('!companyCheckFakeEmails errorFakeEmails: ', errorFakeEmails);
								let errDescription = 'An unexpected error occurred. Please try again later'
								if (errorFakeEmails.response.data.response.error) {
									errDescription = errorFakeEmails.response.data.response.error
								}
								toastr.error(errDescription)
							});
						//************************************************************

						const systemSettings = res[0][0]
						const addinsList = res[1]
						const notificationWebRequests = res[2]
						// addinConfig = getAddinConfig();
						let notif_snapshot_exist_id = false;
						let notif_video_exist_id = false;
						let notif_snapshot_and_video_exist_id = false;
						let systemAddinExist = false;
						const multicalls = [];

						// console.log('systemSettings', systemSettings);
						// console.log('systemSettings.customerPages', systemSettings.customerPages);

						newData.fleet_token = systemSettings.companyGuid;

						// systemSettings.customerPages.map((addin, i) => {
						// 	// if (JSON.parse(addin).name == 'RoscoLive' && system_addin_exist === false) {
						// 	if (JSON.parse(addin).name === 'Dual-Vision Video Camera' && system_addin_exist === false) {
						// 		system_addin_exist = i;
						// 	}
						// })
						addinsList.forEach((addin, i) => {
							// console.log(addin.configuration.name)
							if (addin?.configuration?.name === 'Dual-Vision Video Camera' && systemAddinExist === false) {
								systemAddinExist = i
							}
						})

						notificationWebRequests.map((notification, i) => {
							if (notification.name == 'Auto-upload snapshot to RoscoLive' && !notif_snapshot_exist_id) {
								notif_snapshot_exist_id = notification.id;
							}
							else if (notification.name == 'Auto-upload video to RoscoLive' && !notif_video_exist_id) {
								notif_video_exist_id = notification.id;
							}
							else if (notification.name == 'Auto-upload snapshot and video clip to RoscoLive' && !notif_snapshot_and_video_exist_id) {
								notif_snapshot_and_video_exist_id = notification.id;
							}
						})

						// systemSettings.allowUnsignedAddIn = true;
						// if (system_addin_exist !== false) {
						// 	systemSettings.customerPages[system_addin_exist] = JSON.stringify(addinConfig);
						// }
						// else {
						// 	systemSettings.customerPages.push(JSON.stringify(addinConfig));
						// }
						// multicalls.push(
						// 	['Set', {
						// 		typeName: 'SystemSettings',
						// 		entity: systemSettings,
						// 		credentials: {
						// 			database: newData.database,
						// 			userName: newData.email,
						// 			sessionId: newData.sessionId
						// 		},

						// 	}]
						// )
						multicalls.push(
							[systemAddinExist === false ? 'Add' : 'Set', {
								typeName: 'AddIn',
								// entity: { configuration: JSON.stringify(addinConfig) },
								entity: { configuration: addinConfig },
							}],
						)

						if (notif_snapshot_and_video_exist_id) {
							multicalls.push(
								['Set', {
									typeName: 'NotificationBinaryFile',
									entity: {
										id: notif_snapshot_and_video_exist_id,
										url: getConvertedURL('https://geotab.roscocloud.com/api/v1.0/geotab/custom_event/all'),
										name: 'Auto-upload snapshot and video clip to RoscoLive',
										requestType: 'Post',
										postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
										type: 'WebRequestTemplate',
									},
									credentials: {
										database: newData.database,
										userName: newData.email,
										sessionId: newData.sessionId
									}
								}]
							)
						} else {
							multicalls.push(
								['Add', {
									typeName: 'NotificationBinaryFile',
									entity: {
										url: getConvertedURL('https://geotab.roscocloud.com/api/v1.0/geotab/custom_event/all'),
										name: 'Auto-upload snapshot and video clip to RoscoLive',
										requestType: 'Post',
										postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
										type: 'WebRequestTemplate',
									},
									credentials: {
										database: newData.database,
										userName: newData.email,
										sessionId: newData.sessionId
									}
								}]
							)
						}

						if (notif_snapshot_exist_id) {
							multicalls.push(
								['Set', {
									typeName: 'NotificationBinaryFile',
									entity: {
										id: notif_snapshot_exist_id,
										url: getConvertedURL('https://geotab.roscocloud.com/api/v1.0/geotab/custom_event/snapshots'),
										name: 'Auto-upload snapshot to RoscoLive',
										requestType: 'Post',
										postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
										type: 'WebRequestTemplate',
									},
									credentials: {
										database: newData.database,
										userName: newData.email,
										sessionId: newData.sessionId
									}
								}]
							)
						} else {
							multicalls.push(
								['Add', {
									typeName: 'NotificationBinaryFile',
									entity: {
										url: getConvertedURL('https://geotab.roscocloud.com/api/v1.0/geotab/custom_event/snapshots'),
										name: 'Auto-upload snapshot to RoscoLive',
										requestType: 'Post',
										postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
										type: 'WebRequestTemplate',
									},
									credentials: {
										database: newData.database,
										userName: newData.email,
										sessionId: newData.sessionId
									}
								}]
							)
						}

						if (notif_video_exist_id) {
							multicalls.push(
								['Set', {
									typeName: 'NotificationBinaryFile',
									entity: {
										id: notif_video_exist_id,
										url: getConvertedURL('https://geotab.roscocloud.com/api/v1.0/geotab/custom_event/videos'),
										name: 'Auto-upload video to RoscoLive',
										requestType: 'Post',
										postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
										type: 'WebRequestTemplate',
									},
									credentials: {
										database: newData.database,
										userName: newData.email,
										sessionId: newData.sessionId
									}
								}]
							)
						} else {
							multicalls.push(
								['Add', {
									typeName: 'NotificationBinaryFile',
									entity: {
										url: getConvertedURL('https://geotab.roscocloud.com/api/v1.0/geotab/custom_event/videos'),
										name: 'Auto-upload video to RoscoLive',
										requestType: 'Post',
										postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
										type: 'WebRequestTemplate',
									},
									credentials: {
										database: newData.database,
										userName: newData.email,
										sessionId: newData.sessionId
									}
								}]
							)
						}

						insertGeotabCompany(newData)
							.then((res, err) => {
								console.log('!insertGeotabCompany res', res, err);

								api.multiCall(
									multicalls,
									(res, err) => {
										console.log('!SET SystemSettings res', res, err);
										toggleLoader(false);

										if (err) {
											console.log('!SET SystemSettings error', err);

											let errDescription = 'An unexpected error occurred. Please try again later'
											if (err.response.data.response.error) {
												errDescription = err.response.data.response.error
											}
											toastr.error(errDescription)

											return;
										}

										toastr.success("Success", "The credentials were saved");
									},
									(a, p) => {
										toggleLoader(false);
										console.log('!SET SystemSettings1 a p: ', a, p);
										toastr.error('An unexpected error occurred. Please try again later');
									}
								);
							})
							.catch((error) => {
								console.log('!insertGeotabCompany error: ');
								toggleLoader(false);

								let errDescription = 'An unexpected error occurred. Please try again later'
								if (error.response.data.response.error) {
									errDescription = error.response.data.response.error
								}
								toastr.error(errDescription)
							});
					},
					(err) => {
						toggleLoader(false);
						console.log('!GET SystemSettings error: ', err);

						let errDescription = 'An unexpected error occurred. Please try again later'
						if (err.response.data.response.error) {
							errDescription = err.response.data.response.error
						}
						toastr.error(errDescription)
					});
				}
				else {
					toggleLoader(false);
					toastr.error('An unexpected error occurred. Please try again later');
				}
			},
			(error) => {
				toggleLoader(false);
				console.log('!Geotab Authenticate error: ', error)

				if (error.message) {
					toastr.error(error.message)
				}
				else {
					// toastr.error('An unexpected error occurred. Please try again later')
					toastr.error(error)
				}
			}
		)
	}

	const sendForm = async (e) => {
		e.preventDefault();
		
		const error = checkForm(data)
		if (!error) {
			let resPairingExists
			try {
				resPairingExists = await getGeotabCompanyByCompanyId({ company_id: company.id })
			}
			catch (errRequest) {
				console.log('!Error getGeotabCompanyByCompanyId: ', errRequest);
			}
			const geotabCompany = resPairingExists && resPairingExists.data.response

			if (resPairingExists && (geotabCompany.database.toLowerCase() !== data.database.toLowerCase() || geotabCompany.email.toLowerCase() !== data.email.toLowerCase())) {
				setGeotabCompany(geotabCompany)
				setShowConfirmUpdate(true)
			}
			else {
				updateGeotabPairing()
			}
		}
	}

	// console.log('PROPS GeotabCredentialsContainer: ', props);
	const { email, password, database } = errMessage;

	return (
		<div className="panel-body">
			<h3 className='login-header'>Geotab Credentials</h3>
			<div className="form-wrapper">

				<form onSubmit={sendForm}>
					<div className={classnames('form-group', { 'has-error': database })} style={{ paddingTop: '10px' }}>
						<label className={`login-label ${(data.database.length) ? 'active' : ''}`}>Database</label>
						<input
							type="text"
							id="myDatabase"
							name="database"
							className="vehicle-search"
							autoComplete="new-password"
							placeholder='Database'
							value={data.database}
							onChange={fillForm}
						/>
						{database && <span className="help-block">{database}</span>}
					</div>
					<div className={classnames('form-group', { 'has-error': email })}>
						<label className={`login-label ${(data.email.length) ? 'active' : ''}`}>E-mail</label>
						<input
							type="text"
							id="myGemail"
							name="email"
							className="vehicle-search"
							autoComplete="new-password"
							placeholder='E-Mail'
							value={data.email}
							onChange={fillForm}
						/>
						{email && <span className="help-block">{email}</span>}
					</div>
					<div className={classnames('login-form form-group has-feedback', { 'has-error': password })}>
						<label className={`login-label ${(data.password.length) ? 'active' : ''}`}>Password</label>
						<input
							type={toggle_password ? 'password' : 'text'}
							id="myPassword"
							name="password"
							className="vehicle-search"
							data-toggle="password"
							autoComplete="new-password"
							placeholder='Password'
							value={data.password}
							onChange={fillForm}
						/>
						<span className="fa fa-eye form-control-feedback" onClick={togglePassword}></span>
						{password && <span className="help-block">{password}</span>}
					</div>
					<div className="change-password-submit form-group submit-btn">
						<CustomButton type="submit" variant='primary' size='lg'>
							Save
						</CustomButton>
					</div>
				</form>

			</div>

			{showConfirmUpdate && (
				<ModalDelete
					title="Update Geotab Credentials"
					content={`The "${company.company_name}" company is currently paired with the Geotab database "${geotabCompany.database}" using the email "${geotabCompany.email}". By updating, the current credentials will be replaced by the newly entered ones. \n Are you sure you want to update these credentials?`}
					deleteBtnName="Update"
					styleView="primary"
					showClose
					closeModal={() => setShowConfirmUpdate(false)}
					deleteModal={() => {
						setShowConfirmUpdate(false);
						updateGeotabPairing();
					}}
				/>
			)}
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(GeotabCredentialsContainer);
