import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'

import ManageAssets from '../../dump/menuComponents/ManageAssets'
import FleetConfigurationContainer from '../../smart/fleetConfiguration/FleetConfigurationContainer'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';
import CameraHealth from '../../../pages/vehicles/CameraHealth'

import { isCustomUserPermissions } from '../../helpers/helperUsers'
import { isInternalUser } from '../../helpers/constants'
import { apiCallGet } from '../../../action/RouterActions'

const ManageVehicles = (props) => {
	const { user, company, hasUnacknowledgedAlerts, /*updateCameraHealthAlerts,*/ toggleLoader } = props
	const userRole = user.roles[0]
	const userPermissions = user.permissions

	const [tabKey, setTabKey] = useState(_.get(props, 'location.state.tabKey') || 1);
	const [selectedProfileName, setSelectedProfileName] = useState(_.get(props, 'location.state.profile_name') || '');
	const [device_id, setDevice_id] = useState(_.get(props, 'location.state.device_id') || null);
	const [passedCompany, setPassedCompany] = useState(_.get(props, 'location.state.company') || null);
	const [atGlanceSortName, setAtGlanceSortName] = useState('vehicle_name')

	// useEffect(() => {
	// 	if (company && company.company_id) {
	// 		getCameraHealthAlerts()
	// 	}
	// }, [company.company_id])

	// const getCameraHealthAlerts = () => {
	// 	const tmpCameraHealthAlerts = [
	// 		{ device_id: 3868616673, vehicle_name: 'Sergey DV6', group_name: "DV6" },
	// 		{ device_id: 68421682, vehicle_name: 'Brian_Office3_DV4', group_name: "API Test Group" },
	// 	]
	// 	// updateCameraHealthAlerts(tmpCameraHealthAlerts)
	// 	// TODO: implementation of the API request once the FWSD-6455 done 
	// 	// toggleLoader(true);
	// 	// apiCallGet('/devices/health_alerts', params)
	// 	// 	.then(res => {
	// 	// 		toggleLoader(false);
	// 	// 		updateCameraHealthDevices(res.data.response.alerts)
	// 	// 	})
	// 	// 	.catch(err => {
	// 	// 		toggleLoader(false);
	// 	// 		console.log('!getHealthAlerts error: ', err.response)
	// 	// 		toastr.error('Unable to get alerts. Please try again later');
	// 	// 	})
	// }

	const selectProfile = (e) => {
		setTabKey(2)
		setSelectedProfileName(e.target.name)
		setDevice_id('all')
	}

	const clearSelectedProfile = () => {
		setSelectedProfileName(null)
	}

	const canViewProfiles = ['system_admin', 'customer_service', 'partner', 'fleet_manager', 'sales_demo', 'storage_manager', 'custom_user', 'custom_partner'].includes(user.roles[0]);
	// || (user.roles[0] === 'custom_user' && _.find(user.permissions, { perm_category_id: 3, a_view: true }));
	const bubbleShow = tabKey !== 3 && hasUnacknowledgedAlerts
	const headerOptions = {
		title: 'Vehicles'
	}

	if (canViewProfiles) {
		headerOptions.renderSubtitle = (
			<nav className="page-subtitle">
				{isCustomUserPermissions(userRole, userPermissions, 'settings_profile_disabledBtn') &&
					<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => setTabKey(1)}>
						At a Glance
					</CustomButton>}
				{isCustomUserPermissions(userRole, userPermissions, 'settings_profile_view') && // FWSD-5071
					<CustomButton
						variant='subtitle'
						active={tabKey === 2}
						onClick={() => {
							setTabKey(2)
							setDevice_id(null)
						}}
					>
						Settings
					</CustomButton>
				}
				{/* FWSD-7019 */}
				{/* <CustomButton
					variant='subtitle'
					active={tabKey === 3}
					onClick={() => {
						setTabKey(3);
						setDevice_id(null);
					}}
				>
					Camera Health
					{bubbleShow && <label className="bubble-icon" />}
				</CustomButton> */}
			</nav>
		)
	}

	return (
		<>
			<GreyHeader {...headerOptions} />

			{tabKey === 1 &&
				<ManageAssets
					selectProfile={selectProfile}
					goToCameraHealth={() => {setAtGlanceSortName('group_name'); setTabKey(3);}}
				/>
			}
			{tabKey === 2 &&
				<FleetConfigurationContainer
					goToManageVehicles={() => setTabKey(1)}
					selectedProfileName={selectedProfileName}
					clearSelectedProfile={clearSelectedProfile}
					device_id={device_id}
					passedCompany={passedCompany}
				/>
			}
			{tabKey === 3 &&
				<CameraHealth
					atGlanceSortName={atGlanceSortName}
				/>
			}
		</>
	);
}

ManageVehicles.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	hasUnacknowledgedAlerts: PropTypes.bool.isRequired,
};

// export default connect(
// 	state => ({
// 		user: state.user.user,
// 		company: state.company.company,
// 	}),
// )(ManageVehicles);


const mapStateToProps = ({ user, company, assets }) => ({
	user: user.user,
	company: company.company,
	hasUnacknowledgedAlerts: assets.hasUnacknowledgedAlerts,
})

const mapDispatchToProps = dispatch => ({
	// updateCameraHealthAlerts: (devices) => {
	// 	dispatch({ type: 'SET_CAMERA_HEALTH_ALERTS', payload: devices });
	// },
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageVehicles)
