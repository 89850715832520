import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Toggle from 'react-toggle';
import { updatePartner, deletePartner, disablePartner, getCompanyById } from '../../../action/CompaniesActions';
import '../../dump/menuComponents/menuComponents.sass'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const PartnerEditContainer = (props) => {
    const { location, user, toggleLoader } = props;
    const userRole = user.roles[0];

    const [data, setData] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_address2: '',
        company_country: '',
        company_phone_number: '',
        active: '',
        allow_create_company: false,
        allow_edit_company: false,
    })
    const [company, setCompany] = useState(null)
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false
    })
    const [errMessage, setErrMessage] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: '',
        active: '',
    })

    // console.log('STATE PartnerEditContainer data: ', data)

    useEffect(() => {
        if (location.state.company) {
            getPartner(location.state.company.id);
        }
    }, [location.state])

    const getPartner = (partner_id) => {
        if (location) {
            toggleLoader(true);
            getCompanyById(userRole, partner_id)
                .then((res, err) => {
                    if (res.status == 200) {
                        toggleLoader(false);
                        let secondObject = {};
                        for (var k in res.data.response.company)
                            secondObject[k] = res.data.response.company[k];
                        let newElement = res.data.response.company;
                        setCompany(secondObject);
                        setData(newElement);

                    }
                    if (err) {
                        toggleLoader(false);
                        console.log(err);
                    }
                })
                .catch(error => {
                    toggleLoader(false);
                    console.log(error.response);
                });
        }
    }

    const cancel = () => {
        browserHistory.push('/partner');
    }

    const sendForm = (e) => {
        e.preventDefault();
    }

    const onSaveClick = (e) => {
        const requestData = {
            company: company,
            data: data
        }

        const error = checkForm(data);
        if (!error) {
            toggleLoader(true);
            updatePartner(requestData, userRole)
                .then(res => {
                    if (company.active !== data.active) {
                        const active = data.active ? 'enable' : 'disable';

                        disablePartner(userRole, data, active)
                            .then(res => {
                                toggleLoader(false);
                            })
                            .catch((error) => {
                                toggleLoader(false);
                                toastr.error('Unable to disable partner. Please try again later.');
                            });
                    }
                    else {
                        toggleLoader(false);
                        toastr.success("", "Partner was changed");
                    }
                })
                .catch((error) => {
                    toggleLoader(false);
                    let errDescription = 'An unexpected error occurred. Please try again later'
                    if (error.response.data.response.error) {
                        errDescription = error.response.data.response.error
                    }
                    toastr.error(errDescription)
                });
        }
    }

    const checkForm = (data) => {
        let error = false;
        let updatedErrMessage = { ...errMessage };
        for (let i of ["company_name", "company_city", "company_state", "company_zipcode", "company_address", "company_country"]) {
            if (data[i] == '') {
                error = true;
                updatedErrMessage[i] = 'Please enter a ' + i.replace('company_', 'partner ') + '!';
            }
            else if (updatedErrMessage[i]) {
                updatedErrMessage[i] = null;
            }
        }
        setErrMessage(updatedErrMessage);
        return error;
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    }

    const deletePartnerConfirm = () => {
        setModal({
            ...modal,
            show: true,
            content: `Are you sure you want to delete the partner: ${location.state.company.company_name}?`
        });
    }

    const closeModal = () => {
        setModal({
            ...modal,
            error: false,
            show: false
        });
    }

    const deletePartnerModal = () => {
        const data = {
            partner_name: company.company_name,
            user_role: userRole
        }

        deletePartner(data)
            .then(res => {
                setModal({ ...modal, show: false });
                toastr.success("", "Partner deleted successfully");
                props.delPartner(data.partner_name);
                browserHistory.push('/partner');
            })
            .catch((error) => {
                setModal({ ...modal, show: false });

                toastr.error('An unexpected error occurred. Please try again later');
            });
    }

    const { company_name, company_city, company_state, company_zipcode, company_address, company_country } = errMessage;

    if (!company) {
        return (
            <GreyHeader title="Edit Partner" hideSubtitle />
        )
    }

    return (
        <>
            <GreyHeader title="Edit Partner" subtitle={data.company_name} />

            <div className='page-subheader'>
                <div className={classnames('subheader-section search-flex', { 'has-error': company_name })}>
                    <label className="search-label">Name:</label>
                    <input type="text" name="company_name" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_name} />
                    {company_name && <span className="help-block">{company_name}</span>}
                </div>
                <div className='subheader-section'>
                    <CustomButton variant="secondary" onClick={onSaveClick}>SAVE</CustomButton>
                    <CustomButton variant="secondary-outline" onClick={cancel}>BACK</CustomButton>
                    <CustomButton variant="delete" onClick={deletePartnerConfirm}>DELETE</CustomButton>
                </div>
            </div>
            <main className="main-content-block">
                <section>
                    <h3 className='category-title'>Partner Info</h3>
                    <form onSubmit={sendForm}>
                        <div className='two-column-grid with-margin'>
                            <div className={classnames({ 'has-error': company_city })}>
                                <label className="control-label">City:</label>
                                <input type="text" name="company_city" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_city} maxLength={50} />
                                {company_city && <span className="help-block">{company_city}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_state })}>
                                <label className="control-label">State:</label>
                                <input type="text" name="company_state" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_state} />
                                {company_state && <span className="help-block">{company_state}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_zipcode })}>
                                <label className="control-label">Zip Code:</label>
                                <input type="text" name="company_zipcode" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_zipcode} />
                                {company_zipcode && <span className="help-block">{company_zipcode}</span>}
                            </div>
                            <div className={classnames({ 'has-error': company_address })}>
                                <label className="control-label">Address:</label>
                                <input type="text" name="company_address" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_address} />
                                {company_address && <span className="help-block">{company_address}</span>}
                            </div>

                            <div className='optional'>
                                <label className="control-label">Address 2: <span>(optional)</span></label>
                                <input type="text" name="company_address2" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_address2} />
                            </div>

                            <div className={classnames({ 'has-error': company_country })}>
                                <label className="control-label">Country:</label>
                                <input type="text" name="company_country" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_country} />
                                {company_country && <span className="help-block">{company_country}</span>}
                            </div>

                            <div className='optional'>
                                <label className="control-label">Phone Number: <span>(optional)</span></label>
                                <input type="text" name="company_phone_number" className="vehicle-search" onChange={(e) => { handleInputChange(e) }} value={data.company_phone_number} />
                            </div>

                            <div>
                                <div>
                                    <label htmlFor="active" className="control-label">Enable or disable partner:</label>
                                </div>
                                <label>
                                    <span className="toggle-left-title">Disable</span>
                                    <Toggle
                                        name="active"
                                        checked={data.active ? data.active : false}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    <span>Enable</span>
                                </label>
                            </div>
                        </div>
                    </form>
                </section>
                <section>
                    <h3 className="category-title">Admin Options</h3>
                    {(userRole === 'system_admin') &&
                        (<div className="two-column-grid with-margin" style={{ paddingBottom: '30px' }}>
                            <div>
                                <div>
                                    <label htmlFor="active" className="control-label">Allow create new company:</label>
                                </div>
                                <label>
                                    <span className="toggle-left-title">Disable</span>
                                    <Toggle
                                        name="allow_create_company"
                                        checked={data.allow_create_company ? data.allow_create_company : false}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    <span>Enable</span>
                                </label>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="active" className="control-label">Allow edit companies:</label>
                                </div>
                                <label>
                                    <span className="toggle-left-title">Disable</span>
                                    <Toggle
                                        name="allow_edit_company"
                                        checked={data.allow_edit_company ? data.allow_edit_company : false}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    <span>Enable</span>
                                </label>
                            </div>
                        </div>)}
                    <div className="two-column-grid with-margin">
                        <CustomButton
                            variant="primary-outline"
                            id="sm"
                            onClick={() => browserHistory.push({ pathname: '/partner/whitelabel', state: { partnerId: company.id, partnerName: company.company_name } })}
                        >
                            Whitelabel Settings
                        </CustomButton>
                    </div>
                </section>
            </main>

            <Modal
                show={modal.show}
                onHide={closeModal}
                dialogClassName='has-error'
                className="modal-lg-size">
                <Modal.Header closeButton>
                    <Modal.Title className='delete-title'>Delete Partner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center modal-text'>{modal.content}</div>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="delete" onClick={deletePartnerModal}>DELETE</CustomButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default connect(
    state => ({
        user: state.user.user
    }),
    dispatch => ({
        delPartner: (partner) => {
            dispatch({ type: 'DEL_PARTNER_SUCCESS', payload: partner });
        },
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(PartnerEditContainer);
