import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import momenttz from '../../helpers/momenttz'
import DateTime from 'react-datetime'
import Autocomplete from 'react-autocomplete'
import classnames from 'classnames'
import { connect } from 'react-redux'
import $ from 'jquery'

import { apiCallGet } from '../../../action/RouterActions'
import { requestEventVideo } from '../../../action/AlertActions'
import CustomButton from '../../CustomButton';

const EventVideoRequestModal = (props) => {

	const { device, autoValue, devices, onHide, user, company, deviceSelection, onSave, toggleLoader } = props;

	const visRef = useRef(null);
	const CVRref = useRef(null);

	const [videos, setVideos] = useState(
		[{
			device_id: '',
			length: 30,
			timestamp: moment().seconds(0),
			description: '',
			autoValue: '',
			errMessage: '',
			errTimeMessage: ''
		}]
	);

	const [available_video_dates, setAvailable_video_dates] = useState([]);
	const [dataVisualizer, setDataVisualizer] = useState([]);
	const [dateLabel, setDateLabel] = useState('');
	const [timeLabel, setTimeLabel] = useState('');
	const [showVisualizer, setShowVisualizer] = useState(false);
	const [value_start_date, setValue_start_date] = useState('');
	const [value_end_date, setValue_end_date] = useState('');
	const [currentState, setCurrentState] = useState('');
	const [videoLength, setVideoLength] = useState('');
	const [showMessage, setShowMessage] = useState(false);
	const [isOpenDateTime, setIsOpenDateTime] = useState(false);
	const [zoom, setZoom] = useState(window.devicePixelRatio);

	// onEnterModal = () => {
	useEffect(() => {
		console.log('onEnterModal props: ', props);

		let paramDeviceId = '';
		let paramAutoValue = '';

		if (device.device_id) {
			paramDeviceId = device.device_id;
			paramAutoValue = device.vehicle_name || device.device_id;
			apiCallGet(`/devices/${paramDeviceId}/availablevideo/dates?timezone=${timezone()}`)
				.then(res => setAvailable_video_dates(res.data.response.available_video_dates))
		} else if (devices.length) {
			//* Selects device according to the Search Vehicle value
			if (autoValue.length > 0) {
				paramAutoValue = autoValue;
				devices.forEach((elem) => {
					const name = elem.vehicle_name || elem.device_id;
					if (name.toLowerCase() === paramAutoValue.toLowerCase()) {
						paramDeviceId = elem.device_id;
					}
				});
			}
			// ***********************
		}

		setVideos([{
			device_id: paramDeviceId, length: 30, timestamp: moment().seconds(0), description: '', autoValue: paramAutoValue, errMessage: '', errTimeMessage: '',
		}]);
	}, []);

	useEffect(() => {
		const start_date = moment(value_start_date, 'hh:mm:ss a');

		if (visRef.current) {
			visRef.current.scrollTo({ left: ((currentMinute(start_date) + (+start_date.format('ss') / 60)) * (120 / (videos[0].length / 60))) });
			setStartDate(value_start_date, 0, 'instant');
		}
	}, [videos[0].length]);

	useEffect(() => {
		const curMin = dataVisualizer.findIndex(elm => elm !== '0');
		const curMinute = curMin !== -1 ? curMin : 0;

		if (visRef.current) visRef.current.scrollTo({ left: curMinute * (120 / (videos[0].length / 60)) });
	}, [dateLabel]);

	const onCloseModal = () => {
		setVideos([{
			device_id: '', length: 30, timestamp: moment().seconds(0), description: '', autoValue: '', errMessage: '', errTimeMessage: '',
		}]);
		onHide();
	}

	$(document).mouseup((e) => { if ($(".rdtPicker").has(e.target).length === 0) setIsOpenDateTime(false) });

	const fillForm = idx => async (e) => {
		const { target } = e;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		const newVideos = videos.map((video, sidx) => {
			if (idx !== sidx) return video;
			video[name] = value;
			return video;
		});

		const start_date = moment(value_start_date, 'hh:mm:ss a')
		const end_date = moment(value_start_date, 'hh:mm:ss a').add(value, 'seconds').format('hh:mm:ss a')

		setVideos(newVideos);
		setValue_end_date(end_date);
		setVideoLength(value);
	}

	const checkForm = () => {
		let error = false;

		const newVideos = videos.map((videoitem) => {
			let varItem = videoitem;

			if (!videoitem.device_id) {
				error = true;
				varItem.errMessage = 'Select vehicle!';
			}

			if (!videoitem.timestamp) {
				error = true;
				varItem.errTimeMessage = 'This field must be fill!';
			}
			else if (!moment(videoitem.timestamp, 'MM/DD/YYYY hh:mm:ss A', true).isValid()) {
				error = true;
				varItem.errTimeMessage = 'This field is not valid!';
			}

			if (!error) {
				varItem = { ...videoitem, errMessage: null, errTimeMessage: null };
			}

			return varItem;
		})

		setVideos(newVideos);

		return error;
	}

	const defaultDate = async () => {
		let current = moment(available_video_dates[available_video_dates.length - 1]).format("LLLL");

		await apiCallGet(`/devices/${videos[0].device_id}/availablevideo?start_time=${momenttz(moment(current).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss')}&end_time=${momenttz(moment(current).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss')}`)
			.then(res => {
				if (res.data.response.length) {
					setDataVisualizer(((!!res.data.response[0] ? res.data.response[0].available_video : "") + (!!res.data.response[1] ? res.data.response[1].available_video : "")).split(''))
					setShowVisualizer(true)
					setDateLabel(current)
					setTimeLabel(true)

					const newVideos = videos.map((video, sidx) => {
						if (0 !== sidx) {
							return video
						}
						return { ...video, timestamp: moment(current) }
					});

					const cur = dataVisualizer.findIndex(elm => elm !== '0');
					const curMinute = cur !== -1 ? cur : 0;
					setVideos(newVideos)
					setValue_start_date(moment(current).add(curMinute, 'minute').format('hh:mm:ss a'))
					setValue_end_date(moment(current).add(curMinute, 'minute').add(videos[0].length, 'seconds').format('hh:mm:ss a'))
					setVideoLength(newVideos[0].length)
				} else {
					setDateLabel(current)
					setShowMessage(true)
					setShowVisualizer(false)
					setDataVisualizer([])
					setValue_start_date("")
					setValue_end_date("")
					setCurrentState("")
					setVideoLength(videos[0].length)
				}
			});
	}

	const onDateChanged = (idx, select = 0) => (current) => {
		setTimeLabel('');

		if (videos[0].device_id) {
			if (available_video_dates.includes(moment((select !== 0) ? moment(dateLabel) : current).format('YYYY-MM-DD'))) {
				if ((select === 0) ? moment(current).format('YYYYMMDD') !== moment(dateLabel).format('YYYYMMDD') : true) {
					if (select !== 0) current = moment(dateLabel);
					available_video_dates.map(async (elm, index) => {
						if (moment(elm).format('MM-DD-YYYY') === moment(current).format('MM-DD-YYYY')) {
							if ((index + select) < 0) {
								setTimeLabel(true);
								toastr.info('', 'No previous events are available.');
							}
							if ((index + select) > (available_video_dates.length - 1)) {
								setTimeLabel(true);
								toastr.info('', 'No future events are available.');
							}
							if ((0 <= (index + select)) && ((index + select) <= (available_video_dates.length - 1))) {
								const cur = moment(available_video_dates[(index + select)]).format("LLLL");
								await apiCallGet(`/devices/${videos[0].device_id}/availablevideo?start_time=${momenttz(moment(cur).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss')}&end_time=${momenttz(moment(cur).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss')}`)
									.then(res => {
										if (res.data.response.length) {
											const response = ((!!res.data.response[0] ? res.data.response[0].available_video : "") + (!!res.data.response[1] ? res.data.response[1].available_video : "")).split('');
											setDataVisualizer(response)
											const curMin = response.findIndex(elm => elm !== '0');
											const curMinute = curMin !== -1 ? curMin : 0;
											setShowVisualizer(true)
											setShowMessage(false)
											setDateLabel(cur)
											setTimeLabel(true)
											setValue_start_date(moment(cur).add(curMinute, 'minute').format('hh:mm:ss a'))
											setValue_end_date(moment(cur).add(curMinute, 'minute').add(videos[0].length, 'seconds').format('hh:mm:ss a'))
											setVideoLength(videos[0].length)
										} else {
											setDateLabel(cur)
											setShowMessage(true)
											setShowVisualizer(false)
											setTimeLabel(false)
											setDataVisualizer([])
											setValue_start_date("")
											setValue_end_date("")
											setCurrentState("")
											setVideoLength(videos[0].length)
										}
									});
							}
						}
					});

					const newVideos = videos.map((video, sidx) => {
						if (idx !== sidx) {
							return video
						}
						return { ...video, timestamp: current }
					});
					setVideos(newVideos);
				} else {
					showVisualizer ? setTimeLabel(true) : setTimeLabel(false);
				}
			}
			else {
				setDateLabel(moment(current).format("LLLL"))
				setShowMessage(true)
				setShowVisualizer(false)
				setDataVisualizer([])
				setValue_start_date('')
				setValue_end_date('')
				setCurrentState('')
				setVideoLength(videos[0].length)
			}
		}

		setIsOpenDateTime(false);
	}

	const onTimeChanged = (current) => {
		setTimeLabel(moment(current))
		setValue_start_date(moment(current).format('hh:mm:ss a'))
		setValue_end_date(moment(current).add(videos[0].length, "seconds").format('hh:mm:ss a'))
	}

	const sendRequest = (e) => {
		const error = checkForm();
		const requestArray = [];
		const currentData = moment(dateLabel).format('YYYYMMDD');
		const start_date = moment(value_start_date, 'HH:mm:ss a').format('HHmmss');
		const end_date = moment(value_end_date, 'HH:mm:ss a').format('HHmmss');
		videos[0]["start_ts"] = momenttz(moment((currentData + start_date), 'YYYYMMDDHHmmss')).utc().format('YYYYMMDDHHmmss');
		videos[0]["end_ts"] = momenttz(moment((currentData + end_date), 'YYYYMMDDHHmmss')).utc().format('YYYYMMDDHHmmss');

		if (!error) {
			toggleLoader(true);

			videos.forEach((item) => {
				const timestamp = item.timestamp.format('YYYY-MM-DD HH:mm:ss')
				requestArray.push(requestEventVideo({ ...item, timestamp }))
			});

			Promise.all(requestArray)
				.then(
					(res) => {
						toggleLoader(false);
						console.log('sendRequest PromiseAll res: ', res);

						let eventId = [];
						if (res[0].data.response.request_id) {
							eventId = res[0].data.response.request_id
						} else if (res[0].data.response.saved_files_ids) {
							eventId = res[0].data.response.saved_files_ids
						}

						onSave(
							{
								event_ts: moment(dateLabel),
								event_device_id: videos[0].device_id,
								event_id: eventId,
							},
							false,
							videos[0].length
						);
						toastr.success('', 'Custom Video Requested');
						onHide();
					},
					(error) => {
						toggleLoader(false);
						console.log('sendRequest PromiseAll ERROR: ', error);

						let errDescription = 'An unexpected error occurred. Please try again later'
						if (error.response.data.response.error) {
							errDescription = error.response.data.response.error
						}
						toastr.error(errDescription)
					},
				)
				.catch((error) => {
					toggleLoader(false);
					console.log('sendRequest PromiseAll catch ERROR: ', error);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const addVideos = () => {
		const arr = {
			device_id: device ? device.device_id : '',
			length: 60,
			timestamp: moment().seconds(0),
			description: '',
		}

		setVideos([...videos, arr]);
	}

	const removeVideos = (idx) => {
		console.log('removeVideos', idx, videos);

		setVideos(videos.filter((s, sidx) => idx !== sidx));
	}

	const validDate = (current) => {
		return current.isSameOrBefore(moment())
	}

	// const isValid = (dateString, format) => {
	// 	if (moment(dateString, format).isBetween(moment(moment(dateLabel).format(format), format), moment(moment(dateLabel).add(dataVisualizer.length, 'minutes').add(1, "seconds").format(format), format))) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	const currentMinute = (date) => {
		const h = +moment(date, 'hh:mm:ss').format("hh");
		const m = +moment(date, 'hh:mm:ss').format("mm");
		date = date.format("a") === "am" ? (h === 12 ? m : (h * 60 + m)) : (h === 12 ? (12 * 60 + m) : ((h + 12) * 60 + m));

		return date;
	}

	const changeVisualizer = (current, videoLength) => {
		let width = dataVisualizer.length * (120 / (videoLength / 60));
		let allTime = dataVisualizer.length * 60;
		let time = moment(moment(dateLabel).format('hh:mm:ss a'), 'hh:mm:ss a').unix() + current * allTime / width;

		const start_date = moment(value_start_date, 'hh:mm:ss a');
		const end_date = moment(value_end_date, 'hh:mm:ss a');

		let currentMinuteStart = currentMinute(start_date);
		let currentMinuteEnd = currentMinute(end_date.subtract(1, 'seconds'));
		const sliceStart = dataVisualizer.slice((videoLength / 60) < 1 ? currentMinuteStart - 1 : currentMinuteStart, currentMinuteEnd);
		const sliceEnd = dataVisualizer.slice(currentMinuteStart, (videoLength / 60) < 1 ? currentMinuteEnd + 2 : currentMinuteEnd + 1);
		let cur = ((videoLength / 60) < 1) ? ((+start_date.format('ss') + 30 < 60) ? 0 : 1) : (videoLength / 60);
		const valueTransitionStart = sliceEnd.indexOf("3") !== -1 ? currentMinuteStart + sliceStart.indexOf("3") + 1 : false;
		const valueTransitionEnd = sliceEnd.indexOf("3") !== -1 ? currentMinuteStart + sliceEnd.indexOf("3") + 1 : false;

		if (zoom === window.devicePixelRatio) {
			setValue_start_date(moment(time * 1000).format('hh:mm:ss a'));
			setValue_end_date(moment(time * 1000).add(videoLength, 'seconds').format('hh:mm:ss a'));
		}

		setZoom(window.devicePixelRatio);
		setCurrentState(current);
		setVideoLength(videoLength);

		const slice3Start = () => {
			let slice3Start = 0;
			if (valueTransitionStart) {
				const arr = dataVisualizer.slice(0, currentMinuteStart + 1).reverse();
				for (let i of arr) {
					if (i === "3") {
						slice3Start += 1;
					} else {
						break;
					}
				}
			}
			return slice3Start;
		}

		const slice3End = () => {
			let slice3End = 0;
			if (valueTransitionEnd) {
				const arr = dataVisualizer.slice(currentMinuteEnd);
				for (let i of arr) {
					if (i === "3") {
						slice3End += 1;
					} else {
						break;
					}
				}
			}
			return slice3End;
		}

		if (slice3Start() > 1 || slice3End() > 1) {
			if ((valueTransitionStart && slice3Start() > 1) && dataVisualizer[currentMinuteEnd] !== "3") visRef.current.scrollTo({ left: (currentMinuteStart - cur + ((videoLength / 60 < 1) ? 1.5 : 1) - slice3Start()) * (120 / (videoLength / 60)), behavior: "instant" });
			if ((valueTransitionEnd && slice3End() > 1) && dataVisualizer[currentMinuteStart] !== "3") visRef.current.scrollTo({ left: (currentMinuteEnd + slice3End()) * (120 / (videoLength / 60)), behavior: "instant" });
		} else {
			if (dataVisualizer[currentMinuteStart] === "3" && dataVisualizer[currentMinuteEnd] !== "3") visRef.current.scrollTo({ left: (currentMinuteStart - cur + ((videoLength / 60 < 1) ? 0.5 : 0)) * (120 / (videoLength / 60)), behavior: "instant" });
			if (dataVisualizer[currentMinuteEnd] === "3" && dataVisualizer[currentMinuteStart] !== "3") visRef.current.scrollTo({ left: (currentMinuteEnd + 1) * (120 / (videoLength / 60)), behavior: "instant" });
			if (cur > 2) {
				if (valueTransitionStart && dataVisualizer[currentMinuteStart] !== "3" && dataVisualizer[currentMinuteEnd] !== "3") visRef.current.scrollTo({ left: (valueTransitionStart - cur) * (120 / (videoLength / 60)), behavior: "instant" });
				if (valueTransitionEnd && dataVisualizer[currentMinuteStart] !== "3" && dataVisualizer[currentMinuteEnd] !== "3") visRef.current.scrollTo({ left: (valueTransitionEnd) * (120 / (videoLength / 60)), behavior: "instant" });
			}
		}
	}

	const setStartDate = (value, timeout = 0, behavior = 'smooth') => {
		let width = dataVisualizer.length * 120 / (videoLength / 60);
		let date = dataVisualizer.length * 60;
		let current = moment(value, 'hh:mm:ss a').unix() - moment(moment(dateLabel).format('hh:mm:ss a'), 'hh:mm:ss a').unix();
		current = current * width / date;
		if (moment(value, 'hh:mm:ss a').isValid()) {
			let end_date = moment(value, 'hh:mm:ss a').add(videoLength, 'seconds').format('hh:mm:ss a');
			setValue_start_date(value);
			setTimeout(() => {
				if (moment(end_date, 'hh:mm:ss a').isValid()) {
					visRef.current.scrollTo({ left: current, behavior: behavior });
					changeVisualizer(current, videoLength);
					setValue_end_date(end_date);
				}
			}, timeout)
		}
	}

	const setEndDate = (value, timeout = 0, behavior = 'smooth') => {
		let width = dataVisualizer.length * 120 / (videoLength / 60);
		let date = dataVisualizer.length * 60;
		let current = moment(value, 'hh:mm:ss a').unix() - moment(moment(dateLabel).format('hh:mm:ss a'), 'hh:mm:ss a').unix() - videoLength;
		current = current * width / date;
		if (moment(value, 'hh:mm:ss a').isValid()) {
			let start_date = moment(value, 'hh:mm:ss a').subtract(videoLength, 'seconds').format('hh:mm:ss a');
			setValue_end_date(value);
			setTimeout(() => {
				if (moment(start_date, 'hh:mm:ss a').isValid()) {
					visRef.current.scrollTo({ left: current, behavior: behavior });
					changeVisualizer(current, videoLength);
					setValue_start_date(start_date);
				}
			}, timeout)
		}
	}

	const checkCurrentValue = (currentMinuteStart, currentMinuteEnd, elements) => {
		let slice_start, slice_end;

		const arrStartDate = dataVisualizer.slice(currentMinuteStart, (currentMinuteStart + elements))
		if (arrStartDate.every(elm => elm === "1") && arrStartDate.length === elements) {
			slice_start = 1
		}
		if (arrStartDate.every(elm => elm === "2") && arrStartDate.length === elements) {
			slice_start = 2
		}

		const arrEndDate = dataVisualizer.slice(currentMinuteEnd - elements + 1, currentMinuteEnd + 1);
		if (arrEndDate.every(elm => elm === "1") && arrEndDate.length === elements) slice_end = 1;
		if (arrEndDate.every(elm => elm === "2") && arrEndDate.length === elements) slice_end = 2;

		return (!!slice_start && !!slice_end && slice_start === slice_end) ? true : false;
	}

	const getVideoRequest = () => {
		// FWSD-7106, Remove all validation from Custom Video Request
		// if (available_video_dates.includes(moment(dateLabel).format("YYYY-MM-DD"))) {
		// 	let elements = (+videoLength === 30) ? 1 : videoLength / 60;
		// 	const start_date = moment(value_start_date, 'hh:mm:ss a');
		// 	const end_date = moment(value_end_date, 'hh:mm:ss a').subtract(1, 'second');

		// 	if (checkCurrentValue(currentMinute(start_date), currentMinute(end_date), elements)) {
		// 		sendRequest();
		// 	} else {
		// 		!!dataVisualizer.length ? toastr.error('Incorrect timing of Custom Video Request!') : sendRequest();
		// 	}
		// } else {
		// 	sendRequest();
		// }

		sendRequest()
	}


	const userRole = user.roles[0]
	const partnerName = company.partner || user.partner_company_name
	const companyType = company.type || user.company_type

	let displayTimeFormat = 'MM/DD/YYYY hh:mm:ss A';
	const optsRequest = {}
	if (userRole === 'sales_demo') {
		optsRequest.disabled = 'disabled'
	}

	if (partnerName === 'Rosco Vision WCS' || companyType === 'WCS') {
		displayTimeFormat = 'MM/DD/YYYY hh:mm A'
	}

	const displayItemName = item => item.vehicle_name || item.device_id

	const getItemValue = item => item.vehicle_name || item.device_id

	const matchStateToTerm = (item, value) => {
		if (item.vehicle_name) {
			return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
		}

		return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	}

	const validDates = (date) => {
		return (moment(date).format("YYYY-MM-DD"));
	}

	const timezone = () => {
		const tz = momenttz().utcOffset();
		const timezone = 'UTC' + ((tz > 0) ? '+' : '') + tz / 60;

		return timezone;
	}

	const selectDate = () => {
		if (available_video_dates.length && videos[0].device_id) {
			if (!!dateLabel) {
				return moment(dateLabel).format("MM-DD-YYYY")
			} else {
				defaultDate();
				return moment(available_video_dates[available_video_dates.length - 1]).format("MM-DD-YYYY")
			}
		} else if (!!dateLabel) {
			return moment(dateLabel).format("MM-DD-YYYY")
		} else {
			return "Select a date"
		}
	}

	const selectTime = () => {
		let timeDate = timeLabel;
		if (timeDate === true) timeDate = "";
		if (!!timeDate) {
			return timeDate.format('hh:mm:ss a');
		} else {
			return "Select a time";
		}
	}

	return (
		<Modal
			show
			onHide={onCloseModal}
			dialogClassName={classnames('custom-request-modal ', { 'modal-lg': (videos.length > 1) })}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg" className='action-title' >
					Custom Video Request
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{videos.map((videoitem, index) => (
						<div key={index}>
							{deviceSelection && (
								<div className={classnames('col-xs-12 col-sm-12 col-md-12 form-group vehicle-group rowFlex ', { 'has-error': videoitem.errMessage })}>
									<Autocomplete
										autoHighlight
										value={videoitem.autoValue}
										inputProps={{ id: 'states-autocomplete' }}
										wrapperProps={{ className: 'search-autocomplete' }}
										wrapperStyle={{ position: 'relative' }}
										getItemValue={getItemValue}
										shouldItemRender={matchStateToTerm}
										menuStyle={{
											borderRadius: '3px',
											boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
											background: 'rgba(255, 255, 255, 0.9)',
											padding: '2px 0',
											fontSize: '90%',
											position: 'absolute',
											overflow: 'auto',
											maxHeight: '200px',
											left: '0',
											top: 'auto',
											zIndex: 1,
										}}
										onChange={(e, value) => {
											const newVideos = videos.map((video, sidx) => {
												if (index !== sidx) return video;
												return { ...video, device_id: '', autoValue: value };
											});
											setVideos(newVideos)
											setDateLabel('')
											setTimeLabel('')
											setShowMessage(false)
											setShowVisualizer(false)
											setAvailable_video_dates([])
											setValue_start_date('')
											setValue_end_date('')
										}}
										onSelect={(value, item) => {
											const newVideos = videos.map((video, sidx) => {
												if (index !== sidx) return video;
												return {
													...video, device_id: item.device_id, autoValue: value, errMessage: null,
												};
											});
											setVideos(newVideos);
											apiCallGet(`/devices/${item.device_id}/availablevideo/dates?timezone=${timezone()}`)
												.then(res => { setAvailable_video_dates(res.data.response.available_video_dates) });
										}}
										renderMenu={function (items, value, style) {
											return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
										}}
										renderItem={(item, isHighlighted) => (
											<option
												className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
												key={item.device_id}
											>
												{displayItemName(item)}
											</option>
										)}
										renderInput={props => (
											<div className="select-box">
												<label className="control-label text-label">Vehicle:</label>
												<input {...props} list="list" type="text" value={videoitem.autoValue} className="vehicle-search" />
											</div>
										)}
										items={devices}
									/>
									{videoitem.errMessage && <span className="help-block">{videoitem.errMessage}</span>}
								</div>
							)}

							<div className="col-md-12 vehicle-group form-group">
								<p>Select a date and duration of video you would like to retrieve</p>
							</div>

							<div className={classnames('col-md-12 col-md-12 col-md-12 form-group date-group rowFlex-date ', { 'has-error': videoitem.errTimeMessage })}>
								<div className="rowFlex">
									<DateTime
										value={selectDate()}
										timeFormat={false}
										ref={CVRref}
										className="request-timestamp"
										inputProps={{
											readOnly: true,
											onClick: () => {
												setIsOpenDateTime(true);
											}
										}}
										open={isOpenDateTime}
										isValidDate={validDate}
										onChange={onDateChanged(index)}
									/>
									{videoitem.errTimeMessage && <span className="help-block">{videoitem.errTimeMessage}</span>}
								</div>
								<div className="select-box">
									<label className="control-label text-label">Clip Duration:</label>
									<select placeholder="select" className="rosco-select" name="length" value={videoitem.length} onChange={fillForm(index)}>
										{partnerName !== 'Rosco Vision WCS' && companyType !== 'WCS'
											&& <option value="30">30 Seconds</option>}
										<option value="60">1 Minute</option>
										<option value="120">2 Minutes</option>
										<option value="180">3 Minutes</option>
										<option value="240">4 Minutes</option>
										<option value="300">5 Minutes</option>
										{/* {(partnerName === 'Rosco Vision WCS' || companyType === 'WCS')
											&& (
												<option value="600">10 Minutes</option>
											)}
										{(partnerName === 'Rosco Vision WCS' || companyType === 'WCS')
											&& (
												<option value="900">15 Minutes</option>
											)} */}
									</select>
								</div>
							</div>
							{showMessage && (
								<div className="col-md-12 col-md-12 col-md-12 form-group block-info columnFlex">
									<p>No video data found for the selected date. Select a time to make a request for. Please note that requesting video for a time where no video may exist may result in no video returned.</p>
									<div style={{ padding: '15px 0px' }} className={classnames('col-md-12 col-md-12 col-md-12 form-group date-group rowFlex-date ', { 'has-error': videoitem.errTimeMessage })}>
										<DateTime
											value={selectTime()}
											closeOnSelect
											className="request-timestamp"
											inputProps={{ readOnly: true }}
											onChange={onTimeChanged}
											timeFormat='hh:mm:ss a'
											dateFormat={false}
										/>
										{videoitem.errTimeMessage && <span className="help-block">{videoitem.errTimeMessage}</span>}
									</div>
								</div>
							)}
							{showVisualizer && videos[0].device_id && (
								<div>
									<div className="col-md-12 col-md-12 col-md-12 form-group block-info columnFlex">
										<p>Move the bar within the window to adjust the start and end time you wish to capture.</p>
										<div className="rowFlex"><div className="circle-green"></div><p>Video captured during driving.</p></div>
										<div className="rowFlex"><div className="circle-blue"></div><p>Video captured during parking mode.</p></div>
										<p className="textNote">Note: Requests cannot be made that overlap driving mode and parking mode.</p>
									</div>
									<div className="col-md-12 col-md-12 col-md-12 form-group columnFlex">
										<div className="visualizer" ref={visRef} onScroll={() => { changeVisualizer(visRef.current.scrollLeft, videoitem.length) }}>
											<div className="rectangle" />
											<div className="rowFlex-visualizer">
												<div className="box-before">
													<svg className="arrow-left" onClick={onDateChanged(0, -1)} width="32" height="32" viewBox="0 0 5 9">
														<path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z" />
													</svg>
												</div>
												{dataVisualizer.map((elm, index) => {
													const selectDuration = videoitem.length / 60;
													const widthBlock = 120 / selectDuration;
													let color = '';
													switch (elm) {
														case '0':
															color = 'white';
															break;
														case '1':
															color = '#16be16';
															break;
														case '2':
															color = '#2e318c';
															break;
														case '3':
															if (dataVisualizer[index - 1] === '1') color = '#16be16';
															if (dataVisualizer[index - 1] === '2') color = '#2e318c';
															break;
													}
													return (
														<div
															key={index}
															className={classnames(`box_${(index + 1)}`, (((index + 1) % selectDuration === 0) || ((index + 1) === dataVisualizer.length)) ? "box-vertical-line" : "")}
															style={{ width: `${widthBlock}px` }}
														>
															<div className="element" style={{ marginTop: "60px", borderTop: "2px solid black", borderBottom: "2px solid black", width: `${widthBlock}px`, height: "25px", background: color }} />
															<label style={{ position: "absolute", minWidth: "62px", display: (index === 0) ? "block" : "none", margin: "75px 0px 0px -50px" }}>
																{moment(dateLabel).format('hh:mm a')}
															</label>
															{(((index + 1) % selectDuration === 0) || ((index + 1) === dataVisualizer.length))
																? <label style={{ minWidth: "62px", margin: `75px 0px 0px ${((index + 1) === dataVisualizer.length) ? 118 - widthBlock * (selectDuration - 1) : 90 - widthBlock * (selectDuration - 1)}px` }}>
																	{moment(dateLabel).add((index + 1), 'minutes').format('hh:mm a')}
																</label>
																: ""
															}
														</div>
													)
												})}
												<div className="box-after">
													<svg className="arrow-right" onClick={onDateChanged(0, 1)} width="32" height="32" viewBox="0 0 5 9">
														<path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="rowFlex-inputs">
											<input className="start-date" value={value_start_date} onChange={(e) => { setStartDate(e.target.value.slice(0, 11), 3000, "instant") }} />
											<label>To</label>
											<input className="end-date" value={value_end_date} onChange={(e) => { setEndDate(e.target.value.slice(0, 11), 3000, "instant") }} />
										</div>
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			</Modal.Body>
			{/* <Modal.Footer className="default-modal-footer modal-footer"> */}
			<Modal.Footer>
				<CustomButton
					prefix="rosco-primary-cancel btn btn-default"
					onClick={() => onHide()}
				>
					Cancel
				</CustomButton>
				<CustomButton
					variant="primary"
					prefix="btn"
					onClick={() => getVideoRequest()}
					disabled={!dateLabel || !videos[0].device_id || !timeLabel}
					{...optsRequest}
				>
					Request Video
				</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}

EventVideoRequestModal.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	deviceSelection: PropTypes.bool,
	device: PropTypes.objectOf(PropTypes.any),
	autoValue: PropTypes.string,
	devices: PropTypes.arrayOf(PropTypes.any),
	onHide: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired
}

EventVideoRequestModal.defaultProps = {
	deviceSelection: true,
	device: {},
	autoValue: '',
	devices: []
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(EventVideoRequestModal);
