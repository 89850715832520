import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { ReactBootstrapSlider } from 'react-bootstrap-slider'
import _, { find } from 'lodash';
import { Link } from 'react-router';
import Toggle from 'react-toggle';
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';

import SelectionEventVideoType from './SelectionEventVideoType'
import InfoPop from '../../common/InfoPop'
import CustomButton from '../../CustomButton'
import CustomReactBootstrapSlider from '../../CustomReactBootstrapSlider'
import LabelTag from '../../dump/LabelTag'
import ModalDelete from '../modals/ModalDelete'

import { isInternalUser } from '../../helpers/constants'

const dv6ADASSettings = [
	{ name: 'Forward Collision', event_setting: 'ForwardCollision', event_type_name: 'dv6_forward_collision', description: 'If enabled, the camera will report Forward Collision events to RoscoLive when they occur. A Forward Collision event occurs when the camera detects a possible imminent front-end collision.' },
	{ name: 'Headway Monitoring', event_setting: 'Headway', event_type_name: 'dv6_headway_monitoring', description: 'If enabled, the camera will report Headway Monitoring events to RoscoLive when they occur. Headway Monitoring events are triggered when the driver begins following at an unsafe distance from the vehicle in front of them.' },
	{ name: 'Tailgating', event_setting: 'FollowingDistance', event_type_name: 'dv6_following_distance', description: 'If enabled, the camera will report Tailgating events to RoscoLive when they occur. Tailgating events are triggered when the driver continues to follow at an unsafe distance for a period of time.' },
]

const dv6DMSSettings = [
	{ name: 'Distraction', event_setting: 'Distraction', event_type_name: 'dv6_distraction', description: 'If enabled, the camera will report Distraction events to RoscoLive when they occur. A Distraction event occurs when the camera detects the driver’s gaze is not facing forward for an amount of time.', voice_prompt_setting: 'System.Chimes.Distraction' },
	{ name: 'Yawning', event_setting: 'Yawning', event_type_name: 'dv6_yawning', description: 'If enabled, the camera will report Yawning events to RoscoLive when they occur. A Yawning event occurs when the camera detects the driver’s mouth yawning frequently over a short period of time.', voice_prompt_setting: 'System.Chimes.Yawning' },
	{ name: 'Dozing', event_setting: 'Dozing', event_type_name: 'dv6_drowsiness', description: 'If enabled, the camera will report Dozing events to RoscoLive when they occur. A Dozing event occurs when the camera detects the driver’s eye lids closed for an extended period of time.', voice_prompt_setting: 'System.Chimes.Drowsiness' },
	{ name: 'PhoneUse', event_setting: 'PhoneUse', event_type_name: 'dv6_phone_use', description: 'If enabled, the camera will report Phone Use events to RoscoLive when they occur. A Phone Use event occurs when the camera detects the driver’s hand up to their head as if holding a phone to their ear for an extended period of time.', voice_prompt_setting: 'System.Chimes.PhoneUse' },
	{ name: 'Smoking', event_setting: 'Smoking', event_type_name: 'dv6_smoking', description: 'If enabled, the camera will upload Smoking events to Rosco Live when they occur. A Smoking event occurs when the camera detects a cigarette shaped object in their mouth for an extended period of time.', voice_prompt_setting: 'System.Chimes.Smoking' },
	// { name: 'Seatbelt', event_setting: 'Seatbelt', event_type_name: 'dv6_seatbelt', description: 'If enabled, the camera will upload Seatbelt Detection events to RoscoLive when they occur. A Seatbelt Detection event occurs when the camera cannot detect a properly secured seatbelt on the driver.', voice_prompt_setting: 'System.Chimes.Seatbelt', isInternal: true },
	{ name: 'Seatbelt', event_setting: 'Seatbelt', event_type_name: 'dv6_seatbelt', description: 'If enabled, the camera will upload Seatbelt Detection events to RoscoLive when they occur. A Seatbelt Detection event occurs when the camera cannot detect a properly secured seatbelt on the driver.', voice_prompt_setting: 'System.Chimes.Seatbelt' },
]

const dv6SensitivitySettings = [
	{ name: 'High G-Force', event_setting: 'Accelerometer', event_type_name: 'dv6_fatal_gsensor', description: 'If enabled, the camera will report High G-Force events to RoscoLive when they occur. A High G-Force event occurs when the amount of  G-force experienced by the vehicle exceeds the sensitivity setting.', sliderObj: {id: 1, min:1.0, max:4.0, step:0.1, name: "Event.Accelerometer.FatalThreshold.X"} },
	{ name: 'Harsh Braking', event_setting: 'HarshBraking', event_type_name: 'dv6_harsh_braking', description: 'If enabled, the camera will report Harsh Braking events to RoscoLive when they occur. A Harsh Braking event occurs when a vehicle de-accelerates faster than the set sensitivity setting.', sliderObj: {id:2, min:7, max:17, step:0.1, name: "Event.HarshBraking.Threshold"}, sublabel: 'Available only with DV665/DV672 CAN harness' },
	{ name: 'Harsh Acceleration', event_setting: 'HarshAccel', event_type_name: 'dv6_harsh_accel', description: 'If enabled, the camera will report Harsh Acceleration events to RoscoLive when they occur. A Harsh Acceleration event occurs when a vehicle accelerates faster than the set sensitivity setting.', sliderObj: {id:3, min:7, max:17, step:0.1, name: "Event.HarshAccel.Threshold"}, sublabel: 'Available only with DV665/DV672 CAN harness' },
]

const dv4ADASSettings = [
	{ name: 'Lane Departure Warning', event_setting: 'LaneDeparture', description: 'If enabled, the camera will report Lane Departure warning events to RoscoLive when they occur.' },
	{ name: 'Forward Collision Warning', event_setting: 'ForwardCollision', description: 'If enabled, the camera will report Forward Collision warning events to RoscoLive when they occur.' },
	{ name: 'Pedestrian Collision Warning', event_setting: 'Pedestrian', description: 'If enabled, the camera will upload Pedestrian Collision warning events to Rosco Live when they occur.' },
]

const dv4DMSSettings = [
	{ name: 'Distraction', event_setting: 'Distraction', event_setting: 'Distraction', event_type_name: 'dv4_distraction', description: 'If enabled, the camera will upload Distraction events to Rosco Live when they occur.' },
	{ name: 'Drowsiness/Looking Down', event_setting: 'Dozing', event_setting: 'Dozing', event_type_name: 'dv4_drowsiness', description: 'If enabled, the camera will upload Drowsiness / Looking Down events to Rosco Live when they occur.' },
	{ name: 'Phone Use/Hand to Head', event_setting: 'PhoneUse', event_setting: 'PhoneUse', event_type_name: 'dv4_phone_use', description: 'If enabled, the camera will upload Phone Use / Hand to Head events to Rosco Live when they occur.' },
]

const FleetConfigurationEvent = (props) => {
	const { settingsType, user, configuration, configurationdv6, event_types, webfleetDeviceId, setConfiguration, fillFormBoolean, fillFormInt, fillForm } = props
	
	const [showModalDMSSeatbeltToggle, setShowModalDMSSeatbeltToggle] = useState(false)

	const userRole = user.roles[0]
	// const userPermissions = user.permissions
	const isDV6 = settingsType === 'dv6'
    const isDV4 = settingsType !== 'dv6'
    const currentConfig = isDV6 ? configurationdv6 : configuration
	const Sensor4 = find(currentConfig.Event.Sensor, o => o.index === 4);
	// const Sensor8 = find(currentConfig.Event.Sensor, o => o.index === 8);
	// const edit_only = (userRole === 'sales_demo' || (userRole === 'custom_user' && !find(userPermissions, { perm_category_id: 4, a_edit: true })))
	// const isWebfleetMode = webfleetDeviceId.length > 0
	// FWSD-5156, access to change the DV6 ADAS settings for the users with partner role of the Whitelist Tenna LLC and Eagle Wireless partners
	// const hasPartnerAccess = [2171, 537].includes(user.company_id) && userRole === 'partner'
	// const hasPartnerAccess = [3].includes(user.company_id) && userRole === 'partner'

	let unit = 'mph'
	if (currentConfig.System.SpeedUnit === 2) {
		unit = 'kph'
	}

	// console.log('PROPS FleetConfigurationEvent: ', props)
	// console.log('FleetConfigurationEvent currentConfig: ', currentConfig)
	// console.log('PROPS FleetConfigurationEvent configurationdv6: ', configurationdv6)
	// console.log('currentConfig: ', currentConfig)
	// console.log('currentConfig.Event.PanicButton.Enabled: ', currentConfig.Event.PanicButton.Enabled)

	const fillFormSensors = (e, idx) => {
		const { target } = e;
		const { name } = target;
		let value = target.value * 1;
		if (target.type === 'checkbox') {
			value = !!(target.checked);
		}
		else if (target.type === 'button') {
			value = target.value === 'true'
		}

		if (idx === 8) {
			currentConfig.Event.Sensor[4].SendToCloud = value;
			setConfiguration({ configurationdv6 })
		}

		currentConfig.Event.Sensor = currentConfig.Event.Sensor.map((sensor, sidx) => {
			if (idx === sensor.index) {
				sensor[name] = value;
				return sensor;
			}

			return sensor;
		});

		if (isDV6) {
			setConfiguration({ configurationdv6: currentConfig });
		}
		else {
			setConfiguration({ configuration: currentConfig });
		}
		
	}

	const getMinSpeedSlider = () => {
		const { configurationdv6 } = props
		let res = 32
		if (currentConfig.System.SpeedUnit === 1) {
			res = 20
		}
		return res
	}

	const getMaxSpeedSlider = () => {
		const { configurationdv6 } = props
		let res = 89
		if (currentConfig.System.SpeedUnit === 1) {
			res = 55
		}
		return res
	}

	const numberFormat = (argName, argValue = null) => {
		const { configurationdv6 } = props

		let res = configurationdv6.Event.Adas.Headway.SpeedThreshold
		if (currentConfig.System.SpeedUnit === 1) {
			res = Math.round((res / 1.609344) * 1e2) / 1e2
		}

		return +res.toFixed(0)
	}

	const onMinimumSpeedChange = (e, currentConfigArg) => {
		// debugger
		// FWSD-5160
		// let { configurationdv6, setConfiguration } = props
		let { setConfiguration } = props
		// let { value } = e.target
		let value = e * 1

		// const minSpeedValue = getMinSpeedSlider()
		// const maxSpeedValue = getMaxSpeedSlider()

		// if (value < minSpeedValue) {
		// 	value = minSpeedValue
		// }
		// else if (value > maxSpeedValue) {
		// 	value = maxSpeedValue
		// }
		// if (value < minSpeedValue) {
		// 	value = minSpeedValue
		// }
		// else if (value > maxSpeedValue) {
		// 	value = maxSpeedValue
		// }

		// if (configurationdv6.Event.Adas.Headway.SpeedUnit === 'mph') {
		// 	value = Math.round((value * 1.609344) * 1e2) / 1e2;
		// }

		// configurationdv6 = _.set(configurationdv6, 'Event.Adas.Headway.SpeedThreshold', value)
		// configurationdv6 = _.set(configurationdv6, 'Event.Adas.FollowingDistance.SpeedThreshold', value)
		const isMPH = currentConfig.System.SpeedUnit === 1;
		if (isMPH) value = Math.round((value * 1.609344) * 1e2) / 1e2;

		const obj = {target: {name: 'Event.Adas.Headway.SpeedUnit', value: isMPH ? "mph" : "kph"}};
		fillForm(obj, isDV6);
		let configurationdv6 = _.set(currentConfig, 'Event.Adas.Headway.SpeedThreshold', value)
		configurationdv6 = _.set(currentConfig, 'Event.Adas.FollowingDistance.SpeedThreshold', value)
		setConfiguration({ configurationdv6 })
	}

	const setEventTypes = (argTypeValue) => {
		setConfiguration({ event_types: { ...event_types, ...argTypeValue } })
	}

	const fillHighGForceFatalThreshold = (e) => {
		const value = e.target.value * 1

		currentConfig.Event.Accelerometer.FatalThreshold.X = value
		currentConfig.Event.Accelerometer.FatalThreshold.Y = value

		if (isDV6) {
			setConfiguration({ configurationdv6: currentConfig });
		}
		else {
			setConfiguration({ configuration: currentConfig });
		}
	}
	
	const eventSensitivityRender = (argObject) => {
		const { id, min, max, step, name } = argObject
		// FWSD-6898
		const tooltipFormatter = (value) => {
			let res = value

			if (id !== 1) {
				res = (value * 0.28 / 9.81).toFixed(2)
			}

			return `${Math.abs(res)} Gs`
		}

		return (
			<div className="toggle-wrapper slider-wrapper">
				<label className="settings-title">Sensitivity</label>
				<div className="backlight-slider">
					<CustomReactBootstrapSlider
						name={name}
						max={max}
						min={min}
						step={step}
						tooltip="show"
						reversed={true}
						value={_.get(currentConfig, name, '')}
						formatter={tooltipFormatter}
						change={(e) => id === 1 ? fillHighGForceFatalThreshold(e) : fillFormInt(e, isDV6, name)} // FWSD-6903
						// disabled="disabled"
						// ticks={[60, 70, 80, 90, 100]}
					/>
					<InfoPop 
						title='Sensitivity Values' 
						body='Values with lower sensitivity allow the driver to maintain closer distances to the vehicle in front before triggering an alert, whereas values with higher sensitivity will generate an alert at greater distances to the vehicle in front.' 
						placement='top'
					>
						<div className="temp-bar-container slider slider-horizontal mt-10">
							<div className="cold temp-bar">Less Sensitive</div>
							<div className="med temp-bar"/>
							<div className="hot temp-bar">More Sensitive</div>
						</div>
					</InfoPop>
				</div>
			</div>
		)
	}

	const DMSSettingRender = (dmsSetting) => {
		const { name, event_setting, event_type_name, isInternal, description, voice_prompt_setting } = dmsSetting
		// FWSD-7044
		const onToggleClick = (e) => {
			if (e.target.name === 'Event.Dms.Seatbelt.Enabled' && e.target.checked) {
				setShowModalDMSSeatbeltToggle({ function: () => {
					fillFormBoolean({ target: { name: e.target.name, checked: true } }, isDV6)
					setShowModalDMSSeatbeltToggle(false)
				}})
			}
			// FWSD-7081
			else if (e.target.name === 'Event.Dms.Dozing.Enabled') {
				fillFormBoolean({ target: { name: 'Event.Dms.Dozing.Enabled', checked: e.target.checked } }, isDV6)
				fillFormBoolean({ target: { name: 'Event.Dms.HeadDown.Enabled', checked: e.target.checked } }, isDV6)
			}
			else {
				fillFormBoolean(e, isDV6)
			}
		}
		
		// FWSD-7044
		// const fillForm = (e, dv6 = null, argName = null, argValue = null) => {
		const onSensitivityClick = (e, ...arg) => {
			// console.log('arguments: ', arg)
			if (e.target.name === 'Event.Dms.Dozing.Sensitivity') {
				props.fillForm({ target: { name: 'Event.Dms.Dozing.Sensitivity', checked: e.target.checked } }, ...arg)
				props.fillForm({ target: { name: 'Event.Dms.HeadDown.Sensitivity', checked: e.target.checked } }, ...arg)
			}
			else {
				props.fillForm(e, ...arg)
			}
		}

		if (isInternal && !isInternalUser(userRole)) {
			return <div key="empty" />
		}
		else {
			return (
				<div key={name} className="settings-card">
					<div className="card-header">
						<div>
							DMS - {name}
							{isDV4 && <span className="subtitle">REQUIRES ADDITIONAL HARDWARE</span>}
							{isInternal && <LabelTag text='Internal' tag_type='ml-10 internal' />}
						</div>
						<Toggle
							name={`Event.Dms.${event_setting}.Enabled`}
							// checked={currentConfig.Event.Adas.ForwardCollision.Enabled}
							checked={_.get(currentConfig, `Event.Dms.${event_setting}.Enabled`)}
							icons={false}
							// onChange={(e) => fillFormBoolean(e, isDV6)}
							onChange={onToggleClick}
						/>
					</div>
					<div className="card-body">
						<p>{description}</p>
						{/* anumation of card-body content displaing with delay 0.5s */}
						<div className={`${!currentConfig.Event.Dms[event_setting].Enabled ? 'hide' : ''}`}>
							<SelectionEventVideoType
								name={event_type_name}
								value={event_types[event_type_name]}
								setEventTypes={setEventTypes}
								labelPosition="top"
							/>

							{isDV6 &&
							<>
								<div className="toggle-wrapper">
									<label className="settings-title">Event Voice Prompt</label>
									<Toggle
										name={voice_prompt_setting}
										checked={_.get(currentConfig, voice_prompt_setting, '') }
										icons={false}
										onChange={(e) => fillFormBoolean(e, isDV6)}
									/>
								</div>

								<div className="toggle-wrapper button-group">
									<label className="settings-title">Sensitivity</label>
									<div className="button-group">
										<CustomButton
											variant="toggle-group"
											active={(+_.get(currentConfig, `Event.Dms.${event_setting}.Sensitivity`) === -1)}
											name={`Event.Dms.${event_setting}.Sensitivity`}
											// onClick={(e) => props.fillForm(e, true, '', -1)}
											onClick={(e) => onSensitivityClick(e, true, '', -1)}
										>
											Less Sensitive
										</CustomButton>
										<CustomButton
											variant="toggle-group"
											active={(+_.get(currentConfig, `Event.Dms.${event_setting}.Sensitivity`) === 0)}
											name={`Event.Dms.${event_setting}.Sensitivity`}
											// onClick={(e) => props.fillForm(e, true, '', 0)}
											onClick={(e) => onSensitivityClick(e, true, '', 0)}
										>
											Default
										</CustomButton>
										<CustomButton
											variant="toggle-group"
											active={(+_.get(currentConfig, `Event.Dms.${event_setting}.Sensitivity`) === 1)}
											name={`Event.Dms.${event_setting}.Sensitivity`}
											// onClick={(e) => props.fillForm(e, true, '', 1)}
											onClick={(e) => onSensitivityClick(e, true, '', 1)}
										>
											More Sensitive
										</CustomButton>
									</div>
								</div>
							</>
							}
						</div>
					</div>
				</div>
			)
		}
	}

	const ADASSettingRender = (adasSetting) => {
		const { name, event_setting, event_type_name, description } = adasSetting
		// debugger
		return (
			<div key={name} className="settings-card">
				<div className="card-header">
					<div>
						ADAS - {name}
						{isDV4 && <span className="subtitle">REQUIRES MOBILEYE</span>}
					</div>
					<Toggle
						name={`Event.Adas.${event_setting}.Enabled`}
						// checked={_.get(currentConfig, `Event.Adas.${name}.Enabled`)}
						checked={currentConfig.Event.Adas[event_setting]?.Enabled}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				<div className="card-body"> 
					<p>{description}</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${!currentConfig.Event.Adas[event_setting]?.Enabled ? 'hide' : ''}`}> 
						{isDV6 &&
						<>
							<SelectionEventVideoType
								name={event_type_name}
								value={event_types[event_type_name]}
								setEventTypes={setEventTypes}
							/>

							<div className="toggle-wrapper">
								<label className="settings-title">Event Voice Prompt</label>
								<Toggle
									// name=System.Chimes.FollowingDistance"
									name={`System.Chimes.${event_setting}`}
									// checked={currentConfig.System.Chimes.FollowingDistance}
									checked={currentConfig.System.Chimes[event_setting]}
									icons={false}
									onChange={(e) => fillFormBoolean(e, isDV6)}
								/>
							</div>
						</>
						}
					</div>
				</div>
			</div>
		)
	}

	const dv6SensitivitySettingRender = (sensitivitySetting) => {
		const { name, event_setting, event_type_name, description, sliderObj, sublabel } = sensitivitySetting
		return (
			<div key={name} className="settings-card">
				<div className="card-header">
					<div>
						{name}
						{sublabel && <span className="sublabel">{sublabel}</span>}
					</div>
					
					<Toggle
						name={`Event.${event_setting}.Enabled`}
						checked={currentConfig.Event[event_setting].Enabled}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				<div className="card-body"> 
					<p>{description}</p>
					{/* animation of card-body content displaing with delay 0.5s */}
					<div className={`${!currentConfig.Event[event_setting].Enabled ? 'hide' : ''}`}> 
						{/* Upload Type */}
						<SelectionEventVideoType
							name={event_type_name}
							value={event_types[event_type_name]}
							setEventTypes={setEventTypes}
						/>
						{/* Event Chime */}
						<div className="toggle-wrapper">
							<label className="settings-title">Event Chime</label>
							<Toggle
								name={`System.Chimes.${event_setting}`}
								checked={currentConfig.System.Chimes[event_setting]}
								icons={false}
								onChange={(e) => fillFormBoolean(e, isDV6)}
							/>
						</div>
						{/* Sensitivity */}
						{eventSensitivityRender(sliderObj)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<main className="event-settings main-content-block">
			<h3 className="category-title">Event Settings</h3>
			<div className="category-subheader">
				Adjust these settings to customize what events and alerts you would like to receive from your camera.
			</div>

			<div className="settings-card">
				<div className="card-header">
					Driver Event
					<Toggle
						name={isDV6 ? "Enabled" : "Event.PanicButton.Enabled"}
						checked={isDV6 ? Sensor4.Enabled : currentConfig.Event.PanicButton?.Enabled}
						icons={false}
						onChange={(e) => {isDV6 ? fillFormSensors(e, 4) : fillFormBoolean(e, isDV6)}}
					/>
				</div>
				<div className="card-body">
					<p>If enabled, the camera will report Driver Events to RoscoLive when they occur. Driver Events occur when the driver event button is pressed on the front of the camera.</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${(isDV6 && !Sensor4.Enabled) || (isDV4 && !currentConfig.Event.PanicButton?.Enabled) ? 'hide' : ''}`}>
						<SelectionEventVideoType
							name={isDV6 ? "dv6_driver" : "dv4_driver"}
							value={isDV6 ? event_types.dv6_driver : event_types.dv4_driver}
							setEventTypes={setEventTypes}
						/>
					</div>
				</div>
			</div>

			{isDV4 && 
				<div className="settings-card">
					<div className="card-header">
						External Driver Event
						<Toggle
							name="Enabled"
							checked={Sensor4.Enabled}
							icons={false}
							onChange={(e) => fillFormSensors(e, 4)}
						/>
					</div>
					<div className="card-body"> 
						<p>If enabled, the camera will report External Driver Events to RoscoLive when they occur. External Driver Events require the external driver event button to be installed and occur when the external driver event button is pressed.</p>
						{/* anumation of card-body content displaing with delay 0.5s */}
						<div className={`${!Sensor4.Enabled ? 'hide' : ''}`}> 
							<SelectionEventVideoType
								name="dv4_ext_driver"
								value={event_types.dv4_ext_driver}
								setEventTypes={setEventTypes}
							/>
						</div>
					</div>
				</div>
			}

			<div className="settings-card">
				<div className="card-header">
					Max Speed
					<Toggle
						name="Event.HighSpeed.Enabled"
						checked={currentConfig.Event.HighSpeed.Enabled}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				<div className="card-body"> 
					<p>If enabled, the camera will report Max Speeding events to RoscoLive when they occur. Max Speed events occur when the vehicle speed exceeds the threshold value set.</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${!currentConfig.Event.HighSpeed.Enabled ? 'hide' : ''}`}> 
						<SelectionEventVideoType
							name={isDV6 ? "dv6_high_speed" : "dv4_high_speed"}
							value={isDV6 ? event_types.dv6_high_speed : event_types.dv4_high_speed}
							setEventTypes={setEventTypes}
						/>

						<div className="toggle-wrapper button-group">
							<label className="settings-title">Max Speed Threshold</label>
							<div>
								<input
									type="number"
									step="1"
									min="0"
									max="1000"
									maxLength="4"
									value={(currentConfig.System.SpeedUnit === 2) ? currentConfig.Event.HighSpeed.Threshold : (currentConfig.Event.HighSpeed.Threshold / 1.609344).toFixed(0)}
									className="vehicle-search number-input mr-10 pt-5"
									name="Event.HighSpeed.Threshold"
									onChange={(e) => fillForm(e, isDV6)}
								/>
								<label style={{ marginLeft: '6px' }}>{currentConfig.System.SpeedUnit === 2 ? 'KPH' : 'MPH'}</label>
							</div>
						</div>

						{isDV6 &&
							<div className="toggle-wrapper button-group">
								<label className="settings-title">Event Chime</label>
								<Toggle
									name="System.Chimes.HighSpeed"
									checked={currentConfig.System.Chimes.HighSpeed}
									icons={false}
									onChange={(e) => fillFormBoolean(e, isDV6)}
								/>
							</div>
						}
					</div>
				</div>
			</div>

			<div className="settings-card">
				<div className="card-header">
					Speeding
					<LabelTag text='Under Improvement' tag_type='beta' />
				</div>
				<div className="card-body"> 
					<p>Rosco offers an additional resource for capturing speeding, based on the posted speed limit. Speeding is broken down into four categories, based on severity:</p>
					<ul>
						<li>+1 to +5 over the speed limit (low speeding)</li>
						<li>+6 to +10 over the speed limit (moderate speeding)</li>
						<li>+11 to +15 over the speed limit (high speeding)</li>
						<li>+16 (severe speeding)</li>
					</ul>
					<p>
						Speeding of this type will be identified using the Trip Replay tool, reports, and real-time alerts
						via <Link to='/account/settings'>email and SMS messages</Link>.
					</p>
				</div>
			</div>

			{isDV6 ? (
			<>
				{/* DV6 Sensitivity  */}
				{
					dv6SensitivitySettings.map((item) => dv6SensitivitySettingRender(item))
				}

				{/* DV6 ADAS  */}
				{
					dv6ADASSettings.map((item) => ADASSettingRender(item))
				}

				<div className="settings-card">
					<div className="card-header">
						ADAS - Headway Monitoring & Tailgating Sensitivity Settings
					</div>
					<div className="card-body"> 
						<p>The following settings will determine the sensitivity and frequency at which Headway Monitoring and Tailgating events are detected.</p>
						<div className="toggle-wrapper slider-wrapper block">
							<label className="settings-title mb-10">Following Distance Time</label>
							<div className="toggle-wrapper slider-wrapper p-0 no-border">
								<div className="backlight-slider mr-20">
									<CustomReactBootstrapSlider
										// name="Event.Adas.Headway.Ttc.TriggerThreshold"
										max={3.0}
										min={0.1}
										step={0.1}
										tooltip="show"
										value={+currentConfig.Event.Adas.Headway.Ttc.TriggerThreshold}
										change={(e) => fillFormInt(e, isDV6, 'Event.Adas.Headway.Ttc.TriggerThreshold')}
									/>
									{/* <Slider
										// name="Event.Adas.Headway.Ttc.TriggerThreshold"
										max={3.0}
										min={0.1}
										step={0.1}
										value={+currentConfig.Event.Adas.Headway.Ttc.TriggerThreshold}
										onChange={(e) => fillFormInt({target:{name:"Event.Adas.Headway.Ttc.TriggerThreshold", value: e}}, isDV6, 'Event.Adas.Headway.Ttc.TriggerThreshold')}
									/> */}
									<InfoPop 
											title='Sensitivity Values' 
											body='Values with lower sensitivity allow the driver to maintain closer distances to the vehicle in front before triggering an alert, whereas values with higher sensitivity will generate an alert at greater distances to the vehicle in front.' 
										placement='top'
									>
										<div className="temp-bar-container slider slider-horizontal mt-10">
												<div className="cold temp-bar">Less Sensitive</div>
												<div className="med temp-bar"/>
												<div className="hot temp-bar">More Sensitive</div>
										</div>
									</InfoPop>
								</div>
								<div>
									<input
										type="number"
										step="0.1"
										min="0.1"
										max="3.0"
										maxLength="3"
										name="Event.Adas.Headway.Ttc.TriggerThreshold"
										value={currentConfig.Event.Adas.Headway.Ttc.TriggerThreshold}
										className="vehicle-search number-input mr-10 pt-5"
										onChange={(e) => fillFormInt(e, isDV6)}
									/>
									<label style={{ marginLeft: '6px' }}>seconds</label>
								</div>
							</div>
						</div>

						<div className="toggle-wrapper slider-wrapper block">
							<label className="settings-title mb-10">Minimum Speed</label>
							<div className="toggle-wrapper slider-wrapper p-0 no-border">
								<div className="slider-wrapper header-slider">
									<div className="slider-minmax-value min-value">
										{`${getMinSpeedSlider()} ${currentConfig.System.SpeedUnit === 1 ? 'MPH' : 'KPH'}`}
									</div>
									<CustomReactBootstrapSlider
										min={getMinSpeedSlider()}
										max={getMaxSpeedSlider()}
										step={1}
										tooltip="show"
										value={numberFormat()}
										change={(e) => onMinimumSpeedChange(e.target.value)}
									/>
									{/* <Slider
										// name="Event.Adas.Headway.SpeedThreshold"
										min={getMinSpeedSlider()}
										max={getMaxSpeedSlider()}
										step={1}
										value={numberFormat()}
										onChange={onMinimumSpeedChange}
									/> */}
									<div className="slider-minmax-value max-value">
										{`${getMaxSpeedSlider()} ${currentConfig.System.SpeedUnit === 1 ? 'MPH' : 'KPH'}`}
									</div>
								</div>
								<div>
									<input
										type="number"
										step="1"
										min={getMinSpeedSlider()}
										max={getMaxSpeedSlider()}
										maxLength="3"
										name="Event.Adas.Headway.Ttc.TriggerThreshold"
										value={numberFormat()}
										className="vehicle-search number-input mr-10 pt-5"
										onChange={(e) => onMinimumSpeedChange(e.target.value)}
									/>
									<label style={{ marginLeft: '6px' }}>{currentConfig.System.SpeedUnit === 1 ? 'MPH' : 'KPH'}</label>
								</div>
								{/* <div className="toggle-buttons button-group">
									<CustomButton
										variant='toggle'
										active={currentConfig.Event.Adas.Headway.SpeedUnit === 'mph'}
										value="mph"
										name="Event.Adas.Headway.SpeedUnit"
										onClick={(e) => fillForm(e, isDV6)}
									>
										MPH
									</CustomButton>
									<CustomButton
										variant='toggle'
										active={currentConfig.Event.Adas.Headway.SpeedUnit === 'kph'}
										value="kph"
										name="Event.Adas.Headway.SpeedUnit"
										onClick={(e) => fillForm(e, isDV6)}
									>
										KPH
									</CustomButton>
								</div> */}
							</div>
						</div>					
					</div>
				</div>

				{/* DV6 DMS  */}
				{
					dv6DMSSettings.map((item) => DMSSettingRender(item))
				}
			</>
			) : (
			<>
				{/* DV4 ADAS  */}
				{
					dv4ADASSettings.map((item) => ADASSettingRender(item))
				}

				<div className="settings-card">
					<div className="card-header">
						<div>
							ADAS - Speed Warning Threshold
							<span className="subtitle">REQUIRES MOBILEYE</span>
						</div>
						<select
							placeholder="select"
							className="rosco-select"
							name="Event.Adas.SpeedThreshold"
							onChange={(e) => fillFormInt(e, isDV6)}
							value={currentConfig.Event.Adas.SpeedThreshold}
						>
							<option value="0">Level 0 +[0 {unit}]</option>
							<option value="1">Level 1 +[0-5 {unit}]</option>
							<option value="2">Level 2 +[5-10 {unit}]</option>
							<option value="3">Level 3 +[10-15 {unit}]</option>
							<option value="4">Level 4 +[15-20 {unit}]</option>
							<option value="5">Level 5 +[20-25 {unit}]</option>
							<option value="6">Level 6 +[25-30 {unit}]</option>
							<option value="7">Level 7 +[30-35 {unit}]</option>
							<option value="8">Level 8 +[35 {unit}]</option>
						</select>
					</div>
					<div className="card-body"> 
						<p>Sets the level for Speed Warnings on Mobileye.</p>
					</div>
				</div>

				{/* DV4 DMS  */}
				{
					dv4DMSSettings.map((item) => DMSSettingRender(item))
				}
			</>
			)}

			{showModalDMSSeatbeltToggle &&
				<ModalDelete
					title={'Channel 2 Camera Adjustment'}
					content={`To get the best and most reliable performance from the camera for this event type, ensure the channel 2 camera captures the driver’s head and upper torso area as close as possible to the right middle of the screen when viewing the video from that channel. This will ensure the camera is able to reliably detect if a seatbelt is present across the driver’s shoulders. If not properly aligned, the camera may fail to detect a seatbelt and generate false positives or not generate seatbelt detection events when they otherwise should have. <br/><br/> <a href="https://support.roscovision.com/knowledge/dv6-channel-2-adjustment-guide-for-seatbelt-detection" target="_blank">Learn more here</a>`}
					showClose
					deleteBtnName="Enable Seatbelt Detection"
					closeBtnName="Back"
					styleView="primary"
					backdropClassName="upper"
					closeModal={() => {	setShowModalDMSSeatbeltToggle(false) }}
					deleteModal={showModalDMSSeatbeltToggle.function}
				/>
			}
		</main>
	)
}

FleetConfigurationEvent.propTypes = {
	webfleetDeviceId: PropTypes.string,
}

FleetConfigurationEvent.defaultProps = {
	webfleetDeviceId: '',
}

export default FleetConfigurationEvent;
