const env = location.hostname

export function transformationLabel(label) {
	switch (label) {
	case 'bar_top_10':
		// return 'Bar Graph Top 10 vehicles';
		return 'Bar Graph - Vehicles with Least Events Generated';
	case 'bar_bottom_10':
		// return 'Bar Graph Bottom 10 Vehicles';
		return 'Bar Graph - Vehicles with Most Events Generated';
	case 'bar_custom':
		return 'Bar - selected Vehicles';
	case 'pie_top_10':
		// return 'Pie Chart Top 10 Vehicles';
		return 'Pie Chart - Vehicles with Least Events Generated';
	case 'pie_bottom_10':
		// return 'Pie Chart Bottom 10 Vehicles';
		return 'Pie Chart - Vehicles with Most Events Generated';
	case 'pie_custom':
		return 'Pie - selected Vehicles';
	case 'last_6_month':
		return 'Last 6 month';
	case 'today':
		return 'Today';
	case 'yesterday':
		return 'Yesterday';
	case 'prev_24_hours':
		return 'Previous 24 hours';
	case 'prev_7_days':
		return 'Previous 7 days';
	case 'this_week':
		return 'This week';
	case 'last_week':
		return 'Last week';
	case 'prev_14_days':
		return 'Previous 14 days';
	case 'this_month':
		return 'This month';
	case 'last_month':
		return 'Last month';
	case 'prev_4_weeks':
		return 'Previous 4 weeks';
	case 'prev_30_days':
		return 'Previous 30 days';
	case 'name':
		return 'name';
	case 'type':
		return 'type';
	// case 'date_range':
	//     return 'date range';
	//     break;
	case 'events':
		return 'events';
	case 'alerts':
		return 'alerts';
	default:
		break;
	}
}

export function defineEnvironmentDokuWiki() {
	if (process.env.REACT_APP_dokuwikiLinks && process.env.REACT_APP_dokuwikiLinks.url) {
		return process.env.REACT_APP_dokuwikiLinks.url
	}

	switch (env) {
		case 'localhost':
		case 'test.roscolive.com':
		case 'dev.roscolive.com':
		case 'staging.roscolive.com':
			return 'devwiki.roscocloud.com';
		case 'roscocloud.com':
		case 'www.roscocloud.com':
		case 'fleet.roscolive.com':
			return 'wiki.roscocloud.com';
		default:
			break;
	}
}

export function getDirection(direction, abbreviation = false) {
	let dir = 'Unknown'
	if (direction > 337.5 || direction <= 22.5)
		dir = abbreviation ? 'N' : 'North'
	else if (direction > 22.5 && direction <= 67.5)
		dir = abbreviation ? 'NE' : 'North East'
	else if (direction > 67.5 && direction <= 112.5)
		dir = abbreviation ? 'E' : 'East'
	else if (direction > 112.5 && direction <= 157.5)
		dir = abbreviation ? 'SE' : 'South East'
	else if (direction > 157.5 && direction <= 202.5)
		dir = abbreviation ? 'S' : 'South'
	else if (direction > 202.5 && direction <= 247.5)
		dir = abbreviation ? 'SW' : 'South West'
	else if (direction > 247.5 && direction <= 292.5)
		dir = abbreviation ? 'W' : 'West'
	else if (direction > 292.5 && direction <= 337.5)
		dir = abbreviation ? 'NW' : 'North West'

	return dir
}

export const getDirectionIcon = (direction) => {
	switch (direction) {
	case 'n':
		return 'North'
	case 'ne':
		return 'North East'
	case 'e':
		return 'East'
	case 'se':
		return 'South East'
	case 's':
		return 'South'
	case 'sw':
		return 'South West'
	case 'w':
		return 'West'
	case 'nw':
		return 'North West'
	default:
		return 'Unknown'
	}
}
