import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Dropdown, Fade } from 'react-bootstrap'
import _ from 'lodash'

import momenttz from '../helpers/momenttz'
import moment from 'moment'
import Auth from '../Auth'
import { BiTime } from 'react-icons/bi'
import AnnounceKit from 'announcekit-react'

import { apiCall, apiCallGet } from '../../action/RouterActions'
import { logoutUser } from '../../action/AccountActions'
import { getCompaniesList, getPartnerCompaniesList } from '../../action/CompaniesActions'
import { getUserNotifications, removeUserNotification, viewedUserNotifications, addAuditLog } from '../../action/ClientActions'
// import { viewedLastReleaseDescription } from '../../action/ClientActions';

import './HeaderNav.sass'
import CustomButton from '../CustomButton';
import UserDropdown from './UserDropdown'
import { notificationsIcon, userIcon } from '../helpers/iconHelpers'

const HeaderNav = (props) => {
	const { user, company, partnerList, notification, setUserNotifications, updateCompanyList, updatePartnerList, updateCompany, updateCompanyCollection, partnerPortalRedirect, updatePartnerPortalRedirect, toggleLoader } = props;
	const userRole = user.roles[0];
	let widgetRef = useRef(null)
	// FWSD-6888
	// const announceLink = document.location.hostname.includes('staging') ? 'https://updates.roscolive.com/widgets/v2/2Z9pGE' : 'https://updates.roscolive.com/widgets/v2/2Z9pGE'
	const announceLink = 'https://updates.roscolive.com/widgets/v2/2Z9pGE'

	const [company_name, setCompany_name] = useState('');
	const [partner_company_name, setPartner_company_name] = useState('');
	const [hasViewedNotifications, setHasViewedNotifications] = useState(false);
	const [userDropdownOpen, setUserDropdownOpen] = useState(false);
	// const [announceKitOpen, setAnnounceKitOpen] = useState(false);
	const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false);

	useEffect(() => {
		let { partner_company_name, company_id, company_name, company_type, company_driver_recognition, company_token, roles, company_data } = user;
		let partnerLocal = localStorage.getItem("partner_company_name");
		let companyLocal = localStorage.getItem("company_name");
		if (!!partnerLocal === true) partner_company_name = partnerLocal;
		if (!!companyLocal === true) company_name = companyLocal;
		changeCompany(company_name, partner_company_name);

		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				getPartners()
				getPartnersCompanies(partner_company_name)
				break
			case 'installer':
				getPartners()
				getPartnersCompanies()
				break
			case 'partner':
			case 'custom_partner':
				getCompanies()
				break
			case 'fleet_maintainer':
			case 'fleet_manager':
			case 'group_manager':
			case 'user':
			case 'storage_manager':
			case 'storage_user':
			case 'custom_user':
				updateCompany({
					partner: partner_company_name,
					company_id,
					name: company_name,
					type: company_type,
					driver_recognition: company_driver_recognition,
					token: company_token,
					company_data,
				})
				break
			case 'partner_view':
				getCompanies()
				break
			default:
				break
		}

		getNotifications()
		setInterval(() => {
			getNotifications()
		}, 600000)

		// apiCallGet('/releases/last')
		// 	.then(async (res) => {
		// 		const release = await res.data.response
		// 		if (release && moment.utc().isAfter(moment.utc(release.timestamp)) &&
		// 			(!user.release_viewed || moment.utc(user.release_viewed).isBefore(moment.utc(release.timestamp)))
		// 		) {
		// 			setTimeout(() => {
		// 				setAnnounceKitOpen(true);
		// 				!!widgetRef && widgetRef.current.open();
		// 			}, 1000)
		// 		}
		// 	})
		// 	.catch(err => {
		// 		console.log(err)
		// 	})
	}, []);

	React.useEffect(() => {
        widgetRef.current.unread()
			.then(unreadCount => {
				// console.log("!!!unread", unreadCount)
				if (unreadCount > 0) {
					widgetRef.current.open()
				}
			})
    }, [widgetRef])

	const getPartners = (callback) => {
		const companyType = 'PARTNER';

		toggleLoader(true);

		getCompaniesList(userRole, companyType)
			.then((res, err) => {
				console.log('!getCompaniesList res', res);
				toggleLoader(false);

				const companies = _.sortBy(res.data.response['companies list'], num => num.toLowerCase());
				const filtered_companies = filterName(companies);

				updatePartnerList(filtered_companies);

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getCompaniesList error:', error);
				toastr.error('There was an issue getting the available companies. Please try again later.');
			});
	}

	const filterName = (companies) => {
		return _.filter(companies.map(item => item.trim()), (num) => num !== '')
	}

	const getPartnersCompanies = (partnerCompanyName) => {
		toggleLoader(true);

		getPartnerCompaniesList(userRole, partnerCompanyName, true)
			.then((res, err) => {
				console.log('!getPartnersCompanies res', res)
				toggleLoader(false);

				let companiesList = res.data.response['companies list'];
				let companies;
				if (companiesList[0]) {
					companiesList = _.sortBy(_.filter(companiesList.map(item => ({
						name: item.name.trim(),
						type: item.type,
						id: item.id,
						driver_recognition: item.driver_recognition,
						token: item.token,
						company_data: item.company_data,
					})), num => num.name !== ''), num => num.name.toLowerCase())

					if (companiesList.length) {
						companies = _.map(companiesList, 'name')
					}
				} else {
					companies = [];
				}

				let i = 0;
				let companyLocal = localStorage.getItem("company_name");
				(!!companyLocal === true) ? companiesList.map((item, index) => { if (item.name === companyLocal) i = index }) : companiesList.map((item, index) => { if (item.name === user.company_name) i = index });

				if (partnerCompanyName === user.partner_company_name) {
					updateProps(
						companiesList[i].name,
						partnerCompanyName,
						companiesList[i].type,
						companiesList[i].id,
						companiesList[i].driver_recognition,
						companiesList[i].token,
						companiesList[i].company_data,
					)

					addAuditLogFunc(companiesList[i].id)
				} else if (!companiesList.length) {
					toastr.info("The selected partner has no companies. Please select a different option")
				}
				else {
					updateProps(
						companiesList[i].name,
						partnerCompanyName,
						companiesList[i].type,
						companiesList[i].id,
						companiesList[i].driver_recognition,
						companiesList[i].token,
						companiesList[i].company_data,
					)

					addAuditLogFunc(companiesList[i].id)
				}

				updateCompanyList(companies)
				updateCompanyCollection(companiesList)
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getPartners error: ', error);
				toastr.error('There was an issue getting the available companies. Please try again later.');
			});
	}

	const updateProps = (company, partner, type, company_id, driver_recognition, company_token, company_data) => {
		updateCompany({
			partner,
			name: company,
			type,
			company_id,
			driver_recognition,
			token: company_token,
			company_data,
		})
	}

	const getCompanies = () => {
		const company_type = 'CUSTOMER'
		toggleLoader(true);

		getCompaniesList(userRole, company_type, true)
			.then((res, err) => {
				console.log('!getCompaniesList getCompanies res', res, err)
				toggleLoader(false);

				let companies_list = res.data.response['companies list'];
				let companies
				if (companies_list[0]) {
					companies_list = _.sortBy(_.filter(companies_list.map(item => ({
						name: item.name.trim(),
						type: item.type,
						id: item.id,
						driver_recognition: item.driver_recognition,
						token: item.token,
						company_data: item.company_data,
					})), num => num.name !== ''), num => num.name.toLowerCase())

					if (companies_list.length) {
						companies = _.map(companies_list, 'name')
					}
				}
				else {
					companies = [];
				}

				let i = 0;
				let companyLocal = localStorage.getItem("company_name");
				if (!!companyLocal === true) companies_list.map((item, index) => { if (item.name === companyLocal) i = index });

				if (['partner', 'partner_view', 'custom_partner'].includes(userRole)) {
					// FWSD-4893, user was redirected to RL UI from the Partner Portal's Companies page
					if (partnerPortalRedirect.company_id && !partnerPortalRedirect.user_was_redirected) {
						const companySelected = _.find(companies_list, ['id', +partnerPortalRedirect.company_id])
						setCompany_name(companySelected.name)
						updatePartnerPortalRedirect({
							user_was_redirected: true,
						})

						updateCompany({
							name: companySelected.name,
							partner: user.company_name,
							type: companySelected.type,
							company_id: companySelected.id,
							driver_recognition: companySelected.driver_recognition,
							token: companySelected.token,
							company_data: companySelected.company_data,
						})
					} else {
						updateCompany({
							name: companies_list[i].name,
							partner: user.company_name,
							type: companies_list[i].type,
							company_id: companies_list[i].id,
							driver_recognition: companies_list[i].driver_recognition,
							token: companies_list[i].token,
							company_data: companies_list[i].company_data,
						})
					}
				}

				updateCompanyList(companies)
				updateCompanyCollection(companies_list)

			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);

				toastr.error('There was an issue getting the available companies. Please try again later.');
			});
	}

	// const handlePartnerCompanyChange = (event) => {
	// 	const { target } = event;
	// 	const { value } = target;

	// 	getPartnersCompanies(value);
	// }

	// const handleCompanyChange = (event) => {
	// 	const { target } = event;
	// 	const { value } = target;

	// 	const obj = _.find(partnerList.company_collection, ['name', value])
	// 	updateCompany({
	// 		name: value,
	// 		partner: company.partner,
	// 		type: company.type,
	// 		company_id: obj.id,
	// 		driver_recognition: obj.driver_recognition,
	// 		token: company.token,
	// 		company_data: company.company_data,
	// 	})
	// }

	const logOut = (e) => {
		e.preventDefault()
		localStorage.removeItem("partner_company_name");
		localStorage.removeItem("company_name");

		logoutUser()
			.then(res => {
				new Auth().deauthenticateUser();
				location.href = "/";
			})
			.catch((error) => {
				console.log(error);
			})
	}

	const getNotifications = () => {
		getUserNotifications()
			.then((res, err) => {
				const notifications = res.data.response.sort((a, b) => moment(b.createtime).format('YYYYMMDDHHmmss') - moment(a.createtime).format('YYYYMMDDHHmmss'))
				setUserNotifications(notifications)
			})
			.catch((error) => {
				console.log('!getNotifications error', error)
			})
	}

	const removeNotification = (e, id) => {
		if (typeof e.stopPropagation == "function") {
			e.stopPropagation();
		} else {
			e.cancelBubble = true;
		}

		const filtered = notification.notifications.filter(notification => notification.id != id * 1)
		setUserNotifications(filtered)
		removeUserNotification({ notification_id: id })
			.then((res, err) => {
				console.log('!removeNotification res', res, err)
			})
			.catch((error) => {
				console.log('!removeNotification error', error)
			})
	}

	const clearEarlierNotifications = async (e) => {
		const earlier_notifications = notification.notifications.filter(({ status }) => status === 'viewed');
		for (const n of earlier_notifications) {
			let res = await removeUserNotification({ notification_id: n.id })
			if (res) {
				console.log('!removeNotification res', res);
			}
			else {
				console.log(res);
			}
		}
		const new_notifications = notification.notifications.filter(({ status }) => status === 'active');
		setUserNotifications(new_notifications);
	}

	const clearNewNotifications = async (e) => {
		const new_notifications = notification.notifications.filter(({ status }) => status === 'active');
		for (const n of new_notifications) {
			let res = await removeUserNotification({ notification_id: n.id })
			if (res) {
				console.log('!removeNotification res', res);
			}
			else {
				console.log(res);
			}
		}
		const earlier_notifications = notification.notifications.filter(({ status }) => status === 'viewed');
		setUserNotifications(earlier_notifications);
	}

	const viewedNotifications = () => {
		viewedUserNotifications()
			.then((res, err) => {
				console.log('!viewedUserNotifications res', res, err)
				setHasViewedNotifications(true)
			})
			.catch((error) => {
				console.log('!viewedUserNotifications error', error)
			})
	}

	const onSelectCompany = (companyname) => {
		const companySelected = _.find(partnerList.company_collection, ['name', companyname])
		setCompany_name(companyname);
		setUserDropdownOpen(false);

		updateCompany({
			partner: company.partner,
			name: companyname,
			type: companySelected.type,
			company_id: companySelected.id,
			driver_recognition: companySelected.driver_recognition,
			token: companySelected.token,
			company_data: companySelected.company_data,
		})

		addAuditLogFunc(companySelected.id)
	}

	const addAuditLogFunc = (companyId) => {
		const data = {
			action: 'select_company',
			action_data: { company_id: companyId },
		}
		addAuditLog(data)
			.then((res, err) => {

			})
			.catch((error) => {
				console.log('!addAuditLog error:', error)
			})
	}

	const changeCompany = (company_name, partner_company_name) => {
		partner_company_name && setPartner_company_name(partner_company_name);
		setCompany_name(company_name);
	}

	const sortData = (arr) => {
		const format = value => Number(moment(value).format('YYYYMMDDHHmmss'));
		return arr.sort((a, b) => {
			if (format(a.createtime) < format(b.createtime)) {
				return 1;
			}
			if (format(a.createtime) > format(b.createtime)) {
				return -1;
			}
			return 0;
		})
	}

	// const onReleaseViewed = () => {
	// 	setAnnounceKitOpen(false);
	// 	const timeReviewed = moment.utc();
	// 	viewedLastReleaseDescription(timeReviewed.format('YYYYMMDDHHmm00'))
	// 		.then(res => {
	// 			props.updateUser({ ...user, release_viewed: timeReviewed.format('YYYY-MM-DD HH:mm:ss') })
	// 		})
	// 		.catch(err => {
	// 			console.log(err)
	// 		})
	// }

	const new_notifications = notification.notifications.filter(({ status }) => status === 'active');
	const earlier_notifications = notification.notifications.filter(({ status }) => status === 'viewed');
	const redirectUrl = partnerPortalRedirect.portal_url;

	return (
		<nav className="header-nav">
			{(['partner', 'custom_partner', 'partner_view'].includes(userRole) || redirectUrl) &&
				<CustomButton
					variant="primary"
					style={{ marginRight: '15px' }}
					size="sm"
					onClick={() => location.href = redirectUrl ? `${redirectUrl}${partnerPortalRedirect.type === 'company' ? 'company' : ''}` : 'https://partner.roscolive.com'}
				>
					Partner Portal
				</CustomButton>
			}
			<Dropdown
				id="user-notifications-btn"
				className='notifications-dropdown'
				pull-right="true"
				role="menuitem"
				onToggle={isOpen => {
					!isOpen && viewedNotifications();
					setNotificationDropdownOpen(isOpen);
				}}
			>
				<Dropdown.Toggle
					className='dropdown-toggle notifications-toggle'
					bsPrefix="no-caret"
					variant="outline"
					aria-controls='notification-dropdown'
				>
					{notificationsIcon}
					{(new_notifications.length > 0 && !hasViewedNotifications) &&
						<span className="alert-bubble">{new_notifications.length}</span>
					}
				</Dropdown.Toggle>
				<Fade in={notificationDropdownOpen}>
					<Dropdown.Menu className="dropdown-menu notifications-menu">
						{new_notifications.length > 0
							&& <li className="group">
								NEW
								<CustomButton variant='link' prefix='clear-notifications' onClick={clearNewNotifications}>
									CLEAR ALL
								</CustomButton>
							</li>
						}
						{new_notifications.length > 0
							&& new_notifications.map(item => <NotificationNode key={item.id} removeNotification={removeNotification} {...item} />)
						}
						{earlier_notifications.length > 0
							&& <li className="group">
								EARLIER
								<CustomButton variant='link' prefix='clear-notifications' onClick={clearEarlierNotifications}>
									CLEAR ALL
								</CustomButton>
							</li>
						}
						{earlier_notifications.length > 0
							&& earlier_notifications.map(item => <NotificationNode key={item.id} removeNotification={removeNotification} {...item} />)
						}
						{!new_notifications.length && !earlier_notifications.length &&
							<div className='empty-notifications'>
								No User Notifications
							</div>
						}
					</Dropdown.Menu>
				</Fade>
			</Dropdown>
			<Dropdown
				id="user-account-btn"
				className='user-icon-dropdown'
				pull-right="true"
				role="menuitem"
				show={userDropdownOpen}
				onToggle={isOpen => setUserDropdownOpen(isOpen)}
			>
				<Dropdown.Toggle
					className="dropdown-toggle user-toggle"
					bsPrefix="no-caret"
					variant="outline"
					aria-controls='user-settings-dropdown'
				>
					{userIcon}
				</Dropdown.Toggle>
				<Dropdown.Menu className="user-account-menu">
					<UserDropdown
						partnerList={partnerList}
						changeCompany={changeCompany}
						onSelectCompany={onSelectCompany}
						getPartnersCompanies={getPartnersCompanies}
						logOut={logOut}
						closeDropdown={() => setUserDropdownOpen(false)}
					/>
				</Dropdown.Menu>
			</Dropdown>

			<AnnounceKit
				widget={"https://announcekit.co/widgets/v2/2Z9pGE"} // widget key as google APIKey
				onWidgetOpen={() => setUserDropdownOpen(false)} // Called when the widget is opened.
				// onWidgetClose={() => onReleaseViewed()} // Called when the widget is closed.
				// onWidgetUnread={() => alert('onWidgetUnread2')} // Called when unread post count of widget has been updated.
				ref={widgetRef}
				// ref={elem => (widgetRef = elem)}
				user={{
					id: user.id,
					email: user.email
				}}
				data={{
					role: user.roles[0]
				}}
				widgetStyle={{
					height: '0.75em',
					top: '13px',
					position: 'absolute',
					left: '48px',
				}}
			/>
		</nav>
	)
}

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
		company: state.company.company,
		partnerList: state.partnerList,
		notification: state.notification,
		partnerPortalRedirect: state.partnerPortalRedirect,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateCompany: (company) => {
			dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company });
		},
		updateCompanyList: (partner) => {
			dispatch({ type: 'GET_COMPANY_LIST_SUCCESS', payload: partner });
		},
		updateCompanyCollection: (partner) => {
			dispatch({ type: 'GET_COMPANY_COLLECTION_SUCCESS', payload: partner });
		},
		updatePartnerList: (partner) => {
			dispatch({ type: 'GET_PARTNER_LIST_SUCCESS', payload: partner });
		},
		setUserNotifications: (state) => {
			dispatch({ type: 'SET_USER_NOTIFICATIONS', payload: state });
		},
		updatePartnerPortalRedirect: (payload) => {
			dispatch({ type: 'SET_PARTNER_PORTAL_REDIRECT', payload });
		},
		updateUser: (user) => {
			dispatch({ type: 'GET_USER_SUCCESS', payload: user });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);

const NotificationNode = (props) => {
	const { id, notification, createtime, type, removeNotification } = props;

	const handleCloseClick = (e) => {
		removeNotification(e, id)
	}
	const handleCompleted = (e) => {
		removeNotification(e, id);
		apiCall('POST', '/user/notification/reformat/complete')
			.then((res, err) => {
				console.log('!handleCompleted res:', res, err)
			})
			.catch((error) => {
				console.log('!handleCompleted error:', error.response)
			})
	}
	return (
		<li role="presentation">
			<div className="notification-item">
				<CustomButton size="icon" prefix="transparent close" onClick={handleCloseClick}>
					<span aria-hidden="true">×</span>
					<span className="sr-only">Close</span>
				</CustomButton>
				<p>
					<span className="item-info" dangerouslySetInnerHTML={{ __html: notification }} />
					{type === 'sdformat' && <button className="btn-completed" onClick={handleCompleted}>Completed</button>}
				</p>
				<p className="item-time">
					<BiTime />
					{` ${momenttz(createtime).format('MM/DD/YYYY h:mm:ss A')}`}
				</p>
			</div>
		</li>
	)
};
