import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import EventClipsContainer from '../../smart/menuComponentsContainer/EventClipsContainer';
import EventFlagged from '../../smart/menuComponentsContainer/EventFlagged';
import EventClipsQuarantine from '../../smart/menuComponentsContainer/EventClipsQuarantine';
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const Events = (props) => {
	const { roles, permissions } = props.user;

	const [tabKey, setTabKey] = useState(1)
	const [location, setLocation] = useState(null)

	useEffect(() => {
		const { location } = props;
		if (location && location.state) {
			setLocation(location)
		}
		else {
			setLocation(false)
		}
	}, [])

	useEffect(() => {
		const { location } = props
		if (location && location.state) {
			setLocation(location)
		}
		else {
			setLocation(false)
		}
	}, [props.location])

	const handleSelect = (tabKey) => {
		setTabKey(tabKey)
		const label = tabKey === 1 ? 'Daily Events' : tabKey === 2 ? 'Flagged Events' : 'Quarantine'
		ReactGA.event({
			category: roles[0],
			action: 'Change Events Tab',
			label,
		})
	};

	const hasEditPermission = !(['custom_user', 'custom_partner'].includes(roles[0]) && _.find(permissions, { 'perm_category_id': 2, 'a_edit': false }))

	return (
		<div className="events-page">
			<GreyHeader
				title="Events"
				renderSubtitle={
					hasEditPermission &&
					(<nav className='page-subtitle'>
						<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => handleSelect(1)}>
							Daily Events
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 2} onClick={() => handleSelect(2)}>
							Flagged Events
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 3} onClick={() => handleSelect(3)}>
							Quarantine
						</CustomButton>
					</nav>)
				}
			/>

			{/* {location !== null && (tabKey === 1 ? <EventClipsContainer location={location} /> : <EventFlagged />)} */}
			{location !== null && tabKey === 1 && <EventClipsContainer location={location} />}
			{location !== null && tabKey === 2 && <EventFlagged />}
			{location !== null && tabKey === 3 && <EventClipsQuarantine />}
		</div>
	)
}

Events.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ user }) => ({
	user: user.user
});

export default connect(mapStateToProps)(Events);
