import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { createPartner } from '../../../action/CompaniesActions';
import '../../dump/menuComponents/menuComponents.sass'
import CustomButton from '../../CustomButton';

const PartnerAddContainer = (props) => {

    const { user, toggleLoader } = props;
    const user_role = user.roles[0];

    const [data, setData] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: ''
    })
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false
    })
    const [errMessage, setErrMessage] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: '',
        billing_date: ''
    })
    const [companySaved, setCompanySaved] = useState(false)
    const [open, setOpen] = useState(false)
    const [createdPartner, setCreatedPartner] = useState('')

    const cancel = () => {
        const data = {
            company_name: '',
            company_city: '',
            company_state: '',
            company_zipcode: '',
            company_address: '',
            company_country: '',
            billing_date: '',
            live_active: true,
            cutoff_when_overage: true,
            storage_active: true,
            trace_active: true
        };

        setCompanySaved(false)
        setData(data)
        setOpen(false)
    }

    const sendForm = (e) => {
        e.preventDefault();
    }

    const savePartner = (e) => {
        const error = checkForm(data);

        if (!error) {
            toggleLoader(true);
            createPartner({ data }, user_role)
                .then((res, err) => {
                    toggleLoader(false);
                    console.log('!createPartner res', res);
                    const created_partner = res.data.response.company;
                    setCompanySaved(true)
                    setCreatedPartner(created_partner)
                    props.getPartners();
                    props.addPartner(created_partner.company_name);
                    toastr.success("Success", "Successfully added new partner");
                })
                .catch((error) => {
                    toggleLoader(false);
                    console.log('!createPartner error', error.response);
                    toastr.error('Unable to create Partner. Please try again later.')
                });
        }
    }

    const checkForm = (data) => {
        let error = false;
        let updatedErrMessage = { ...errMessage };
        if (data.company_name.includes('/')) {
            error = true;
            updatedErrMessage['company_name'] = 'Partner name cannot contain "/"'
        }
        else {
            for (let i of ["company_name", "company_city", "company_state", "company_zipcode", "company_address", "company_address2", "company_country", "company_phone_number"]) {
                if (data[i] == '') {
                    error = true;
                    updatedErrMessage[i] = 'Enter a ' + i.replace('company_', 'partner ');
                }
                else if (updatedErrMessage[i]) {
                    updatedErrMessage[i] = null;
                }
            }
        }
        setErrMessage(updatedErrMessage);
        return error;
    }

    const handleInputChange = (event, optional = null) => {
        const target = event.target,
            value = target.type === 'checkbox' ? target.checked : target.value,
            name = target.name

        const updatedData = { ...data }
        updatedData[name] = value;

        if (optional && value === '') {
            delete updatedData[name]
        }

        setData(updatedData);
    }

    const editPartner = () => {
        browserHistory.push({ pathname: "/partner/edit", state: { company: createdPartner } });
    }

    const toggleAddBlock = () => {
        setErrMessage({
            company_name: '',
            company_city: '',
            company_state: '',
            company_zipcode: '',
            company_address: '',
            company_country: '',
            billing_date: ''
        });

        setOpen(!open)
    }

    const { company_name, company_city, company_state, company_zipcode, company_address, company_country } = errMessage;

    if (companySaved) {
        return (
            <div className='add-asset-block'>
                <CustomButton variant="secondary-outline" onClick={cancel}>Add Another Partner</CustomButton>
                <div className="label-wrapper">
                    <label>The new partner, <b>{data.company_name}</b>, has been created.</label>
                </div>
                <div>
                    <CustomButton variant="primary" onClick={editPartner}>Edit Partner</CustomButton>
                </div>
            </div>
        )
    }

    return (
        <div className='add-asset-block'>
            <CustomButton variant="secondary-outline" onClick={toggleAddBlock}>{open ? 'Cancel' : 'Add a New Partner'}</CustomButton>
            {open &&
                <div className='edit-content-block'>
                    <form onSubmit={sendForm}>
                        <div className='two-column-grid'>
                            <div className={classnames({ 'has-error': company_name })}>
                                <label className="control-label">Name:</label>
                                <input type="text" name="company_name" className="vehicle-search" onChange={handleInputChange} maxLength={50} />
                                {company_name && <span className="help-block">{company_name}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_city })}>
                                <label className="control-label">City:</label>
                                <input type="text" name="company_city" className="vehicle-search" onChange={handleInputChange} />
                                {company_city && <span className="help-block">{company_city}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_state })}>
                                <label className="control-label">State:</label>
                                <input type="text" name="company_state" className="vehicle-search" onChange={handleInputChange} />
                                {company_state && <span className="help-block">{company_state}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_zipcode })}>
                                <label className="control-label">Zip Code:</label>
                                <input type="text" name="company_zipcode" className="vehicle-search" onChange={handleInputChange} />
                                {company_zipcode && <span className="help-block">{company_zipcode}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_address })}>
                                <label className="control-label">Address:</label>
                                <input type="text" name="company_address" className="vehicle-search" onChange={handleInputChange} />
                                {company_address && <span className="help-block">{company_address}</span>}
                            </div>

                            <div className='optional'>
                                <label className="control-label">Address 2: <span>(optional)</span></label>
                                <input type="text" name="company_address2" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
                            </div>

                            <div className={classnames({ 'has-error': company_country })}>
                                <label className="control-label">Country:</label>
                                <input type="text" name="company_country" className="vehicle-search" onChange={handleInputChange} />
                                {company_country && <span className="help-block">{company_country}</span>}
                            </div>

                            <div className='optional'>
                                <label className="control-label">Phone Number: <span>(optional)</span></label>
                                <input type="text" name="company_phone_number" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
                            </div>
                        </div>

                    </form>

                    <CustomButton variant="primary" onClick={savePartner}>Add Partner</CustomButton>
                </div>
            }
        </div>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company
    }),
    dispatch => ({
        addPartner: (partner) => {
            dispatch({ type: 'ADD_PARTNER_SUCCESS', payload: partner });
        },
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(PartnerAddContainer);
