import React from 'react'
import { Route, IndexRoute } from 'react-router'
import Cookies from 'js-cookie'

import Layout from '../component/Layout'
import EmptyLayout from '../component/EmptyLayout'
import LiveVideoLayout from '../component/LiveVideoLayout'
import Auth from '../component/Auth'
import LoginPage from '../component/dump/forms/login/LoginPage'
import ConfirmationPage from '../component/dump/forms/login/ConfirmationPage'
import RestorePasswordPage from '../component/dump/forms/login/RestorePasswordPage'
import ResetPasswordPage from '../component/dump/forms/login/ResetPasswordPage'
import NotFoundComponent from '../component/dump/NotFoundComponent'
import RestrictedContainer from '../component/RestrictedContainer'
import LegalNotice from '../component/dump/menuComponents/LegalNotice'
import LiveStreamsContainer from '../component/smart/menuComponentsContainer/LiveStreamsContainer'
import HomeAccess from '../component/smart/menuComponentsContainer/HomeAccess';
import ManageAssetsGroups from '../component/dump/menuComponents/ManageAssetsGroups'
import ManageUsers from '../component/dump/menuComponents/ManageUsers'
import ManageAssetsUserEditContainer from '../component/smart/menuComponentsContainer/ManageAssetsUserEditContainer'
import ChangePasswordContainer from '../component/smart/menuComponentsContainer/ChangePasswordContainer'
import EditAssetContainer from '../component/smart/menuComponentsContainer/EditAssetContainer'
import InstallationReportAssetContainer from '../component/smart/menuComponentsContainer/InstallationReportAssetContainer'
import AddGroupContainer from '../component/smart/menuComponentsContainer/AddGroupContainer'
import EditGroupContainer from '../component/smart/menuComponentsContainer/EditGroupContainer'
import CompanyContainer from '../component/smart/menuComponentsContainer/CompanyContainer'
import CompanyEditContainerTabs from '../pages/companies/CompanyEditContainerTabs'
import PartnerContainer from '../component/smart/menuComponentsContainer/PartnerContainer'
import PartnerEditContainer from '../component/smart/menuComponentsContainer/PartnerEditContainer'
import PartnerAddContainer from '../component/smart/menuComponentsContainer/PartnerAddContainer'
import FleetAdminModemContainer from '../pages/modems/FleetAdminModemContainer'
import ManageDriversPage from '../pages/drivers/ManageDriversPage'
import ReleasesPage from '../pages/releases/ReleasesPage'
import Firmware from '../pages/firmware/Firmware'
import AccountUserContainer from '../component/smart/account/AccountUserContainer'
import AccountChangePasswordContainer from '../component/smart/account/AccountChangePasswordContainer'
import AccountPasswordRecoveryContainer from '../component/smart/account/AccountPasswordRecoveryContainer'
import AccountSettings from '../component/smart/account/AccountSettings'
import AccountFleetSettings from '../component/smart/account/AccountFleetSettings'
import PartnerWhitelabelSettings from '../pages/partners/whitelabel/PartnerWhitelabelSettings'
import LiveVideoContainer from '../component/smart/menuComponentsContainer/LiveVideoContainer'
import LiveVideoErrorContainer from '../component/smart/menuComponentsContainer/LiveVideoErrorContainer'
import AdvancedPlayerOpenClip from '../component/smart/permalinks/AdvancedPlayerOpenClip'
import Reports from '../component/dump/menuComponents/Reports'
import GeotabCredentialsContainer from '../component/smart/geotab/GeotabCredentialsContainer'
import WebfleetCredentialsContainer from '../component/smart/geotab/WebfleetCredentialsContainer'
import PatternsEventContainer from '../component/smart/patterns/PatternsEventContainer'
import PatternsEventIdContainer from '../component/smart/patterns/PatternsEventIdContainer'
import PatternsDriverIdContainer from '../component/smart/patterns/PatternsDriverIdContainer'
import PatternsWikiLinks from '../component/smart/patterns/PatternsWikiLinks'
import PatternsDeviceInstalledReport from '../component/smart/patterns/PatternsDeviceInstalledReport'
import PatternsUnknowndriverDevice from '../component/smart/patterns/PatternsUnknowndriverDevice'
import PatternsPartnerRedirectContainer from '../component/smart/patterns/PatternsPartnerRedirectContainer'
import SupportContainer from '../component/smart/menuComponentsContainer/SupportContainer'
import FleetAdminServerStatsContainer from '../component/smart/menuComponentsContainer/FleetAdminServerStatsContainer'
import AdminSingleActions from '../component/smart/menuComponentsContainer/AdminSingleActions'
import Events from '../component/dump/menuComponents/Events'
import ManageVehicles from '../component/smart/menuComponentsContainer/ManageVehicles'

function loggedIn() {
	return new Auth().isUserAuthenticated();
}

function enterUser(nextState, replace) {
	// console.log('enterUser');
}

function requireWebfleetAuth(nextState, replace) {
	// console.log('requireAuth1: ', nextState)
	// console.log('requireAuth2: ', replace)
	// Object.keys(localStorage).map(k => console.log('' + k + ': ' + localStorage.getItem(k)))
	if (nextState.location.query.ex) {
		new Auth().authenticateUser(true, 1, 1);
		location.href = nextState.location.pathname
	}
}

function requireAuth(nextState, replace) {
	// console.log('requireAuth1: ', nextState)
	// console.log('requireAuth2: ', replace)
	// console.log('requireAuth3: ', Cookies.get())

	if (nextState.location.query.ex) {
		new Auth().authenticateUser(true, 1, 1);
		location.href = nextState.location.pathname
	}
	else {
		if (!loggedIn()) {
			replace({
				pathname: '/login'
			})
		}
	}
}

export default (
	<Route>
		<Route component={EmptyLayout}>
			<Route path="/login" component={LoginPage} />
			<Route path="/restore-password" component={RestorePasswordPage} />
			<Route path="/reset-password" component={ResetPasswordPage} />
			<Route path="/not-found" component={NotFoundComponent} />
			<Route path="/mygeotab/credentials/:provider/:companyHash" component={GeotabCredentialsContainer} />
			<Route path="/webfleet/credentials/:companyHash" component={WebfleetCredentialsContainer} />
			<Route path="/confirmation" component={ConfirmationPage} />
			{/* FWSD-4893, redirect from Partner portal to RL company, vehicle and profile page */}
			<Route path="/permalink/partner/:page_type/:company_id/:token" component={PatternsPartnerRedirectContainer} />
			<Route path="/permalink/partner/:page_type/:company_id/:id/:token" component={PatternsPartnerRedirectContainer} />
		</Route>
		<Route component={LiveVideoLayout} onEnter={requireWebfleetAuth}>
			<Route path="/live/stream/:deviceId" component={LiveVideoContainer} />
			<Route path="/live/stream/:deviceId/error" component={LiveVideoErrorContainer} />
		</Route>
		<Route component={LiveVideoLayout} onEnter={requireAuth}>
			<Route path="/live/video/:deviceId" component={LiveVideoContainer} />
			<Route path="/live/video/:deviceId/error" component={LiveVideoErrorContainer} />
		</Route>
		<Route exact path="/event/video/:eventId" component={AdvancedPlayerOpenClip} onEnter={requireAuth} />
		<Route exact name="Home" path="/" component={Layout} onEnter={requireAuth}>
			<IndexRoute exact name="Home" component={HomeAccess} />
			<Route path="support" component={SupportContainer} />
			<Route path="legal-notice" component={LegalNotice} />
			<Route path="managevehicles" component={ManageVehicles} />
			<Route path="managevehicles/edit" component={EditAssetContainer} />
			<Route path="managevehicles/edit/:id" component={EditAssetContainer} />
			<Route path="managevehicles/installation/report" component={InstallationReportAssetContainer} />
			<Route path="managegroups" component={ManageAssetsGroups} />
			<Route path="managegroups/add" component={AddGroupContainer} />
			<Route path="managegroups/edit" component={EditGroupContainer} />
			<Route path="manageusers" component={ManageUsers} onEnter={enterUser} />
			<Route path="manageusers/edit" component={ManageAssetsUserEditContainer} />
			<Route path="/account/settings" component={AccountSettings} />
			<Route path="/account/change-password" component={AccountChangePasswordContainer} />
			<Route path="/account/password-recovery" component={AccountPasswordRecoveryContainer} />
			<Route authorize={['system_admin']} component={RestrictedContainer}>
				<Route path="/fleet-admin/server-stats" component={FleetAdminServerStatsContainer} />
				<Route path="/single-actions" component={AdminSingleActions} />
				<Route path="/fleet-admin/releases" component={ReleasesPage} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'partner']} component={RestrictedContainer}>
				<Route path="manageusers/change-password" component={ChangePasswordContainer} />
				<Route path="/fleet-admin/modem" component={FleetAdminModemContainer} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'partner', 'fleet_manager']} component={RestrictedContainer}>
				<Route path="/account/fleet-settings" component={AccountFleetSettings} />
			</Route>
			<Route authorize={['system_admin']} component={RestrictedContainer}>
				<Route path="/partner/whitelabel" component={PartnerWhitelabelSettings} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'partner', 'sales_demo']} component={RestrictedContainer}>
				<Route path="/company" component={CompanyContainer} />
				<Route path="/company/edit" component={CompanyEditContainerTabs} />
			</Route>
			<Route authorize={['system_admin', 'customer_service']} component={RestrictedContainer}>
				<Route path="/partner" component={PartnerContainer} />
				<Route path="/partner/edit" component={PartnerEditContainer} />
				<Route path="/partner/add" component={PartnerAddContainer} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'fleet_manager', 'group_manager', 'partner', 'user', 'partner_view', 'sales_demo', 'storage_manager', 'storage_user', 'custom_user', 'custom_partner']} component={RestrictedContainer}>
				<Route path="/event" component={Events} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'fleet_manager', 'group_manager', 'partner', 'user', 'partner_view', 'sales_demo', 'storage_manager', 'storage_user', 'custom_user', 'custom_partner']} component={RestrictedContainer}>
				<Route path="/account" component={AccountUserContainer} />
				<Route path="/livestreams" component={LiveStreamsContainer} />
				<Route path="reports" component={Reports} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'partner', 'partner_view']} component={RestrictedContainer}>
				<Route path="/fleet-admin/firmware" component={Firmware} />
			</Route>
			<Route authorize={['system_admin', 'customer_service', 'fleet_manager', 'fleet_maintainer', 'partner', 'partner_view', 'sales_demo', 'custom_user', 'custom_partner']} component={RestrictedContainer}>
				<Route path="managedrivers" component={ManageDriversPage} />
			</Route>
			<Route path="/patterns/event/:device_id/:timestamp" component={PatternsEventContainer} />
			<Route path="/patterns/events/:event_id/:event_type" component={PatternsEventIdContainer} />
			<Route path="/patterns/drivers/:driver_trip_id" component={PatternsDriverIdContainer} />
			<Route path="/patterns/wiki/:linkType" component={PatternsWikiLinks} />
			<Route path="/patterns/device/installed/report/:report_id" component={PatternsDeviceInstalledReport} />
			<Route path="/patterns/unknowndriver/device/:device_id/:timestamp" component={PatternsUnknowndriverDevice} />
		</Route>
	</Route>
)
