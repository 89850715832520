import React, { useState, useEffect } from 'react';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';

import { getDeviceById } from '../../../action/DeviceActions';

const PatternsUnknowndriverDevice = (props) => {
	const { toggleLoader } = props;

	const [error, setError] = useState('');

	useEffect(() => {
		getDevice();
	}, []);

	const getDevice = () => {
		const { params, user, company_collection, updateCompany, partnerList } = props
		const deviceId = params.device_id
		const userRole = user.roles[0]

		console.log('partnerList: ', partnerList)

		getDeviceById(deviceId, userRole)
			.then((res, err) => {
				toggleLoader(true);
				console.log('!getDevice res: ', res, err)

				const { device } = res.data.response

				updateCompany({
					name: device.company_name,
					partner: device.partner_name,
					company_id: device.company_id,
				});

				browserHistory.push({
					pathname: '/',
					state: {
						unknownDriver: {
							device_id: params.device_id,
							timestamp: moment(params.timestamp, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
						},
						type: 'redirectFromUnknownDrivers',
					},
				})
			})
			.catch((error) => {
				console.log('!getDevice error: ', error.response, error)

				let _error = JSON.stringify(error);
				if (error.response && error.response.data.response.error) {
					_error = error.response.data.response.error
				}
				setError(_error);
			})
	}

	return (
		<article>
			{error
				&& (
					<div>
						<h3>Error 404!</h3>
						<div>{error}</div>
						<div>
							Go back to
							{' '}
							<Link to="/" className="btn">Home</Link>
						</div>
					</div>
				)}
		</article>
	)
}

export default connect(
	state => ({
		user: state.user.user
	}),
	dispatch => ({
		updateCompany: (company) => {
			dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(PatternsUnknowndriverDevice);
