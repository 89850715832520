import React, { useState, useRef } from 'react'
import { MAP } from 'react-google-maps/lib/constants'
// import { withGoogleMap, GoogleMap, Polyline, TrafficLayer, Marker } from 'react-google-maps'
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, TrafficLayer, Marker, InfoWindow } from "react-google-maps"
import { connect } from 'react-redux'
import { default as MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import _ from 'lodash'
import moment from 'moment'

import MarkerNode from './MarkerNode'
import MarkerAlertNode from '../TripsHistory/MarkerAlertNode'
import TripSpeedEvents from '../TripsHistory/TripSpeedEvents'
import TripTimeLocation from '../TripsHistory/TripTimeLocation'
import { googleMapStylesList } from '../../../component/helpers/constants'

const MapControl = (props) => {
	const { children } = props;

	const [flag, setFlag] = useState(true);

	const addToMap = (root) => {
		const { mapHolderRef, controlPosition } = props;
		if (flag && mapHolderRef) {
			mapHolderRef.context[MAP].controls[controlPosition].push(root);
			setFlag(false);
		}
	}

	return (<div ref={addToMap}>{children}</div>);
}

const SimpleMapExampleGoogleMap = compose(
		withProps({
		/**
		 * Note: create and replace your own key in the Google console.
		 * https://console.developers.google.com/apis/dashboard
		 * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
		 */
			googleMapURL:
				// "https://maps.googleapis.com/maps/api/js?key=AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q&v=3.exp&libraries=geometry,drawing,places",
				"https://maps.googleapis.com/maps/api/js?v=3.41&libraries=geometry,drawing,places&key=AIzaSyABDYlC67NC9TFAUxh-wZQKqt1qNiovg54",
			loadingElement: <div style={{ height: `100%` }} />,
			// containerElement: <div style={{ height: `400px` }} />,
			// mapElement: <div style={{ height: `100%` }} />
		}),
		withScriptjs,
		withGoogleMap
  	)
  	((props) => {
	const { userRole, liveVideoUserAccess, speed_unit, showLiveTraffic, onClickLiveTraffic, onClickSnapRoad, markers, polygons, tripsGPS, tripsGPSSnapRoad, tripsEvents, activeTreeItem, showSnapRoad, selectedTrip, _map } = props
	let polylines = (showSnapRoad && tripsGPSSnapRoad.length && tripsGPSSnapRoad) || (tripsGPS.length && tripsGPS) || polygons || [];
	const lineSymbol = {
		path: 'M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z',
		fillColor: '#fff',
		fillOpacity: 0.6,
		strokeWeight: 0.5,
		scale: 0.4,
		anchor: new google.maps.Point(12, 0),
	}
	const polylineOptions = {
		strokeColor: '#4268A8',
		strokeOpacity: 1.0,
		strokeWeight: 5,
		icons: [
			{
				icon: lineSymbol,
				repeat: '30px',
			},
		],
	}

	// let tripTimeRef = useRef(null)
	const [timeLocation, setTimeLocation] = useState(false);

	const getTripTime = (e) => {
		// console.log('getTripTime e: ', e)
		let minObj = null
		let distance = null
		props.tripsGPS[0].polygon.forEach((item) => {
			const { lat, lng } = item
			// const pos1 = new google.maps.LatLng({lat, lng})
			distance = google.maps.geometry.spherical.computeDistanceBetween({lat, lng}, e.latLng)
			// console.log('distance: ', distance)
			// calculates smallest distance between hovered point and each of points of poligon. The "smallest distance" polygon item contains needed timestamp. 
			if (minObj === null || distance < minObj.distance) {
				minObj = { ...item, distance }
			}
		})

		const ts =  moment.utc(minObj.timestamp).format('HH:mm:ss a') || 'NO TIME'
		setTimeLocation({ position: e.latLng, ts, distance: minObj.distance })
	}

	return (
		<GoogleMap
			mapId="e582b30b6a56802a"
			// ref={props.onMapMounted}
			ref={props._map}
			zoom={props.zoom}
			center={props.center}
			onZoomChanged={props.onZoomChanged}
			onMapTypeIdChanged={props.onMapTypeIdChanged}
			mapTypeId={props.maptype}
			options={{
				minZoom: 2,
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.RIGHT_TOP,
				},
				scaleControl: true,
				scaleControlOptions: {
					position: google.maps.ControlPosition.RIGHT_TOP,
				},
				streetViewControl: false,
				styles: googleMapStylesList.default_map, // FWSD-3757, FWSD-4457
			}}
			// defaultZoom={8}
			// defaultCenter={{ lat: -34.397, lng: 150.644 }}
		>
			{
				props.tripsGPS.map((polygon) => {
					// console.log('DROW Polyline polygon: ', polygon)
					return (
						<Polyline
							key={polygon.starttimestamp}
							options={polylineOptions}
							path={[...polygon.polygon]}
							geodesic
							onMouseOver={getTripTime}
							onMouseMove={getTripTime}
							// onMouseMove={e => setTimeLocation(e.latLng)}
							onMouseOut={() => setTimeLocation(false)}
						/>
					)
				})
			}

			{timeLocation && (
				<TripTimeLocation
					timeLocation={timeLocation}
				/>
			)}

			<TripSpeedEvents
				device_id={activeTreeItem && activeTreeItem.device_id}
			/>

			{polylines.length > 0 && polylines[0].polygon.length > 0
				&& (
					<Marker
						position={{ lat: polylines[0].polygon[0].lat, lng: polylines[0].polygon[0].lng }}
						icon={{
							url: '/images/ico/marker_start.svg',
							scaledSize: new google.maps.Size(25, 25),
						}}
						title="Start Trip"
						zIndex={10000}
						opacity={1}
					/>
				)
			}
			{/* {polylines.length > 0  && (
				<Marker
					position={{ lat: 40.6045455932617, lng: -73.9306869506836 }}
					zIndex={10000}
					opacity={1}
				/>
			)} */}
			{
				// FWSD-4032
				// FOR LIVE TRIP ONLY: Remove the B on the map to indicate the end of the trip. Since the trip hasn’t stopped, the B should be removed.
				polylines.length > 0 && polylines[0].polygon.length > 0 && selectedTrip && !selectedTrip.isLiveTrip
				&& (
					<Marker
						position={{ lat: polylines[0].polygon[polylines[0].polygon.length - 1].lat, lng: polylines[0].polygon[polylines[0].polygon.length - 1].lng }}
						icon={{
							url: '/images/ico/marker_stop.svg',
							scaledSize: new google.maps.Size(25, 25),
						}}
						title="Stop Trip"
						zIndex={10000}
						opacity={1}
					/>
				)
			}

			{tripsEvents.length > 0 && (
				tripsEvents.map((item) => {
					return (
						<MarkerAlertNode
							key={item.id}
							eventId={item.id}
							eventType={item.event_type}
							position={item.position}
							timestamp={item.timestamp}
						/>
					)
				})
			)}

			<MarkerClusterer
				averageCenter
				enableRetinaIcons
				gridSize={40}
				maxZoom={13}
				minimumClusterSize={3}
			>
			{
				markers.map(marker => {
					const location = { lat: marker.position?.lat, lng: marker.position?.lng }
					const isActive = activeTreeItem && activeTreeItem.device_id === marker.device_id;
					let visible_icon = marker.visible_icon /* || true */
					if ((!marker.position.lat * 1 && !marker.position.lng * 1)) {
						visible_icon = false
					}

					// if (props.singleTrucks.length && !marker.entry.single_truck) {
					// 	return false
					// }

					//* Does not return marker and cluster with empty data
					if (!visible_icon) {
						return false
					}

					const img_url = '/images/vehicle_icon/' + marker.icon_name + '_' + marker.icon_direction + (isActive ? '_active' : '') + '.png'
					const icon = {
						url: img_url,
					}

					let zIndex = google.maps.Marker.MAX_ZINDEX
					if (isActive) {
						zIndex = google.maps.Marker.MAX_ZINDEX + 1
					}

					return (
						<MarkerNode
							key={marker.device_id}
							map={_map}
							userRole={userRole}
							location_lat={location.lat}
							location_lng={location.lng}
							icon_url={icon.url}
							visible_icon={visible_icon}
							zIndex={zIndex}
							speed_unit={speed_unit}
							liveVideoUserAccess={liveVideoUserAccess}

							device_id={marker.device_id}
							name={marker.name}
							showInfoWindow={marker.showInfoWindow}
							icon_direction={marker.icon_direction}
							driver_name={marker.driver_name}

							// online_gps={marker.entry.online_gps}
							online={marker.entry.online}
							status={marker.entry.status}

							spd={marker.infoData.spd}
							speed={marker.infoData.speed || 0}
							timestamp={marker.infoData.timestamp}
							lan={marker.infoData.lan}
							lat={marker.infoData.lat}
							lon={marker.infoData.lon}
							lng={marker.infoData.lng}
							ignition={marker.infoData.key}
							dir={marker.infoData.dir}
							address={marker.infoData.address}
							ignitionTimeChange={marker.ignitionСhange && marker.ignitionСhange.ts ? marker.ignitionСhange.ts : ''}

							onSelectMarker={props.onSelectMarker}
							// onOpenLive={props.onOpenLive}
							onClickAddress={props.onClickAddress}
						/>
					)
				})
			}
			</MarkerClusterer>

			
				{/* // <Marker position={{lat: 40.6045455932617, lng: -73.9306869506836}} />
				// position={{ lat: polylines[0].polygon[polylines[0].polygon.length - 1].lat, lng: polylines[0].polygon[polylines[0].polygon.length - 1].lng }}
				// 		icon={{
				// 			url: '/images/ico/marker_stop.svg',
				// 			scaledSize: new google.maps.Size(25, 25),
				// 		}}
				// 		title="Stop Trip"
				// 		zIndex={10000}
				// 		opacity={1} */}
				{/*tripsEvents.map((item) => {
					// debugger
					return (
						<MarkerAlertNode
							key={item.id}
							eventId={item.id}
							eventType={item.event_type}
							position={item.position}
							timestamp={item.timestamp}
						/>
					)
				})*/}
			{/* // )} */}

			{showLiveTraffic && (
				<TrafficLayer autoUpdate />
			)}

			<MapControl
				mapHolderRef={props._map.current}
				controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
			>
				<button className="btn-traffic" onClick={onClickLiveTraffic}>
					Traffic
				</button>
				{userRole === 'system_admin' && tripsGPS.length > 0 && (
					<button className={`btn-traffic ${showSnapRoad ? 'active' : ''}`} onClick={onClickSnapRoad}>
						Snap Road
					</button>
				)}
			</MapControl>
		</GoogleMap>
	)
})

const mapStateToProps = ({ dashboardData, user }) => ({
	selectedTrip: dashboardData.selectedTrip,
	tripsGPS: dashboardData.tripsGPS,
	tripsEvents: dashboardData.tripsEvents,
	tripAlertsMarkers: dashboardData.tripAlertsMarkers,
	tripsGPSSnapRoad: dashboardData.tripsGPSSnapRoad,
	showSnapRoad: dashboardData.showSnapRoad,
});

export default connect(mapStateToProps)(SimpleMapExampleGoogleMap);
