import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { Button, Modal } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'

import '../../dump/menuComponents/menuComponents.sass'
import 'react-toggle/style.css'
import 'react-dual-listbox/lib/react-dual-listbox.css'

import NotificationsModal from '../modals/NotificationsModal'
import DisableSMSModal from '../modals/DisableSMSModal'
import NotificationToggle from './NotificationToggle'
import { getCompanyByName, updateCompany } from '../../../action/CompaniesActions'
import { updateUserNotifications } from '../../../action/AccountActions'
import { updateFleetUser } from '../../../action/ClientActions'
import { isInternalUser } from '../../helpers/constants'
import CustomButton from '../../CustomButton'

const roadEventNotifications = {
	title: 'Event Notifications',
	subcategories: [
		{
			title: 'Events',
			notifsArr: [
				{
					name: 'alert_critical_gsensor',
					title: 'High G-Force',
					description: 'Sends notification every time a camera generates a High G-Force event.',
				},
				{
					name: 'alert_speed',
					title: 'Max Speed',
					description: 'Sends notification every time a camera exceeds the Max Speed set in its profile.',
				},
				{
					name: 'flood_notification',
					title: 'Event Flood',
					description: 'Sends notification when a camera is generating an excessive amount of events. Camera event setting may need adjustment or a camera fallen off the windshield.',
				},
				{
					name: 'harshbraking',
					title: 'Harsh Braking',
					description: 'Sends notification every time a camera generates a Harsh Braking event.',
				},
				{
					name: 'harshaccel',
					title: 'Harsh Acceleration',
					description: 'Sends notification every time a camera generates a Harsh Acceleration event.',
				},
				{
					name: 'alert_sensor',
					title: 'Driver',
					description: 'Sends notification every time a camera generates a Driver event.',
				},
				{
					name: 'input_sensor',
					title: 'Aux Input',
					description: 'Sends notification every time a camera generates an event by an auxillary device.',
				},
			],
		},
		{
			title: 'Speeding Alerts',
			notifsArr: [
				{
					name: 'low_speeding',
					title: 'Low Speeding',
					description: 'Sends notification when vehicle speed is +1 to +5 over the posted speed limit.',
				},
				{
					name: 'moderate_speeding',
					title: 'Moderate Speeding',
					description: 'Sends notification when vehicle speed is +6 to +10 over the posted speed limit.',
				},
				{
					name: 'high_speeding',
					title: 'High Speeding',
					description: 'Sends notification when vehicle speed is +11 to +15 over the posted speed limit.',
				},
				{
					name: 'severe_speeding',
					title: 'Severe Speeding',
					description: 'Sends notification when vehicle speed is +16 and above over the posted speed limit.',
				},
			],
		},
		{
			title: 'DMS Events',
			notifsArr: [
				{
					name: 'distraction',
					title: 'Distraction',
					description: 'Sends notification every time a camera detects a Distraction event.',
				},
				{
					name: 'drowsiness',
					title: 'Drowsiness/Head Down',
					description: 'Sends notification every time a camera detects a Drowsiness/Head Down event.',
				},
				{
					name: 'phone_use',
					title: 'Phone Use/Hand to Head',
					description: 'Sends notification every time a camera detects a Phone Use/Hand to Head event.',
				},
				{
					name: 'yawning',
					title: 'Yawning (DV6 Only)',
					description: 'Sends notification every time a camera detects a Yawning event.',
				},
				// TO DO: FWSD-6150, uncommit after the FWSD-6152 finishing, the item No.4
				{
					name: 'smoking',
					title: 'Smoking',
					description: 'Sends notification every time a camera detects a Smoking event.',
				},
				// TO DO: FWSD-6496, uncommit after the FWSD-6152 finishing, the item No.4
				{
					name: 'seatbelt',
					title: 'Seatbelt',
					description: 'Sends notification every time a camera detects a Seatbelt event.',
				},
			],
		},
		{
			title: 'ADAS Events',
			notifsArr: [
				{
					name: 'adas_ldw',
					title: 'Lane Departure (Mobileye Only)',
					description: 'Sends notification every time a camera detects an Lane Departure event.',
				},
				{
					name: 'adas_fcw',
					title: 'Forward Collision',
					description: 'Sends notification every time a camera detects a Forward Collision event.',
				},
				{
					name: 'adas_pcw',
					title: 'Pedestrian Warning (Mobileye Only)',
					description: 'Sends notification every time a camera detects a Pedestrian Warning event.',
				},
				{
					name: 'tailgating',
					title: 'Tailgating (DV6 Only)',
					description: 'Sends notification every time a camera detects a Tailgating event.',
				},
				{
					name: 'adas_hw',
					title: 'Headway Monitoring (DV6 Only)',
					description: 'Sends notification every time a camera detects a Headway event.',
				},
			],
		},
	],
};
const cameraStatusNotifications = [
	// {
	// 	name: 'alert_sd_error',
	// 	title: 'SD Card',
	// 	description: 'Sends notification if there is a potential issue with the SD card in a camera.',
	// 	subtitle: 'Under Improvement for DV6',
	// 	disabled: true,
	// },
	{
		name: 'alert_camera_failure',
		title: 'Channel Status',
		description: "Sends notification if there is a potential issue with one of the camera's channels.",
	},
	// {
	// 	name: 'alert_unclean_shutdown',
	// 	title: 'Power Loss',
	// 	description: 'Sends notification if an unexpected powerloss occurs in a camera. This can be due to loose power connection or removal of camera without properly shutting down.',
	// 	subtitle: 'Under Improvement for DV6',
	// 	disabled: true,
	// },
	// {
	// 	name: 'alert_high_temperature',
	// 	title: 'Overheat',
	// 	description: 'Sends notification if a camera shut down due to excessively high temperature.',
	// },
	// {
	// 	name: 'alert_no_sd_card',
	// 	title: 'No SD Card',
	// 	description: 'Sends notification if a camera reports no SD card present.',
	// },
	// FWSD-7019
	// {
	// 	name: 'alert_camera_errors',
	// 	title: 'Camera Errors',
	// 	description: "Sends notifications when errors are detected for cameras in your fleet.",
	// },
];
const fleetStatusNotifications = [
	{
		name: 'event_breakdown',
		title: 'Event Summary',
		description: 'Sends a daily summary of the events generated by the cameras in your fleet.',
	},
	{
		name: 'alert_inactive_vehicles',
		title: 'Inactive Vehicles',
		description: 'Sends notification when a camera has been inactive for a number of days. Inactive window can be set from 1 to 5 days.',
	},
	{
		name: 'audit_notification',
		title: 'Fleet Updates',
		description: 'Sends a daily summary of the changes made to the fleet.',
	},
	{
		name: 'reformat_reminder',
		title: 'Reformat Reminder',
		description: 'Sends a reminder to reformat an SD card in a camera every six months.',
	},
	{
		name: 'recording_status_notifs',
		title: 'Recording Status',
		description: 'Sends a fleet-wide report notifying if any devices have had recording issues.',
	},
	{
		name: 'unidentified_drivers',
		title: 'Unknown Driver',
		description: 'Sends a notification when a camera does not identify a driver. This can only be used if your fleet has Driver ID enabled.',
	},
	// {
	// 	name: 'data_usage_notification',
	// 	title: 'Vehicle Data Usage Allowance',
	// 	description: 'Sends a notification when a camera is nearing or has exceeded its monthly data allowance.',
	// },
	{
		name: 'alert_idling',
		title: 'Vehicle Idling',
		description: 'Sends a notification when idling is detected for a vehicle.',
	},
	{
		name: 'data_pool_usage',
		title: 'Data Pool Usage',
		description: 'Sends a notification about data pool usage on a periodic basis.',
		// access: ['system_admin', 'customer_service', 'partner', 'fleet_manager', 'notification_recipient', 'custom_user']
		access: ['system_admin', 'customer_service']
	},
	{
		name: 'data_pool_warning',
		title: 'Data Pool Warning',
		description: 'Sends a notification when data pool usage has exceeded certain thresholds.',
		// access: ['system_admin', 'customer_service', 'partner', 'fleet_manager', 'notification_recipient', 'custom_user']
		access: ['system_admin', 'customer_service']
	},
	{
		name: 'device_high_data_usage',
		title: 'Device High Data Usage',
		description: 'Sends a notification when device has used 30% or more of the data pool for the billing cycle.',
		// access: ['system_admin', 'customer_service', 'partner', 'fleet_manager', 'notification_recipient', 'custom_user']
		access: ['system_admin', 'customer_service']
	},
];

const adminNotifications = [
	{
		name: 'daily_recording_status_notifs',
		title: 'Daily Recording Status',
		description: 'Sends a daily summary of cameras where a gap in recording was detected.',
	},
	{
		name: 'daily_new_cameras_notifs',
		title: 'Global Fleet Updates',
		description: 'Sends a daily report of cameras that were newly added to Rosco Live.',
	},
];

const AccountNotificationsContainer = (props) => {

	const { company, readOnly, user, notifications, userEmail, userRole, loaderModal, toggleLoaderModal } = props;

	const [notificationsModal, setNotificationsModal] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState(null);
	const [selectedTitle, setSelectedTitle] = useState(null);
	const [disableSMSModal, setDisableSMSModal] = useState(false);
	const [dmsCutoffModal, setDmsCutoffModal] = useState(false);
	const [selectedName, setSelectedName] = useState(null);
	const [dms_notifs_cutoff, setDms_notifs_cutoff] = useState(null);

	useEffect(() => {
		if (isInternalUser(userRole)) {
			if (company.name && company.partner) {
				getCompanyByName(userRole, company.name, company.partner, 'dms_notifs_cutoff')
					.then(res => {
						setDms_notifs_cutoff(res.data.response.company.dms_notifs_cutoff);
					})
			}
		}
	}, [company.name]);

	const showNotificationsModal = (e, title) => {
		const name = e.target.name;
		const selectedNotification = name !== 'input_sensor' ? notifications[name] : [notifications.inputsensor1, notifications.inputsensor2, notifications.inputsensor3, notifications.inputsensor4]
		setNotificationsModal(true)
		setSelectedNotification(selectedNotification)
		setSelectedName(name)
		setSelectedTitle(title)
	};

	const updateNotifications = (data) => {
		const { phone_number, sms, email } = data;

		let reqArray = [];
		reqArray.push(updateUserNotifications(userEmail, data))
		if (sms && phone_number && phone_number !== user.phone_number) {
			let updatedSettings = {
				user_email: user.email,
				user_role: user.roles[0],
				data: {
					phone_number: phone_number,
				},
			}
			reqArray.push(updateFleetUser(updatedSettings))
		}
		toggleLoaderModal(true);
		Promise.all(reqArray)
			.then(res => {
				if (sms) {
					ReactGA.event({
						category: user.roles[0],
						action: 'Subscribe to SMS',
						label: selectedTitle,
					})
				}
				if (email) {
					ReactGA.event({
						category: user.roles[0],
						action: 'Subscribe to Email',
						label: selectedTitle,
					})
				}
				toastr.success('Successfully updated notification settings.')
				toggleLoaderModal(false);
				if (res[1] && res[1].data && !readOnly) {
					props.updateUser(res[1].data.response.user);
				}
				setNotificationsModal(false)
				props.getNotifications()
			})
			.catch(err => {
				toastr.error('There was an issue updating your notification settings. Please try again later.')
				toggleLoaderModal(false);
				setNotificationsModal(false)
			})
	};

	const disableAllSMS = (notifsWithSMS) => {
		toggleLoaderModal(true)
		const data = {}
		for (let notif of Object.keys(notifsWithSMS)) {
			data[notif] = { ...notifsWithSMS[notif], ...{ sms: false, vehicles: [] } }
		}
		updateUserNotifications(props.userEmail, data)
			.then(res => {
				toastr.success('Successfully disabled SMS notifications.')
				toggleLoaderModal(false)
				setDisableSMSModal(false)
				props.getNotifications()
			})
			.catch(err => {
				toastr.error('Unable to disable SMS notifications. Please contact customer support.')
				toggleLoaderModal(false)
				setDisableSMSModal(false)
			})
	};

	const changeDMSCutoff = (agreed = null) => {
		if (agreed) {
			updateCompany({ data: { dms_notifs_cutoff: dms_notifs_cutoff }, company: { owner_company: company.partner, company_name: company.name } }, user.roles[0])
				.then(res => {
					toastr.success('Updated DMS Cutoff successfully.')
				})
				.catch(err => {
					toastr.error('Unable to update. Please try again later.')
				})
		}
		else {
			setDms_notifs_cutoff(-1)
		}
		setDmsCutoffModal(false)
	};

	// console.log('PROPS AccountNotificationsContainer: ', props)
	// console.log('STATE AccountNotificationsContainer: ', state)

	let recordingStatusDisabled = false;
	if (['system_admin', 'customer_service', 'partner', 'partner_view', 'sales_demo', 'installer'].indexOf(userRole) > -1) {
		recordingStatusDisabled = true;
	};

	if (!notifications) {
		return (<div></div>)
	};

	const auxInputEmail = notifications.inputsensor1 && (notifications.inputsensor1.email || notifications.inputsensor2.email || notifications.inputsensor3.email || notifications.inputsensor4.email);
	const auxInputSms = notifications.inputsensor1 && (notifications.inputsensor1.sms || notifications.inputsensor2.sms || notifications.inputsensor3.sms || notifications.inputsensor4.sms);

	const notifsWithSMS = {}
	if (!['storage_manager', 'storage_user'].includes(userRole)) {
		for (let notif of Object.keys(notifications)) {
			if (notifications[notif].sms) {
				notifsWithSMS[notif] = { ...notifications[notif] }
			}
		}
	}

	return (
		<section className="user-notifications">
			{!['storage_manager', 'storage_user'].includes(userRole) ? (
				<div className="notifications-container">
					<div className="title-flex">
						<h3 className="category-title">
							Event Notifications
						</h3>
						{user.email !== userEmail && !readOnly && Object.entries(notifsWithSMS).length > 0 && (
							<CustomButton variant="primary-outline" size="sm" onClick={() => setDisableSMSModal(true)}>
								Disable SMS
							</CustomButton>
						)}
					</div>
					{roadEventNotifications.subcategories.map(subcategory => (
						<div key={subcategory.title}>
							<h5 className="category-subtitle">
								{subcategory.title}
								{subcategory.title === 'DMS Events' && isInternalUser(userRole) && (
									<div className="dms-cutoff-select">
										DMS Notifications Cutoff
										<select
											value={dms_notifs_cutoff || (dms_notifs_cutoff === 0 ? 0 : -1)}
											onChange={(e) => {
												setDms_notifs_cutoff(e.target.value * 1);
												setDmsCutoffModal(true);
											}}
										>
											<option value="-1">None</option>
											<option value="5">5 minutes</option>
											<option value="30">30 minutes</option>
											<option value="60">1 hour</option>
											<option value="1440">24 hours</option>
											<option value="0">Never</option>
										</select>
									</div>
								)}
							</h5>
							<div className="two-column-grid with-margin notifications-grid">
								{subcategory.notifsArr.map(category => (
									<NotificationToggle
										userRole={userRole}
										{...category}
										readOnly={readOnly}
										key={category.name}
										email={category.name !== 'input_sensor' ? notifications[category.name].email : auxInputEmail}
										sms={category.name !== 'input_sensor' ? notifications[category.name].sms : auxInputSms}
										onChange={(e) => showNotificationsModal(e, category.title)}
									/>
								))}
							</div>
						</div>
					))}

					<h3 className="category-title">Camera Health Notifications</h3>
					<div className="two-column-grid with-margin notifications-grid">
						{cameraStatusNotifications.map(notification => (
							<NotificationToggle
								userRole={userRole}
								{...notification}
								readOnly={notification.disabled ? notification.disabled : readOnly}
								key={notification.name}
								email={!notification.disabled ? notifications[notification.name].email : ''}
								sms={!notification.disabled ? notifications[notification.name].sms : ''}
								onChange={(e) => showNotificationsModal(e, notification.title)}
							/>
						))}
					</div>

					<h3 className="category-title">Fleet Status Notifications</h3>
					<div className="two-column-grid with-margin notifications-grid">
						{fleetStatusNotifications.map(notification =>
							<NotificationToggle
								userRole={userRole}
								{...notification}
								readOnly={readOnly}
								key={notification.name}
								tooltip={(notification.title === 'Recording Status' && recordingStatusDisabled) && 'This report is not available for multi-company users. To receive this report, add a user to this company with fleet manager or lower permissions'}
								email={notifications[notification.name].email}
								sms={notifications[notification.name].sms}
								onChange={(e) => showNotificationsModal(e, notification.title)}
							/>,
						)}
					</div>
					{['system_admin', 'customer_service'].includes(userRole) && (
						<div>
							<h3 className="category-title">Admin Notifications</h3>
							<div className="two-column-grid with-margin notifications-grid">
								{adminNotifications.map(notification =>
									<NotificationToggle
										userRole={userRole}
										{...notification}
										readOnly={readOnly}
										key={notification.name}
										// tooltip={(notification.title === 'Recording Status' && recordingStatusDisabled) && 'This report is not available for multi-company users. To receive this report, add a user to this company with fleet manager or lower permissions'}
										email={notifications[notification.name].email}
										sms={notifications[notification.name].sms}
										onChange={(e) => showNotificationsModal(e, notification.title)}
									/>,
								)}
							</div>
						</div>
					)}
				</div>
			) : (
				<div className="notifications-container">

					<h3 className="category-title">Email Notifications</h3>
					<div className="two-column-grid with-margin notifications-grid">
						<div className="notification-wrapper">
							<label className="toggles">
								<span className="notification-title">Channel Failure</span>
								<span>
									<Toggle
										disabled={readOnly}
										name="alert_camera_failure"
										checked={!!notifications.alert_camera_failure}
										onChange={props.fillForm}
									/>
								</span>
							</label>
							<div className="description">Occurs when the device has a potential hardware issue</div>
						</div>
						<div className="notification-wrapper">
							<label className="toggles">
								<span className="notification-title">Inactive Vehicles</span>
								<span>
									<Toggle
										disabled={readOnly}
										name="alert_inactive_vehicles"
										checked={!!notifications.alert_inactive_vehicles}
										onChange={props.fillForm}
									/>
								</span>
							</label>
							<div className="description">Email is sent to user when vehicle has been inactive in excess of 5 days</div>
						</div>
						<div className="notification-wrapper">
							<label className="toggles">
								<span className="notification-title">Company Audit Notification</span>
								<span>
									<Toggle
										disabled={readOnly}
										name="audit_notification"
										checked={!!notifications.audit_notification}
										onChange={props.fillForm}
									/>
								</span>
							</label>
							<div className="description">This notification will be sent once per day.</div>
						</div>
					</div>
				</div>
			)}
			{notificationsModal && (
				<NotificationsModal
					notification={selectedNotification}
					selectedName={selectedName}
					selectedTitle={selectedTitle}
					hideNotificationsModal={() => setNotificationsModal(false)}
					updateNotifications={updateNotifications}
					userEmail={userEmail}
					readOnly={userEmail !== user.email}
				/>
			)}
			{disableSMSModal && (
				<DisableSMSModal
					hideDisableSMSModal={() => setDisableSMSModal(false)}
					disableAllSMS={() => disableAllSMS(notifsWithSMS)}
				/>
			)}
			{dmsCutoffModal && (
				<Modal
					show
					onHide={() => changeDMSCutoff(false)}
					className="notifications-modal modal-lg-size"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-lg">Change DMS Cutoff</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="notifications-modal-body modal-text">
							<span className="error-label">WARNING:</span> This will change the frequency of SMS notifications for ALL users within the company:
							<strong> {props.company.name}</strong>. Do you want to proceed?
						</div>
					</Modal.Body>
					<Modal.Footer>
						<CustomButton variant='delete' onClick={() => changeDMSCutoff(false)}>Cancel</CustomButton>
						<CustomButton variant='primary' onClick={() => changeDMSCutoff(true)}>Apply</CustomButton>
					</Modal.Footer>
					{loaderModal}
				</Modal>
			)}
		</section>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		updateUser: (user) => {
			dispatch({ type: 'GET_USER_SUCCESS', payload: user });
		},
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(AccountNotificationsContainer);
