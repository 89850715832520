/* eslint-disable1 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReactBootstrapSlider from 'react-bootstrap-slider'
import { Button, Dropdown } from 'react-bootstrap'
import classnames from 'classnames'
import axios from 'axios'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { isMobileOnly } from 'react-device-detect'
import _ from 'lodash'
import $ from 'jquery'
import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx"

import { getEventVideo } from '../../../action/AlertActions'
import { apiCallGet } from '../../../action/RouterActions'

import SimpleMapExampleGoogleMap from '../../AdvancedPlayer/SimpleMapExampleGoogleMap'
import OverlayTriggerNode from '../../AdvancedPlayer/OverlayTriggerNode'
import GsensorGraph from '../../AdvancedPlayer/GsensorGraph'

import { getDirection } from '../../helpers/labels'
import { addAuditLogFunc } from '../../helpers/helperAuditLog'
import CustomButton from '../../CustomButton'
import { videoPlayerIcons } from '../../helpers/iconHelpers';

import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import '../webplayer/webplayer.sass'

const loader = {
	position: 'fixed',
	display: 'block',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	opacity: '0.40',
	zIndex: 10000,
	background: "url('/images/roscovision-icon.png') no-repeat 50% calc(40vh - 23px) rgba(255, 255, 255, 0.5)",
	backgroundSize: '45px',
	textAlign: 'center',
}

let vid1;
let vid2;
let vid3;
let vid4;

const vidDeviationControl = () => {
	if (
		(!vid2.ended && Math.abs(vid1.currentTime - vid2.currentTime) > 0.2)
		|| (vid3 && !vid3.ended && Math.abs(vid1.currentTime - vid3.currentTime) > 0.2)
		|| (vid4 && !vid4.ended && Math.abs(vid1.currentTime - vid4.currentTime) > 0.2)
	) {
		cancelAnimationFrame(vidDeviationControl);

		$('#player-video video').each(function () {
			this.pause();
		});

		// set all 3 before new AnimationFrame is drawn.
		if (vid4) {
			vid4.currentTime = vid1.currentTime;
			vid3.currentTime = vid4.currentTime;
			vid2.currentTime = vid3.currentTime;
			vid1.currentTime = vid2.currentTime;
		} else if (vid3) {
			vid3.currentTime = vid1.currentTime;
			vid2.currentTime = vid3.currentTime;
			vid1.currentTime = vid2.currentTime;
		} else {
			vid2.currentTime = vid1.currentTime;
			vid1.currentTime = vid2.currentTime;
		}

		$('#player-video video').each(function () {
			this.play();
		});
	}

	if (vid1.paused || vid1.ended) {
		cancelAnimationFrame(vidDeviationControl);
	} else {
		requestAnimationFrame(vidDeviationControl);
	}
}

const AdvancedPlayerOpenClip = (props) => {
	const { params } = props;
	const winOpen = JSON.parse(localStorage.getItem('winOpen'))	
	const user = _.isEmpty(props.user) ? winOpen.user : props.user
	const { userRole, companyName, partnerName } = winOpen
	const { eventId } = params

	const [nopermalink, setNopermalink] = useState(true);
	const [loaderModal, setLoaderModal] = useState(false);
	const [windowHeight, setWindowHeight] = useState(560);
	const [windowWidth, setWindowWidth] = useState(900);
	// const [parentObj, setParentObj] = useState(null);
	const [video_urls, setVideo_urls] = useState([]);
	const [nvr_url, setNvr_url] = useState('');
	const [header, setHeader] = useState({});
	const [metadata, setMetadata] = useState([]);
	const [coordinates, setCoordinates] = useState({ lat: -34.397, lng: 150.644 });
	// const [maxCollection, setMaxCollection] = useState()
	const [direction, setDirection] = useState(0);
	const [dir, setDir] = useState('');
	const [state, setState] = useState({
		starttimestamp: '',
		timestamp: 0,
	})
	const [polyline, setPolyline] = useState([]);
	const [acc, setAcc] = useState([]);
	const [speed, setSpeed] = useState(0);
	// const [driver_access, setDriver_access] = useState(false);
	const [driver, setDriver] = useState('');
	const [meye, setMeye] = useState({
		error: false,
		failsafe: false,
		fcw: false,
		hw: 0,
		hwWarning: 0,
		ldw: true,
		ldwLeft: false,
		ldwRight: false,
		pedDz: false,
		pedForward: false,
		speedWarning: 0,
		tamper: false,
	});
	const [player_active_number, setPlayer_active_number] = useState(2);
	// player_active: null, //$('#player-video video')[0],
	const [single_active_player, setSingle_active_player] = useState(0);
	const [play, setPlay] = useState(false);
	// const [started, setStarted] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1.0);
	const [fps, setFps] = useState(24);
	const [value, setValue] = useState(0);
	const [max, setMax] = useState(null);
	const [show_brightness, setShow_brightness] = useState(false);
	const [show_contrast, setShow_contrast] = useState(false);
	const [volume_value, setVolume_value] = useState(0.5);
	const [videos, setVideos] = useState([
		{ brightness: 1, contrast: 1, audio: false },
		{ brightness: 1, contrast: 1, audio: false },
		{ brightness: 1, contrast: 1, audio: false },
		{ brightness: 1, contrast: 1, audio: false },
	]);
	const [showChannelsSelection, setShowChannelsSelection] = useState(false); // we display only one video tag and list of buttons to select channel
	const [showTooltipDoubleClick, setShowTooltipDoubleClick] = useState(false);
	const [playerFullScreen, setPlayerFullScreen] = useState(false)

	useEffect(() => {
		console.log('componentDidMount AdvancedPlayer props: ', props)

		// Only for iOS devices display channel selection to playback video because they can not to mutiple playback
		let isShowChannelsSelection = false
		if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
			isShowChannelsSelection = true
			setShowChannelsSelection(true)
		}
		// ***

		setLoaderModal(true);
		setWindowWidth(window.innerWidth - window.innerWidth * 0.2);
		setWindowHeight(window.innerHeight - window.innerHeight * 0.2);

		// FWSD-6003, Full Screen View
		document.addEventListener('fullscreenchange', onFullScreenChange)
		document.addEventListener('webkitfullscreenchange', onFullScreenChange)
		document.addEventListener('mozfullscreenchange', onFullScreenChange)
		document.addEventListener('MSFullscreenChange', onFullScreenChange)

		// const driverFeatureAccess = (driverRecognition !== 'manual' || ['system_admin'].indexOf(userRole) > -1)
		// if (driverFeatureAccess) {
		getEventDriver()
		// }

		getEventVideo({
			user_role: userRole,
			event_id: eventId,
			type: 'all',
			company_name: companyName,
			partner_name: partnerName,
		})
			.then((res, err) => {
				console.log('getEventVideo res: ', res, err)
				const { response } = res.data

				if (response.error) {
					setLoaderModal(false)
					toastr.error('An unexpected error occurred. Please try again later');
				} else {
					const { video_urls, nvr_url } = response
					const videosUpdate = videos;
					setNopermalink(false)
					setVideo_urls(video_urls)
					setNvr_url(nvr_url)
					// setDriver_access(response.hasOwnProperty('driver'))
					// setDriver(response.driver)

					// const maxCollection = []
					let playersLoaded = 0;

					if (isShowChannelsSelection) { // for iPhone with sinle video tag
						$($('#player-video video')[0]).attr('src', video_urls[player_active_number-1])
						$('#player-video video').off('loadeddata',setPlayerEvents)
						$('#player-video video').on('loadeddata',setPlayerEvents)
					}
					else { // for Android with multiple video tags
						video_urls.forEach((item, i) => {
							$($('#player-video video')[i]).attr('src', item); // .load();
						})
	
						// $('#player-video video').on('loadedmetadata', function(e){
						$('#player-video video').on('loadeddata', (e) => {
							if (hasAudio(e.target)) {
								const channelNumber = $(e.target).attr('data-channel')
								videosUpdate[channelNumber - 1].audio = true
								setVideos(videosUpdate)
							}
	
							// maxCollection.push({ videoObj: e.target, duration: e.target.duration });
							playersLoaded++;
							if (playersLoaded === video_urls.length) {
								// setPlayerEvents(maxCollection);
								setPlayerEvents();
							}
						});
					}					

					console.log('file', response.metadata_url);
					axios(response.metadata_url)
						.then((res, err) => {
							// console.log('metadata.json res:', res, err);

							let polyline = []
							let acc = []
							let metadata = res.data.metadata;

							metadata = _.orderBy(metadata, ['t']);

							const entry = _.find(res.data.metadata, function (item, i) {
								return item.gps && item.gps.lat && item.gps.lon;
							})

							// res.data.metadata.map((item, i) => {
							metadata.map((item, i) => {
								if (item.gps && item.gps.lat && item.gps.lon) {
									polyline.push({ lat: item.gps.lat, lng: item.gps.lon });
								}

								if (item.acc) {
									acc.push(item.acc);
								}
							});
							acc = _.orderBy(acc, ['t']);

							setHeader(res.data.header) // res.data.header
							setMetadata(metadata) // res.data.metadata
							setPolyline(polyline)
							setAcc(acc)
							// setStarttimestamp((res.data.header.date && res.data.header.startTime) ? moment.utc(res.data.header.date + ' ' + res.data.header.startTime).valueOf() : '')
							// setTimestamp((res.data.header.date && res.data.header.startTime) ? moment.utc(res.data.header.date + ' ' + res.data.header.startTime).valueOf() : 0)
							// setMax((moment(res.data.header.date+' '+res.data.header.endTime) - moment(res.data.header.date+' '+res.data.header.startTime))/1000)
							setState((prevState) => ({ 
								starttimestamp: (res.data.header.date && res.data.header.startTime) ? moment.utc(res.data.header.date + ' ' + res.data.header.startTime).valueOf() : '', 
								timestamp: (res.data.header.date && res.data.header.startTime) ? moment.utc(res.data.header.date + ' ' + res.data.header.startTime).valueOf() : 0, 
							}))

							if (entry) {
								setCoordinates({ lat: entry.gps.lat, lng: entry.gps.lon });
								setDir(getDirection(entry.direction));
								setDirection(entry.direction);
								// setTimestamp(entry.ts);
							}

						})
						.catch((error) => {
							console.log('error', error);
						})
				}
			})
			.catch((error) => {
				setLoaderModal(false);

				console.log('!getEventVideo error: ', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});

		return () => {
			// FWSD-6003, Full Screen View
			document.removeEventListener('fullscreenchange', onFullScreenChange)
			document.removeEventListener('webkitfullscreenchange', onFullScreenChange)
			document.removeEventListener('mozfullscreenchange', onFullScreenChange)
			document.removeEventListener('MSFullscreenChange', onFullScreenChange)
		}
	}, [])

	useEffect(() => {
		// if (value) {
		const ts = value
		const metadataUpdate = metadata;
		// setValue(ts)

		let ind = 0;
		var entry = _.find(metadataUpdate, function (item, i) {
			ind = i;
			return (item.t - metadataUpdate[0].t) >= (ts * 1000);
		})

		if (entry) {
			let newSpeed = entry.speed || 0;
			if (!newSpeed) {
				const speed_entry = _.find(metadataUpdate, function (item, i) {
					return ((item.t - metadataUpdate[0].t) >= (ts * 1000 + 1000)) || (item.speed > 0);
				}, ind)

				if (speed_entry) {
					newSpeed = speed_entry.speed || 0;
				} else {
					newSpeed = 0;
				}
			}
			setSpeed(Math.round(newSpeed * ((user.speed_unit === 'kph') ? 1 : .62)));

			let gps = entry.gps || 0;
			if (!gps) {
				const gps_entry = _.find(metadataUpdate, function (item, i) {
					return ((item.t - metadataUpdate[0].t) >= (ts * 1000 + 500)) || (item.gps);
				}, ind)

				if (gps_entry && gps_entry.gps) {
					setCoordinates({ lat: gps_entry.gps.lat, lng: gps_entry.gps.lon });
				}
			}
			else {
				setCoordinates({ lat: gps.lat, lng: gps.lon });
			}

			if (entry.direction) {
				setDir(getDirection(entry.direction));
				setDirection(entry.direction);
			}

			if (header.mobileye) {
				let meye = entry.meye // || meye;
				if (!meye) {
					const meye_entry = _.findLast(metadataUpdate, function (item, i) {
						return /*((item.t - metadataUpdate[0].t) >= (ts*1000+1000)) ||*/ _.has(item, 'meye')
					}, ind)

					if (meye_entry && meye_entry.meye) {
						setMeye(meye_entry.meye.warn)
					}
					else {
						setMeye({
							"error": false,
							"failsafe": false,
							"fcw": false,
							"hw": 0,
							"hwWarning": 0,
							"ldw": true,
							"ldwLeft": false,
							"ldwRight": false,
							"pedDz": false,
							"pedForward": false,
							"speedWarning": 0,
							"tamper": false
						})
					}
				}
			}
		}
	}, [state.timestamp])

	const getEventDriver = () => {
		// document.getElementsByClassName('loader')[0].style.display = 'block'
		const { eventId } = params

		apiCallGet('/event/{event_id}/driver', { event_id: eventId })
			.then((res, err) => {
				console.log('!getEventDriver res:', res, err)

				const { driver } = res.data.response
				setDriver(driver.name || '')

				// document.getElementsByClassName('loader')[0].style.display = 'none'
			})
			.catch((error) => {
				// document.getElementsByClassName('loader')[0].style.display = 'none'
				console.log('!getEventDriver error:', error.response, error)

				setDriver('')
			})
	}

	const hasAudio = (video) => {
		return video.mozHasAudio
			|| Boolean(video.webkitAudioDecodedByteCount)
			|| Boolean(video.audioTracks && video.audioTracks.length);
	}

	// const setPlayerEvents = (maxCollection) => {
	const setPlayerEvents = () => {
		// console.log('!!!!setPlayerEvents', maxCollection);
		vid1 = document.getElementById('channel1');
		vid2 = document.getElementById('channel2');
		vid3 = document.getElementById('channel3');
		vid4 = document.getElementById('channel4');

		const player_video = document.getElementById('player-video');

		// const obj = _.minBy(maxCollection, o => o.duration);
		// setParentObj(obj)


		// obj.videoObj.addEventListener('ended', function(e){
		player_video.getElementsByTagName('video')[0].addEventListener('ended', (e) => {
			setPlay(false);
		}, false);

		player_video.getElementsByTagName('video')[0].addEventListener('timeupdate', function (e) {					
			const ts = this.currentTime;
			// setTimestamp(starttimestamp + ts * 1000);
			setState((prevState) => ({...prevState, timestamp: prevState.starttimestamp + ts * 1000}))
			setValue(ts);
		}, false);

		// let pind = 0;
		// obj.videoObj.addEventListener('timeupdate', function(e){
		// player_video.getElementsByTagName('video')[0].addEventListener('timeupdate', function (e) {
		// 	const ts = this.currentTime;
		// 	setTimestamp(starttimestamp + ts * 1000);
		// 	setValue(ts);

		// 	let ind = 0;
		// 	var entry = _.find(metadata, function (item, i) {
		// 		ind = i;
		// 		return (item.t - metadata[0].t) >= (ts * 1000);
		// 	})

		// 	if (entry) {
		// 		let speed = entry.speed || 0;
		// 		if (!speed) {
		// 			const speed_entry = _.find(metadata, function (item, i) {
		// 				return ((item.t - metadata[0].t) >= (ts * 1000 + 1000)) || (item.speed > 0);
		// 			}, ind)

		// 			if (speed_entry) {
		// 				speed = speed_entry.speed || 0;
		// 			} else {
		// 				speed = 0;
		// 			}
		// 		}
		// 		setSpeed(Math.round(speed * 0.62));

		// 		let gps = entry.gps || 0;
		// 		// if (entry.gps && entry.gps.lat && entry.gps.lon) {
		// 		if (!gps) {
		// 			const gps_entry = _.find(metadata, function (item, i) {
		// 				return ((item.t - metadata[0].t) >= (ts * 1000 + 500)) || (item.gps);
		// 			}, ind)

		// 			if (gps_entry && gps_entry.gps) {
		// 				setCoordinates({ lat: gps_entry.gps.lat, lng: gps_entry.gps.lon });
		// 				// states.dir = getDirection(entry.direction);
		// 				// states.direction = entry.direction;
		// 			}
		// 		} else {
		// 			setCoordinates({ lat: gps.lat, lng: gps.lon });
		// 			// states.dir = getDirection(entry.direction);
		// 			// states.direction = entry.direction;
		// 			// states.timestamp = entry.ts;
		// 		}

		// 		if (entry.direction) {
		// 			setDir(getDirection(entry.direction));
		// 			setDirection(entry.direction);
		// 		}

		// 		if (header.mobileye) {
		// 			let meye = entry.meye // || meye;
		// 			if (!meye) {
		// 				const meye_entry = _.findLast(metadata, function (item, i) {
		// 					return /*((item.t - metadata[0].t) >= (ts*1000+1000)) ||*/ _.has(item, 'meye')
		// 				}, ind)

		// 				if (meye_entry && meye_entry.meye) {
		// 					setMeye(meye_entry.meye.warn)
		// 				}
		// 				else {
		// 					setMeye({
		// 						error: false,
		// 						failsafe: false,
		// 						fcw: false,
		// 						hw: 0,
		// 						hwWarning: 0,
		// 						ldw: true,
		// 						ldwLeft: false,
		// 						ldwRight: false,
		// 						pedDz: false,
		// 						pedForward: false,
		// 						speedWarning: 0,
		// 						tamper: false,
		// 					})
		// 				}
		// 			}
		// 		}
		// 	}
		// }, false);

		// if (showChannelsSelection && video_urls.length > 1) {
		// 	for (var j = 1; j < video_urls.length; j++) {

		// 		player_video.getElementsByTagName('video')[j].addEventListener('ended', function (e) {
		// 			console.log('video ended');
		// 			setPlay(true);
		// 		}, false);

		// 		player_video.getElementsByTagName('video')[j].addEventListener('timeupdate', function (e) {
		// 			const ts = this.currentTime;
		// 			setTimestamp(starttimestamp + ts * 1000);

		// 			let ind = 0;
		// 			var entry = _.find(metadata, function (item, i) {
		// 				ind = i;
		// 				return (item.t - metadata[0].t) >= (ts * 1000);
		// 			})

		// 			setValue(ts);

		// 			if (entry) {
		// 				let speed = entry.speed || 0;
		// 				if (!speed) {
		// 					const speed_entry = _.find(metadata, function (item, i) {
		// 						return ((item.t - metadata[0].t) >= (ts * 1000 + 1000)) || (item.speed > 0);
		// 					}, ind)

		// 					if (speed_entry) {
		// 						speed = speed_entry.speed || 0;
		// 					}
		// 					else {
		// 						speed = 0;
		// 					}
		// 				}
		// 				setSpeed(Math.round(speed * 0.62));

		// 				let gps = entry.gps || 0;
		// 				if (!gps) {
		// 					const gps_entry = _.find(metadata, function (item, i) {
		// 						return ((item.t - metadata[0].t) >= (ts * 1000 + 500)) || (item.gps);
		// 					}, ind)

		// 					if (gps_entry && gps_entry.gps) {
		// 						setCoordinates({ lat: gps_entry.gps.lat, lng: gps_entry.gps.lon });
		// 					}
		// 				} else {
		// 					setCoordinates({ lat: gps.lat, lng: gps.lon });
		// 				}

		// 				if (entry.direction) {
		// 					setDir(getDirection(entry.direction));
		// 					setDirection(entry.direction);
		// 				}
		// 			}
		// 		}, false);
		// 	}
		// }

		// setMax(obj.videoObj.duration); loaderModal(false);
		setMax(document.getElementById('player-video').getElementsByTagName('video')[0].duration)
		// setLoaderModal(false)

		// if (!isMobileOnly && !showChannelsSelection) {
		// 	playVideo(false);
		// }
	}

	const playVideo = (ff) => {
		if (!play || ff) {
			const playbackRateUpdate = Math.abs(playbackRate);
			setPlay(true);

			let videoSelection = '#player-video video';
			// if (showChannelsSelection) {
			// 	videoSelection = '#player-video video#channel' + player_active_number;
			// }

			$(videoSelection).each(function () {
				// $('#player-video video#channel'+player_active_number).each(function() {
				this.playbackRate = playbackRateUpdate;
				this.play();
			});

			if (video_urls.length > 1 && !showChannelsSelection) {
				vidDeviationControl();
			}
		} else {
			setPlay(false);

			$('#player-video video').each(function () {
				this.pause();
			});
		}
	}

	const pauseVideo = () => {
		$('#player-video video').each(function () {
			this.pause();
		});
	}

	const stopVideo = (e) => {
		$('#player-video video').each(function () {
			this.pause();
		});

		changeVideoSlider(e, 0);

		setPlay(false);
	}

	const changePlayerActive = (e, argPlayerNumber) => {
		stopVideo()
		setPlayer_active_number(argPlayerNumber);
	}

	const changeChannelPlayback = (e, argPlayerNumber) => {		
		// $('#player-video video').each(function () {
		// 	this.pause();
		// });

		// const currentTime = $('#player-video video#channel' + player_active_number)[0].currentTime;
		// setPlayer_active_number(argPlayerNumber);

		// $('#player-video video#channel' + argPlayerNumber)[0].currentTime = currentTime;

		// if (!play) {
		// 	$('#player-video video#channel' + argPlayerNumber)[0].play();
		// }
		e.preventDefault()
		stopVideo(e)
		setPlayer_active_number(argPlayerNumber)
		$($('#player-video video')[0]).attr('src', video_urls[argPlayerNumber-1]); //.load();
	}

	const changeVideoSlider = (e, newval) => {
		// console.log('changeVideoSlider', newval, e?.target?.value);
		const val = (typeof (newval) !== 'undefined') ? newval : e?.target?.value;
		$('#player-video video').each(function () {
			this.currentTime = val;
		});

		setValue(val);
	}

	const downloadNVR = () => {
		if (nvr_url) {
			addAuditLogFunc('download_nvr', { downloaded: nvr_url })
			window.location.href = nvr_url;
		}
	}

	const getVideoSoundIcon = (channelNumber) => {
		const row = videos[channelNumber - 1]

		let res = ''
		if (row.audio && player_active_number === channelNumber) {
			res = 'up'
		} else if (row.audio) {
			res = 'mute'
		}

		return res;
	}

	const displayTime = (value) => {
		if (value) {
			return moment(Math.floor(value) * 1000).format('mm:ss')
		}
		return '00:00'
	}

	const singleActivePlayer = (argPlayerNumber) => {
		if (showChannelsSelection) {
			return;
		}

		if (single_active_player) {
			$('#player-video .wrapper').removeClass('wrapper-block');
			$('#player-video .channel').show();

			setSingle_active_player(0);
		} else {
			$('#player-video .channel').hide();
			$('#player-video .channel' + argPlayerNumber).show();
			$('#player-video .wrapper').addClass('wrapper-block');

			setSingle_active_player(argPlayerNumber);
			setShowTooltipDoubleClick(false);
		}
	}

	// !!! FULLSCREEN DOES NOT WORK FOR iPhone
	const onPlayerFullScreenClick = () => {		
		const openPlayerFullScreen = () => {
			const elem = document.getElementById('player-container') || document.getElementById('player-video')
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) { /* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE11 */
				elem.msRequestFullscreen();
			} else if (elem.webkitFullscreenElement) { /* iOS >= 12, There is only way to toggle to fullscreen through native API in Safari */
				elem.webkitFullscreenElement();
			}

		}
	
		const closePlayerFullScreen = () => {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) { /* Safari */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE11 */
				document.msExitFullscreen();
			} else if (document.webkitRequestFullscreen) { /* iOS >= 12, There is only way to toggle to fullscreen through native API in Safari */
				document.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
			}
		}

		if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || playerFullScreen) {
			closePlayerFullScreen()
		}
		else {
			openPlayerFullScreen()
		}
	}

	// FWSD-6003, Full Screen View
	const onFullScreenChange = () => {
		if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
			setPlayerFullScreen(false)
		} 
		else {
			setPlayerFullScreen(true)
		}
	}

	//* Sizing of the popup with player
	let VideoColumnWidth = '80%';
	let MetadataColumnWidth = '20%';
	let PlayerVideoHeight = (windowHeight - 120) + 'px';
	let ChannelHeight = '100%';
	let WrapperColumns = '50% 50%'; // (channels > 1) ? '50% 50%' : '100%';


	if (showChannelsSelection) {
		WrapperColumns = '100%';
		ChannelHeight = (windowHeight - 120) + 'px';
		PlayerVideoHeight = '100%';
	} else {
		switch (video_urls.length) {
			case 1:
				WrapperColumns = '100%';
				ChannelHeight = (windowHeight - 120) + 'px';
				PlayerVideoHeight = '100%';
				break
			case 2:
				ChannelHeight = (windowHeight - 120) + 'px';
				PlayerVideoHeight = '100%';
				break
			case 3:
				ChannelHeight = Math.trunc((windowHeight - 120) / 2) + 'px';
				PlayerVideoHeight = '100%';
				break
			case 4:
				ChannelHeight = Math.trunc((windowHeight - 120) / 2) + 'px';
				PlayerVideoHeight = '100%';
				break
			default:
				break
		}
	}

	if (windowWidth < 800) {
		VideoColumnWidth = '100%';
		MetadataColumnWidth = '100%';
		WrapperColumns = '100%';
		ChannelHeight = '100%';
		PlayerVideoHeight = '100%';
	}

	if (isMobileOnly) {
		VideoColumnWidth = '100%';
		MetadataColumnWidth = '100%';
	}
	//******

	return (
		<div className="modal-body col-md-121" style={{ height: `${windowHeight}px` }}>
			{
				nopermalink
					? (
						<div>
							This event does not exist

							{loaderModal &&
								<div style={loader} />
							}

						</div>
					)
					: (
						<div id="advanced-web-player" className="permalink">
							<div className="col-md-9 col-xs-12 col-sm-12 no-padding video-column" style={{ width: VideoColumnWidth }}>

								<div id="player-video" className='channel1-wrapper' style={{ width: '100%', height: PlayerVideoHeight }}>
									{(video_urls.length > 0)
										&& (
											<div
												className={classnames('wrapper', { 'ios-device': showChannelsSelection }, { 'single-channel': (video_urls.length === 1) }, { 'small-width': (windowWidth < 800) })}
												style={{ gridTemplateColumns: WrapperColumns }}
											>

												{/* If iPhone the display single video tag */}
												{showChannelsSelection
												?
													<div
														style={{ minHeight: ChannelHeight }}
														className="channel channel1 active"
													>
														<h1>														
															<video id="channel1" data-channel="1" muted={true} preload="auto" playsInline /*controls*/ />
														</h1>
													</div>
												: (
												<>
													<div
														style={{ minHeight: ChannelHeight }}
														className={classnames('channel channel1', { active: player_active_number == 1 })}
														onClick={(e) => changePlayerActive(e, 1)}
														onDoubleClick={(e) => singleActivePlayer(1)}
														onMouseOver={() => { if (!single_active_player) { setShowTooltipDoubleClick(true) } }}
														onMouseOut={() => setShowTooltipDoubleClick(false)}
													>
														<h1>
															<video id="channel1" data-channel="1" muted="false" preload="auto" playsInline />
															{getVideoSoundIcon(1) && <img src={`/images/ico/volume_${getVideoSoundIcon(1)}.svg`} className="sound" />}
														</h1>
													</div>
													{(video_urls.length > 1)
														&& (
															<div
																style={{ minHeight: ChannelHeight }}
																className={classnames('channel channel2', { active: player_active_number == 2 })}
																onClick={(e) => changePlayerActive(e, 2)}
																onDoubleClick={(e) => singleActivePlayer(2)}
																onMouseOver={() => { if (!single_active_player) { setShowTooltipDoubleClick(true) } }}
																onMouseOut={() => setShowTooltipDoubleClick(false)}
															>
																<h1>
																	<video id="channel2" data-channel="2" muted={player_active_number != 2} preload="auto" playsInline />
																	{getVideoSoundIcon(2) && <img src={`/images/ico/volume_${getVideoSoundIcon(2)}.svg`} className="sound" />}
																</h1>
															</div>
														)}
													{(video_urls.length > 2)
														&& (
															<div
																style={{ minHeight: ChannelHeight }}
																className={classnames('channel channel3', { active: player_active_number == 3 })}
																onClick={(e) => changePlayerActive(e, 3)}
																onDoubleClick={(e) => singleActivePlayer(3)}
																onMouseOver={() => { if (!single_active_player) { setShowTooltipDoubleClick(true) } }}
																onMouseOut={() => setShowTooltipDoubleClick(false)}
															>
																<h1>
																	<video id="channel3" data-channel="3" muted={player_active_number != 3} preload="auto" playsInline />
																	{getVideoSoundIcon(3) && <img src={`/images/ico/volume_${getVideoSoundIcon(3)}.svg`} className="sound" />}
																</h1>
															</div>
														)}
													{(video_urls.length > 3)
														&& (
															<div
																style={{ minHeight: ChannelHeight }}
																className={classnames('channel channel4', { active: player_active_number == 4 })}
																onClick={(e) => changePlayerActive(e, 4)}
																onDoubleClick={(e) => singleActivePlayer(4)}
																onMouseOver={() => { if (!single_active_player) { setShowTooltipDoubleClick(true) } }}
																onMouseOut={() => setShowTooltipDoubleClick(false)}
															>
																<h1>
																	<video id="channel4" data-channel="4" muted={player_active_number != 4} preload="auto" playsInline />
																	{getVideoSoundIcon(4) && <img src={`/images/ico/volume_${getVideoSoundIcon(4)}.svg`} className="sound" />}
																</h1>
															</div>
														)}
													{(video_urls.length === 3)
														&& (
															<div
																style={{ minHeight: ChannelHeight }}
																className="channel channel4"
															/>
														)}
												</>
												)}
											</div>
										)}
									{/* Video horizontal scrolling */}
									<div className="video-slider">
										{/* Play / Stop playback button */}
										{<CustomButton variant="empty" onClick={(e) => playVideo(false)} id="play" title="Play">
											{play ? videoPlayerIcons.pauseIcon : videoPlayerIcons.playIcon}
										</CustomButton>}
										{/* Slider with playback timing */}
										<div className='slider-container'>
											<ReactBootstrapSlider
												id="video-slider"
												name="horizontalSliderName"
												value={value}
												max={max}
												min={0}
												step={0.1}
												tooltip="hide"
												change={changeVideoSlider}
											/>
										</div>
										{/* Fillscreen button */}
										{/* !!! iPhone does not support the js fullscreen */}
										{!showChannelsSelection &&
											<CustomButton variant="link" onClick={onPlayerFullScreenClick} >											
												{playerFullScreen
													? <RxExitFullScreen className='change-view' size='3.2em' />
													: <RxEnterFullScreen className='change-view' size='3.2em' />
												}
											</CustomButton>
										}
									</div>
								</div>

								{/* if it is iPhone */}
								{showChannelsSelection
									&& (
										<div style={{ textAlign: 'center', marginTop: '10px' }}>
											<ul className="list-inline chanels-list">
												{_.times(video_urls.length, i =>
													<li key={i} className={classnames('list-inline-item', { active: player_active_number === (i + 1) })}>
														<a href="/" onClick={(e) => changeChannelPlayback(e, i + 1)}>
															{i + 1}
														</a>
													</li>,
												)}
											</ul>
										</div>
									)}

								{/* Tooltip message when mouse pointer hovers over channel views */}
								{showTooltipDoubleClick
									&& (
										<div className="tooltipDoubleClick">
											Double click channel for full screen view
										</div>
									)}


							</div>

							<div className="col-md-3 col-xs-12 col-sm-12 no-padding metadata-column" style={{ width: MetadataColumnWidth }}>
								<div id="player-metadata">
									<h4 className='category-title'>Event Info</h4>
									<ul>
										{winOpen.type &&
											<li>
												<span className='metadata-logo'>
													<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
														<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
														<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
													</svg>
												</span>
												<span className='header-value'>
													{winOpen.type ? (winOpen.type === 'External Driver' ? 'Driver Event' : winOpen.type.replace(',', ', ')) : 'Not Available'}
												</span>
											</li>
										}
										{header.date &&
											<li>
												<span className="metadata-logo">
													<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-calendar-event" viewBox="0 0 16 16">
														<path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
														<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
													</svg>
												</span>
												<span className='header-value'>
													{moment.utc(header.date).format("ddd MMM D[,] YY") + ', ' + (state.timestamp ? moment.utc(state.timestamp).format('h:mm:ss A') : '')}
												</span>
											</li>
										}
										{(driver || header.driver) &&
											<li>
												<span className="metadata-logo">
													<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
														<path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
														<path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
													</svg>
												</span>
												<span className='header-value'>
													{driver || header.driver}
												</span>
											</li>
										}
										<li>
											<span className="metadata-logo">
												<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
													<path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
													<path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
												</svg>
											</span>
											<span className='header-value'>
												{speed} {'MPH'} <span className="red-text" style={{ fontSize: '13px' }}>{meye.speedWarning ? "(" + ((meye.speedWarning - 1) * 5) + "-" + (meye.speedWarning * 5) + " over limit)" : ""}</span>
											</span>
										</li>
										<li>
											<span className="metadata-logo">
												<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-stopwatch" viewBox="0 0 16 16">
													<path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
													<path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
												</svg>
											</span>
											<span className='header-value'>
												{displayTime(value)} / {displayTime(max)}
											</span>
										</li>
										{winOpen.custom_event_type &&
											<li>
												<span className="metadata-logo">
													<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
														<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
													</svg>
												</span>
												<span className='header-value'>
													{winOpen.custom_event_type}
												</span>
											</li>
										}
									</ul>
								</div>
								<span className="download-buttons">
									<Dropdown
										id="download-menu-dropdown"
									>
										<Dropdown.Toggle
											// nocaret
											className="btn-batch imageButton downloadButton"
											title='Download'
											style={{ color: '#0071b6', margin: '5px 10px', borderColor: '#0071b6' }}
										>
											<span>Download</span>
										</Dropdown.Toggle>
										<Dropdown.Menu
											className="download-menu"
										>
											<span>
												{(video_urls.length > 0) &&
													< OverlayTriggerNode
														nvrUrl={nvr_url}
														videoUrls={video_urls}
													/>
												}

												{nvr_url &&
													<Button onClick={downloadNVR} style={{ width: '100%', paddingTop: '6px' }} title="Download NVR Video" className='video-player-button'>Download NVR Video</Button>
												}
											</span>
										</Dropdown.Menu>
									</Dropdown>
								</span>

								{/* Google map block */}
								<div id="player-map" style={{ height: '300px' }}>
									<SimpleMapExampleGoogleMap
										containerElement={
											<div style={{ height: `100%` }} className='container-element' />
										}
										mapElement={
											<div style={{ height: `100%` }} className='map-element' />
										}
										coordinates={coordinates}
										polyline={polyline}
										direction={direction}
									/>
								</div>
							</div>
						</div>
					)
			}
		</div>
	)
}

export default connect(
	state => ({
		loader: state.loader.loader
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(AdvancedPlayerOpenClip);
