//* This page is available just for the system_admin role.
//* There are buttons which perform single actions.

import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
// import API from 'mg-api-node'

import { getAddinConfig, getConvertedURL, geotabAuthentication } from '../../helpers/helperGeotab'
import { getGeotabCompanies } from '../../../action/GeotabActions'
import CustomButton from '../../CustomButton';

const addinConfig = getAddinConfig()

const handleAddInClick = (event, type, toggleLoader) => {
	toggleLoader(true);

	getGeotabCompanies({ get_password: true })
		.then((res, err) => {
			toggleLoader(false);
			console.log('!getGeotabCompanies res: ', res, err)

			let funcName = updateGeotabAddIn
			if (type === 'notifs') {
				funcName = updateGeotabWebRequest
			}
			const fleets = res.data.response.geotab_companies
			console.log('!getGeotabCompanies found fleets: ', fleets.length)
			_.forEach(fleets, fleet => funcName(fleet, toggleLoader))
		})
		.catch((error) => {
			toggleLoader(false);
			console.log('!getGeotabCompanies error', error)
		})
}

//* When developper updated the mygeotab -> Administration -> System Settings -> Add-Ins
const updateGeotabAddIn = async (fleet, toggleLoader) => {
	console.log('!updateGeotabAddIn: ', fleet)
	const data = { ...fleet, fleet_token: fleet.token, sessionId: fleet.session_id }
	if (!data.email || !data.password || !data.database || !data.path || !data.fleet_token) {
		console.log('!Error: empty fleet')
		return
	}

	const resultAuth = await geotabAuthentication(data)
	if (resultAuth.error) {
		toggleLoader(false);
		toastr.error('An unexpected error occurred. Please try again later');
		return;
	}

	const { geotabApi, geotabData } = resultAuth
	data.path = geotabData.path
	data.database = geotabData.database
	data.sessionId = geotabData.sessionId

	// const api = new API(data.email, data.password, data.database, 'my.geotab.com')
	// const api = new API(data.email, data.password, data.database, data.path)
	// api.authenticate((err, resAuth) => {
	// 	console.log('!Authenticate res: ', resAuth, err);

	// if (err) {
	// 	toggleLoader(false);
	// 	console.log('!Authenticate error:', data.email, err)

	// 	return
	// }

	// if (resAuth.path && resAuth.credentials && resAuth.credentials.database) {
	// 	data.path = resAuth.path
	// 	data.database = resAuth.credentials.database
	// 	data.sessionId = resAuth.credentials.sessionId

	// 	console.log('data: ', data.email, data)

	geotabApi.call('Get', {
		typeName: 'AddIn',
	},
		// (errGet, resGet) => {
		(resGet) => {
			toggleLoader(false);

			// if (errGet) {
			// 	console.log('!GET SystemSettings error: ', data.email, errGet)

			// 	return
			// }
			console.log('GET AddIn resGet: ', resGet)
			let systemAddinExist = false
			resGet.forEach((addin, i) => {
				// console.log(addin.configuration.name)
				if (addin.configuration.name === 'Dual-Vision Video Camera' && systemAddinExist === false) {
					systemAddinExist = i
				}
			})

			geotabApi.call(
				systemAddinExist ? 'Set' : 'Add', {
				typeName: 'AddIn',
				// entity: { configuration: JSON.stringify(addinConfig) },
				entity: { configuration: addinConfig },
			},
				(resSet) => {
					console.log('!SET SystemSettings res: ', data.email, resSet, data)
					toggleLoader(false);

					// if (err) {
					// 	console.log('!SET SystemSettings error:', data.email, err)

					// 	return
					// }

					toastr.success('Success', 'The credentials were saved')
				},
				(errSet) => {
					toggleLoader(false);
					console.log('!SET SystemSettings1 errSet: ', data.email, errSet)
				},
			)

			// const systemSettings = resGet[0];
			// let systemAddinExist = false
			// console.log('systemSettings: ', systemSettings)
			// console.log('systemSettings.customerPages: ', systemSettings.customerPages)

			// systemSettings.customerPages.forEach((addin, i) => {
			// 	// if (JSON.parse(addin).name === 'RoscoLive' && systemAddinExist === false) {
			// 	if (JSON.parse(addin).name === 'Dual-Vision Video Camera' && systemAddinExist === false) {
			// 		systemAddinExist = i
			// 	}
			// })

			// systemSettings.allowUnsignedAddIn = true
			// if (systemAddinExist !== false) {
			// 	systemSettings.customerPages[systemAddinExist] = JSON.stringify(addinConfig)
			// } else {
			// 	systemSettings.customerPages.push(JSON.stringify(addinConfig))
			// }
			// geotabApi.call(
			// 	'Set', {
			// 		typeName: 'AddIn',
			// 		entity: systemSettings,
			// 	},
			// 	(err, res) => {
			// 		console.log('!SET SystemSettings res:', data.email, res, err, data, systemSettings)
			// 		document.getElementsByClassName('loader')[0].style.display = 'none'

			// 		if (err) {
			// 			console.log('!SET SystemSettings error:', data.email, err)

			// 			return
			// 		}

			// 		toastr.success('Success', 'The credentials were saved')
			// 	},
			// 	(a, p) => {
			// 		document.getElementsByClassName('loader')[0].style.display = 'none'
			// 		console.log('!SET SystemSettings1 a p:', data.email, a, p, data, systemSettings)
			// 	},
			// )
		},
		(errGet) => {
			console.log('!GET SystemSettings errGet: ', data.email, errGet)
		})

	// geotabApi.call('Get', {
	// 	typeName: 'SystemSettings',
	// },
	// (errGet, resGet) => {
	// 	document.getElementsByClassName('loader')[0].style.display = 'none'

	// 	if (errGet) {
	// 		console.log('!GET SystemSettings error: ', data.email, errGet)

	// 		return
	// 	}

	// 	const systemSettings = resGet[0];
	// 	let systemAddinExist = false
	// 	console.log('systemSettings: ', systemSettings)
	// 	console.log('systemSettings.customerPages: ', systemSettings.customerPages)

	// 	systemSettings.customerPages.forEach((addin, i) => {
	// 		// if (JSON.parse(addin).name === 'RoscoLive' && systemAddinExist === false) {
	// 		if (JSON.parse(addin).name === 'Dual-Vision Video Camera' && systemAddinExist === false) {
	// 			systemAddinExist = i
	// 		}
	// 	})

	// 	systemSettings.allowUnsignedAddIn = true
	// 	if (systemAddinExist !== false) {
	// 		systemSettings.customerPages[systemAddinExist] = JSON.stringify(addinConfig)
	// 	} else {
	// 		systemSettings.customerPages.push(JSON.stringify(addinConfig))
	// 	}
	// 	geotabApi.call(
	// 		'Set', {
	// 			typeName: 'SystemSettings',
	// 			entity: systemSettings,
	// 		},
	// 		(err, res) => {
	// 			console.log('!SET SystemSettings res:', data.email, res, err, data, systemSettings)
	// 			document.getElementsByClassName('loader')[0].style.display = 'none'

	// 			if (err) {
	// 				console.log('!SET SystemSettings error:', data.email, err)

	// 				return
	// 			}

	// 			toastr.success('Success', 'The credentials were saved')
	// 		},
	// 		(a, p) => {
	// 			document.getElementsByClassName('loader')[0].style.display = 'none'
	// 			console.log('!SET SystemSettings1 a p:', data.email, a, p, data, systemSettings)
	// 		},
	// 	)
	// })
	// } else {
	// 	document.getElementsByClassName('loader')[0].style.display = 'none'
	// 	console.log('!Auth error:', data.email, resAuth.data.error, data)
	// }
	// })
}

//* When developper updated the mygeotab -> Rules -> Notification templates
const updateGeotabWebRequest = async (fleet, toggleLoader) => {
	console.log('!updateGeotabWebRequest', fleet)
	const data = { ...fleet, fleet_token: fleet.token, sessionId: fleet.session_id }
	if (!data.email || !data.password || !data.database || !data.path || !data.fleet_token) {
		console.log('!Error: empty fleet')
		return
	}

	const resultAuth = await geotabAuthentication(data)
	if (resultAuth.error) {
		toggleLoader(false);
		toastr.error('An unexpected error occurred. Please try again later');
		return;
	}

	const { geotabApi, geotabData } = resultAuth
	data.path = geotabData.path
	data.database = geotabData.database
	data.sessionId = geotabData.sessionId

	// const api = new API(data.email, data.password, data.database, 'my.geotab.com')
	// const api = new API(data.email, data.password, data.database, data.path)
	// api.authenticate((err, resAuth) => {
	// 	console.log('!Authenticate res', resAuth, err);

	// 	if (err) {
	// 		toggleLoader(false);
	// 		console.log('!Authenticate error:', data.email, err)

	// 		return
	// 	}

	// if (resAuth.path && resAuth.credentials && resAuth.credentials.database) {
	// 	data.path = resAuth.path
	// 	data.database = resAuth.credentials.database
	// 	data.sessionId = resAuth.credentials.sessionId

	// 	console.log('data:', data.email, data)

	geotabApi.call('GetNotificationWebRequestTemplates', {},
		(err, resGet) => {
			toggleLoader(false);

			if (err) {
				console.log('!GET SystemSettings error:', data.email, err)

				return
			}

			const notificationWebRequests = resGet
			let notif_snapshot_exist_id = false;
			let notif_video_exist_id = false;
			let notif_snapshot_and_video_exist_id = false;
			let multicalls = []

			notificationWebRequests.map((notification, i) => {
				console.log('notification.name', notification.name)

				if (notification.name === 'Auto-upload snapshot to RoscoLive' && !notif_snapshot_exist_id) {
					notif_snapshot_exist_id = notification.id
				} else if (notification.name === 'Auto-upload video to RoscoLive' && !notif_video_exist_id) {
					notif_video_exist_id = notification.id
				} else if (notification.name === 'Auto-upload snapshot and video clip to RoscoLive' && !notif_snapshot_and_video_exist_id) {
					notif_snapshot_and_video_exist_id = notification.id
				}
			})

			if (notif_snapshot_and_video_exist_id) {
				multicalls.push(
					['Set', {
						typeName: 'NotificationBinaryFile',
						entity: {
							id: notif_snapshot_and_video_exist_id,
							url: getConvertedURL('https://geotab.roscolive.com/api/v1.0/geotab/custom_event/all'),
							name: 'Auto-upload snapshot and video clip to RoscoLive',
							requestType: 'Post',
							postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
							type: 'WebRequestTemplate',
						},
						credentials: {
							database: data.database,
							userName: data.email,
							sessionId: data.sessionId,
						},
					}],
				)
			} else {
				multicalls.push(
					['Add', {
						typeName: 'NotificationBinaryFile',
						entity: {
							url: getConvertedURL('https://geotab.roscolive.com/api/v1.0/geotab/custom_event/all'),
							name: 'Auto-upload snapshot and video clip to RoscoLive',
							requestType: 'Post',
							postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
							type: 'WebRequestTemplate',
						},
						credentials: {
							database: data.database,
							userName: data.email,
							sessionId: data.sessionId,
						},
					}],
				)
			}

			if (notif_snapshot_exist_id) {
				multicalls.push(
					['Set', {
						typeName: 'NotificationBinaryFile',
						entity: {
							id: notif_snapshot_exist_id,
							url: getConvertedURL('https://geotab.roscolive.com/api/v1.0/geotab/custom_event/snapshots'),
							name: 'Auto-upload snapshot to RoscoLive',
							requestType: 'Post',
							postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
							type: 'WebRequestTemplate',
						},
						credentials: {
							database: data.database,
							userName: data.email,
							sessionId: data.sessionId,
						},
					}],
				)
			} else {
				multicalls.push(
					['Add', {
						typeName: 'NotificationBinaryFile',
						entity: {
							url: getConvertedURL('https://geotab.roscolive.com/api/v1.0/geotab/custom_event/snapshots'),
							name: 'Auto-upload snapshot to RoscoLive',
							requestType: 'Post',
							postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
							type: 'WebRequestTemplate',
						},
						credentials: {
							database: data.database,
							userName: data.email,
							sessionId: data.sessionId,
						},
					}],
				)
			}

			if (notif_video_exist_id) {
				multicalls.push(
					['Set', {
						typeName: 'NotificationBinaryFile',
						entity: {
							id: notif_video_exist_id,
							url: getConvertedURL('https://geotab.roscolive.com/api/v1.0/geotab/custom_event/videos'),
							name: 'Auto-upload video to RoscoLive',
							requestType: 'Post',
							postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
							type: 'WebRequestTemplate',
						},
						credentials: {
							database: data.database,
							userName: data.email,
							sessionId: data.sessionId,
						},
					}],
				)
			} else {
				multicalls.push(
					['Add', {
						typeName: 'NotificationBinaryFile',
						entity: {
							url: getConvertedURL('https://geotab.roscolive.com/api/v1.0/geotab/custom_event/videos'),
							name: 'Auto-upload video to RoscoLive',
							requestType: 'Post',
							postBody: 'hash=3eebc8724325&database={database}&date={date}&time={time}&deviceid={deviceId}&device={device}&serial={serialNumber}&vin={vin}&rule={rule}&diagnostic={diagnostic}&driver={driverName}&driverfn={driverFirstName}&driverln={driverLastName}',
							type: 'WebRequestTemplate',
						},
						credentials: {
							database: data.database,
							userName: data.email,
							sessionId: data.sessionId,
						},
					}],
				)
			}

			geotabApi.multicall(
				multicalls,
				(err, res) => {
					console.log('!SET NotificationWebRequestTemplates res', res, err)
					toggleLoader(false);

					if (err) {
						console.log('!SET NotificationWebRequestTemplates error:', data.email, err)
						return
					}

					toastr.success('Success', 'The credentials were saved')
				},
				(a, p) => {
					toggleLoader(false);
					console.log('!SET NotificationWebRequestTemplates a p:', data.email, a, p, data)
				},
			)
		})
	// } else {
	// 	toggleLoader(false);
	// 	console.log('!Auth error:', data.email, resAuth.data.error, data)
	// }
	// })
}

const AdminSingleActions = (props) => {
	const { toggleLoader } = props;
	return (
		<main className='main-content-block'>
			<h3 className='category-title'>Not Regularly Actions</h3>

			<div className="row form-group">
				<div className="col-md-4">
					<div className="upload-wrapper">
						<CustomButton variant="primary" onClick={e => handleAddInClick(e, 'addin', toggleLoader)} style={{ width: '100%' }}>Update Geotab Add-Ins</CustomButton>
					</div>
				</div>
				<div className="col-md-8">
					Make updates of the Add-In list menu for the my.geotab.com customers if developer changed the default Add-In list.
				</div>
			</div>

			<div className="row form-group">
				<div className="col-md-4">
					<div className="upload-wrapper">
						<CustomButton variant="primary" onClick={e => handleAddInClick(e, 'notifs', toggleLoader)} style={{ width: '100%' }}>Update Geotab Web Template</CustomButton>
					</div>
				</div>
				<div className="col-md-8">
					Make updates of the Web Request Template of the Notification Templates for the my.geotab.com customers if developer changed the default Web Request Templates.
				</div>
			</div>
		</main>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(AdminSingleActions);
