import axios from 'axios'
import moment from 'moment'
import momenttz from '../component/helpers/momenttz'

const API_URL = process.env.REACT_APP_API_URL

export function getEventByTimestamp(data) {
	console.log('!getEventByTimestamp data', data);

	const url = `${API_URL}/device/${data.device_id}/event?start_ts=${data.timestamp}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventById(data) {
	console.log('!getEvent data', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'customer_service':
		case 'sales_demo':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'partner_view':
			url = `${API_URL}/partners/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/events/${data.event_id}`;
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventByIdWithType(data) {
	console.log('!getEventByIdWithType data', data);

	// event_type = ['regular', 'wcs']
	const url = `${API_URL}/event/${data.event_id}/type/${data.event_type}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function deleteEvent(data) {
	console.log('!deleteEvent data', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'partner_view':
			url = `${API_URL}/partners/companies/${data.company_name}/events/${data.event_id}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/events/${data.event_id}`;
			break;
	}


	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventsOfCompanyAndRange(data) {
	// console.log('!getEventsOfCompanyAndRange data', data);

	let url;
	switch (data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${data.company_name}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${data.partner_name}/companies/${data.company_name}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
			break;
		default:
			url = `${API_URL}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
			break;
	}

	if (data.page_number) {
		url += `&page_number=${data.page_number}`
	}

	if (data.page_size) {
		url += `&page_size=${data.page_size}`
	}

	if (data.filters) {
		url += `&filters=${data.filters}`
	}

	if (data.videostatuses) {
		url += `&videostatuses=${data.videostatuses}`
	}

	if (data.groups) {
		url += `&groups=${data.groups}`
	}

	if (data.search_type) {
		url += `&search_type=${data.search_type}`
	}

	if (data.search) {
		url += `&search=${data.search}`
	}

	if (data.order) {
		url += data.order
	}

	if (data.search_by_id) {
		url += `&search_by_id=${data.search_by_id}`
	}

	//* event_ids is used when we want to get single event (or several events) by id
	if (data.event_ids) {
		url += `&event_ids=${data.event_ids}`
	}

	//* device_ids is used when we want to get events of single device (or several devices) by device_id (used for Webfleet page)
	if (data.device_ids) {
		url += `&device_ids=${data.device_ids}`
	}

	if (data.is_flood) {
		url += `&is_flood=${data.is_flood}`
	}

	if (data.is_datausage_locked) {
		url += `&is_datausage_locked=${data.is_datausage_locked}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function changeEventNote (data) {
	console.log('!changeEventNote', data);

	const url = `${API_URL}/event/${data.event_id}/custom_event_type`;

	var form = new FormData();
	form.append('description', data.description);
	form.append('partner_name', data.partner_name);
	form.append('company_name', data.company_name);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventSnapshots(data) {
	console.log('!getEventSnapshots data: ', data)

	let url;
	switch(data.user_role) {
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'custom_user':
			url = `${API_URL}/events/${data.event_id}/snapshots`;
			break;
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			break;
		default:
			url = `${API_URL}/companies/${data.company_name}/events/${data.event_id}/snapshots`;
			break
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventVideo(data) {
	console.log('!getEventVideo action data: ', data)

	let url;
	switch (data.user_role) {
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/events/${data.event_id}/videos?video_type=${data.type}`;
		break;
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}/videos?video_type=${data.type}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}/videos?video_type=${data.type}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${data.company_name}/events/${data.event_id}/videos?video_type=${data.type}`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${data.company_name}/events/${data.event_id}/videos?video_type=${data.type}`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${data.partner_name}/companies/${data.company_name}/events/${data.event_id}/videos?video_type=${data.type}`;
		break;
		break;
	default:
		break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function addDriverToEvent(data) {
	let url = `${API_URL}/events/${data.event_id}`

	if (data.driver_id) {
		url += `?driver_id=${data.driver_id}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function requestEventVideo(data) {
	console.log('!requestVideo actions data:', data)

	const url = `${API_URL}/events/custom_clips`;

	const form = new FormData();
	form.append('device_id', data.device_id);
	form.append('start_ts', data.start_ts || momenttz(data.timestamp).utc().format('YYYYMMDDHHmmss'));
	form.append('end_ts', data.end_ts || momenttz(data.timestamp).add(data.length, 'seconds').utc().format('YYYYMMDDHHmmss'));
	if (data.description) {
		form.append('custom_clip_type', data.description);
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function requestFloodDownload(data) {
	console.log('!requestFloodDownload action data:', data)

	const url = `${API_URL}/events/flood_download`

	var form = new FormData();
	form.append('event_id', data.event_id)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function requestCustomAlertVideo(data) {
	console.log('requestCustomAlertVideo data', data)

	const url = `${API_URL}/events/custom_alert_clips`;

	const form = new FormData();
	form.append('device_id', data.device_id);
	form.append('timestamp', moment(data.timestamp).format('YYYYMMDDHHmmss'));
	form.append('alert_type', data.alert_type);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function requestCustomAlertSnapshot(data) {
	console.log('requestCustomAlertSnapshot data', data)

	const url = `${API_URL}/events/custom_alert_snapshots`;

	const form = new FormData();
	form.append('device_id', data.device_id);
	form.append('timestamp', moment(data.timestamp).format('YYYYMMDDHHmmss'));
	form.append('alert_type', data.alert_type);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function getAvailableDatesOfCompany(data) {
	// console.log('!getAvailableDatesOfCompany data: ', data)
	// const tz = moment().utcOffset();
	const tz = momenttz().utcOffset();
	const timezone = 'UTC' + ((tz > 0) ? '+' : '') + tz/60

	let url;
	switch (data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`
			break
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${data.company_name}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`
			break
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${data.partner_name}/companies/${data.company_name}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`
			break
		default:
			url = `${API_URL}/companies/${data.company_name}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`
			break
	}

	if (data.search_type) {
		url += `&search_type=${data.search_type}`
	}

	if (data.search) {
		url += `&search=${data.search}`
	}

	if (data.is_flood) {
		url += `&is_flood=${data.is_flood}`
	}

	if (data.is_datausage_locked) {
		url += `&is_datausage_locked=${data.is_datausage_locked}`
	}

	if (data.device_ids) {
		url += `&device_ids=${data.device_ids}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function getAvailableDatesOfDevice(data) {
	// console.log('!getAvailableDatesOfDevice actions: ', data)

	// const tz = moment().utcOffset();
	const tz = momenttz().utcOffset();
	const timezone = 'UTC' + ((tz > 0) ? '+' : '') + tz/60

	const url = `${API_URL}/devices/${data.device_id}/events/dates?start_ts=${data.start_ts}&timezone=${timezone}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventsOfDevice(data) {
	console.log('!getEventsOfDevice', data)

	const url = `${API_URL}/devices/${data.device_id}/events?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;

	let settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventsOfCompany(data) {
	console.log('!getEventsOfCompany', data)

	let url;
	switch (data.user_role) {
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/devices/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}&alert_type=${data.alert_types}&event_type=${data.event_types}`;
		break;
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/devices/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}&alert_type=${data.alert_types}&event_type=${data.event_types}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/devices/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}&alert_type=${data.alert_types}&event_type=${data.event_types}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${data.company_name}/devices/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}&alert_type=${data.alert_types}&event_type=${data.event_types}`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${data.company_name}/devices/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}&alert_type=${data.alert_types}&event_type=${data.event_types}`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${data.partner_name}/companies/${data.company_name}/devices/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}&alert_type=${data.alert_types}&event_type=${data.event_types}`;
		break;
	default:
		break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getAlertsOfDevice(data) {
	console.log('!getAlertsOfDevice', data)

	const url = `${API_URL}/devices/${data.device_id}/alerts?start_ts=${data.start_ts}&end_ts=${data.end_ts}&alert_type=${data.alert_types}&event_type=${data.event_types}`;

	let settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function restoreFromArchiveWCSEvent (data) {
	console.log('!restoreFromArchiveWCSEvent action', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/wcs/event/${data.event_id}/restore`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/wcs/event/${data.event_id}/restore`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/wcs/event/${data.event_id}/restore`;
			break;
		default:
			url = `${API_URL}/wcs/event/${data.event_id}/restore`;
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		dataType: 'jsonp',
		withCredentials: true,
	};

	return axios(settings);
}

export function requestEventShare(data) {
	console.log('!requestEventShare action data:', data)

	const url = `${API_URL}/events/${data.event_id}/share`

	const form = new FormData()
	form.append('company_id', data.company_id)
	form.append('email', data.email)
	form.append('expiration', data.expiration)
	if (data.message)
		form.append('message', data.message)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function getEventsFilters(data) {
    // console.log('!getEventsFilters data', data)
    let url;
    switch(data.user_role) {
        case 'system_admin':
            url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/events/filters?start_ts=${data.start_ts}&end_ts=${data.end_ts}`
            break
        case 'customer_service':
            url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/events/filters?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;
            break
        case 'partner':
            url = `${API_URL}/partners/companies/${data.company_name}/events/filters?start_ts=${data.start_ts}&end_ts=${data.end_ts}`
            break
        case 'partner_view':
            url = `${API_URL}/partner_view/companies/${data.company_name}/events/filters?start_ts=${data.start_ts}&end_ts=${data.end_ts}`
            break
        default:
            url = `${API_URL}/events/filters?start_ts=${data.start_ts}&end_ts=${data.end_ts}`
            break
    }

    if (data.sn) {
        url = url + `&sn=${data.sn}`
    }
    
//    if (data.geotab) {
//        url = url + `&geotab=true`
//    }
    
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": "GET",
        // "headers": {Pragma: 'no-cache'},
        "withCredentials": true
    }

    return axios(settings)
}