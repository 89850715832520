import React from 'react'
import PropTypes from 'prop-types'

import CustomButton from '../../CustomButton'
import InfoPop from '../../common/InfoPop'

const ProfileItem = (props) => {
	const { profile, onClickProfileItem, isActive } = props
	const { name, preferred } = profile

	return (
		<div className={`category-item right-padding profile-item${isActive ? ' active' : ''}`} onClick={onClickProfileItem}>
			<div className="name">
				{name}
			</div>
			{preferred &&
				<InfoPop 
					title='Preferred Profile' 
					body='This profile will be automatically pushed to newly 
					installed DV6 cameras. To change this, click on another 
					profile and go to the actions drop down button. 
					From there you can make another profile the preferred 
					profile.' 
					placement='top'
				>
					<div>
						<CustomButton
							size="icon"
							variant="primary"
							// prefix={classnames("note-button", { 'with-note': row.custom_event_type })}
							// active={row.custom_event_type}
							// onClick={() => setState({ ...state, selectedEvent: row, showEventNote: true })}
							// onClick={() => setState((prevState) => { return { ...prevState, selectedEvent: row, showEventNote: true } })}
						>
							Preferred
						</CustomButton>
					</div>
				</InfoPop>
			}
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
				<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
			</svg>
		</div>
	)
}

ProfileItem.propTypes = {
	profile: PropTypes.objectOf(PropTypes.any).isRequired,
	onClickProfileItem: PropTypes.func.isRequired,
	// onClickProfileComment: PropTypes.func.isRequired
}

export default ProfileItem;
