// FWSD-5403, FWSD-6490
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'

import CustomButton from '../../CustomButton'
import FleetConfigurationGeneral from './FleetConfigurationGeneral'
import FleetConfigurationChannel from './FleetConfigurationChannel'
import FleetConfigurationEvent from './FleetConfigurationEvent'
import ModalDelete from '../modals/ModalDelete'

const EditProfile = (props) => {
	// const { selected_profile, configuration, configurationdv6, user, event_types, setConfiguration, fillFormBoolean, fillFormInt, fillForm, onSaveClick, goBack } = props
	const { user, selected_profile, configuration, configurationdv6, event_types, isNewProfile, isProfileUpdated, setConfiguration, onSaveClick, goBack, openFleetConfigurationModal, fillFormBoolean, fillFormInt, fillForm, fillFormEnableChannel } = props

	const editProfileRef = useRef(null) // reference to EditProfile page 
	
	const [tabKey, setTabKey] = useState(1)
	const [settingsType, setSettingsType] = useState('dv6')

	const [showModalDelete, setShowModalDelete] = useState(false) // display Are You Sure popup
	const [redirectionYesNo, setRedirectionYesNo] = useState(false) // response/selection of user in Are You Sure popup
	const [outClickTarget, setOutClickTarget] = useState() // stores element (page link in the leftside menu, vehicles tab) which was clicked on before Are You Sure popup displaying

	const isDV6 = settingsType === 'dv6'
	const isDV4 = settingsType === 'dv4'

	// console.log('PROPS EditProfile configurationdv6: ', isProfileUpdated)

	// detects a click outside a React component
	useEffect(() => {
		const clickedOnScrollbar = (e) => {
			if (e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight) {
				return true
			}
			return false
		}

		const onOutsideClick = (e) => {
			// we should ignore clicking on the modal windows ("Are You Sure", "Update Fleet Profile" popups and their background) and window scroller and loader popup and toastr popup
			const isModalWindow = $(e.target).closest('.modal')
			const isLoaderPopup = $(e.target).closest('.loader')
			const isToastr = $(e.target).closest('.redux-toastr')
			if (isModalWindow.length === 0 && isLoaderPopup.length === 0 && isToastr.length ===0 && !clickedOnScrollbar(e)) {
				// console.log('onOutsideClick e.target, e: ', e.target, e)
				// if config settings were changed
				if (isProfileUpdated && !redirectionYesNo && !editProfileRef?.current?.contains(e.target)) {
					// e.preventDefault();
					// e.stopPropagation();
					setOutClickTarget(e.target)
					setShowModalDelete(true)
				}
			}
		};
	
		// targeting on the user clicking on the out of Edit Profile component
		document.addEventListener("mousedown", onOutsideClick, true)
		return () => {
		  	document.removeEventListener("mousedown", onOutsideClick, true)
		}
	}, [isProfileUpdated])

	const onGoBackClick = (e) => {
		if (isProfileUpdated) {
			setOutClickTarget('back')
			setShowModalDelete(true)
		}
		else {
			goBack()
		}
	}
	
	return (
		<div className="fleet-profiles">
			<div ref={editProfileRef}>
				<div className={`page-subheader${isNewProfile ? ' align-right' : ''}`}>
					{!isNewProfile && (
						<div className="subheader-section profile-name">
							{selected_profile.name}
						</div>
					)}
					<div className='subheader-section'>
						{isNewProfile 
							?
								<CustomButton variant="secondary" onClick={openFleetConfigurationModal}>
									ADD
								</CustomButton>
							:
								<CustomButton
									variant="secondary"
									disabled={!isProfileUpdated}
									prefix={`${!isProfileUpdated ? 'custom-disabled' : ''}`}
									onClick={onSaveClick}
								>
									SAVE
								</CustomButton>
						}
						<CustomButton variant="secondary-outline" onClick={onGoBackClick}>
							BACK
						</CustomButton>
					</div>
				</div>

				{/* DV6/DV4 toggle */}
				<div className="subheader-nav justify-left">
					<div className="toggle-wrapper no-border p-0 mt-10">
						<div className="toggle-buttons button-group">
							<CustomButton
								variant='toggle'
								active={isDV6}
								value="dv6"
								onClick={() => setSettingsType('dv6')}
							>
								DV6
							</CustomButton>
							<CustomButton
								variant='toggle'
								active={isDV4}
								value="dv4"
								onClick={() => setSettingsType('dv4')}
							>
								DV4
							</CustomButton>
						</div>
					</div>
				</div>
				{/* Setting types navigation */}
				<div className="subheader-nav justify-left">
					{/* <CustomButton variant='subtitle' active={tabKey === 1} onClick={() => setState(prevState => ({...prevState, tabKey: 1 }))}> */}
					<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => setTabKey(1)}>
						General Settings
					</CustomButton>
					<CustomButton variant='subtitle' active={tabKey === 2} onClick={() => setTabKey(2)}>
						Video Settings
					</CustomButton>
					<CustomButton variant='subtitle' active={tabKey === 3} onClick={() => setTabKey(3)}>
						Event Settings
					</CustomButton>
				</div>
				{/* <div className={`settings-page ${(isNewProfile || isEditing) ? '' : 'disabled'}`}> */}
				<div className="settings-page">
					{tabKey === 1
						&& (
							<FleetConfigurationGeneral
								settingsType={settingsType}
								configuration={configuration}
								configurationdv6={configurationdv6}
								// fillForm={fillForm}
								// fillFormBoolean={fillFormBoolean}
								// fillFormInt={fillFormInt}
								// setConfiguration={setConfiguration}
								// errMessage={errMessage}
								// user={user}
								fillFormBoolean={fillFormBoolean}
								fillFormInt={fillFormInt}
								fillForm={fillForm}
								// webfleetDeviceId={webfleetDeviceId}
							/>
						)}
					{tabKey === 2
						&& (
							<FleetConfigurationChannel
								settingsType={settingsType}
								configuration={configuration}
								configurationdv6={configurationdv6}
								// fillForm={fillForm}
								// setConfiguration={setConfiguration}
								// errMessage={errMessage}
								// user={user}
								// fillFormBoolean={fillFormBoolean}
								fillFormEnableChannel={fillFormEnableChannel}
								// fillFormInt={fillFormInt}
								fillForm={fillForm}
								// advancedSettings={companyData.advanced_profile_settings}
								// webfleetDeviceId={webfleetDeviceId}
							/>
						)}
					{tabKey === 3
						&& (
							<FleetConfigurationEvent
								settingsType={settingsType}
								configuration={configuration}
								configurationdv6={configurationdv6}
								// fillForm={fillForm}
								// fillFormBoolean={fillFormBoolean}
								// fillFormInt={fillFormInt}
								// setConfiguration={setConfiguration}
								// errMessage={errMessage}
								user={user}
								event_types={event_types}
								setConfiguration={setConfiguration}
								fillFormBoolean={fillFormBoolean}
								fillFormInt={fillFormInt}
								fillForm={fillForm}
								// webfleetDeviceId={webfleetDeviceId}
							/>
						)}
				</div>
			</div>
			{showModalDelete
				&& (
					<ModalDelete
						title={'Are You Sure ?'}
						content={`You have unsaved changes, if you leave your changes will be lost. <br/> Are you sure you want to go back ?`}
						showClose
						deleteBtnName="Yes"
						closeBtnName="No"
						styleView="primary"
						backdropClassName="upper"
						closeModal={() => {
							setRedirectionYesNo(false);
							setShowModalDelete(false);
						}}
						deleteModal={() => {							
							setRedirectionYesNo(true); 
							setShowModalDelete(false);
							if (outClickTarget === 'back') {
								goBack()
							}
							else if (typeof outClickTarget?.onclick === "function") {
								outClickTarget.click()
							}
							else if (typeof outClickTarget?.parentNode.onclick === "function") {
								outClickTarget.parentNode.click()
							}
						}}
					/>
				)}
		</div>
	)
}

EditProfile.propTypes = {
	selected_profile: PropTypes.objectOf(PropTypes.any).isRequired,
	isProfileUpdated: PropTypes.bool.isRequired,
	// onClickProfileItem: PropTypes.func.isRequired,
	setConfiguration: PropTypes.func,
	fillFormBoolean: PropTypes.func,
	fillFormInt: PropTypes.func,
	fillForm: PropTypes.func,
	onSaveClick: PropTypes.func,
	goBack: PropTypes.func,
	openFleetConfigurationModal: PropTypes.func,
	// onClickProfileComment: PropTypes.func.isRequired
}

export default EditProfile;
